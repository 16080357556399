import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { HTTP_AP_AdvisorUser, UserApiService } from '@api-new/userservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getCompanyById } from '@platform/company/store/company.action';
import { ToastAction, ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { TOAST_MESSAGE } from '@shared/utils';
import { Observable, from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ANIMATION_DELAY } from '../../constants';
import { ErrorModel } from '../../models/app-state.model';
import {
  activateUserSuccess,
  getUserById,
  getUserFailure,
  getUserSuccess,
  resetUserPassword,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  sendResetPasswordLink,
  sendResetPasswordLinkFailure,
  sendResetPasswordLinkSuccess,
} from './user.action';

@Injectable()
export class UserEffect {
  getUserById = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(getUserById),
        switchMap(({ id }) => {
          return this.userApiService.HTTP_AP_GetAdvisorUser({ advisorUserId: id }).pipe(
            map((user: HTTP_AP_AdvisorUser) => getUserSuccess({ user })),
            catchError((error) => of(getUserFailure({ error }))),
          );
        }),
      ),
  );

  handleGetUserSuccess = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(getUserSuccess),
        map(({ user }) => getCompanyById({ id: user.companyId })),
      ),
  );

  activateAccountRedirect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activateUserSuccess),
        delay(ANIMATION_DELAY),
        tap(() => this.router.navigate([``])),
      ),
    { dispatch: false },
  );

  sendPasswordReset = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(sendResetPasswordLink),
        switchMap(({ email }) =>
          this.authApiService.HTTP_AP_ProvideAdvisorUserResetPasswordCode({ email }).pipe(
            mergeMap(() => {
              this.toastService.showToast(ToastSeverity.success, TOAST_MESSAGE.SUCCESS[ToastAction.action_email]('Recovery'));
              return [sendResetPasswordLinkSuccess()];
            }),
            catchError((error: ErrorModel) => {
              this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR[ToastAction.action_email]('Recovery'));
              return from([sendResetPasswordLinkFailure({ error })]);
            }),
          ),
        ),
      ),
  );

  adviserPasswordReset = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(resetUserPassword),
      switchMap(({ code, password }) =>
        this.authApiService.HTTP_AP_ResetPassword_WithCode({ code, password }).pipe(
          map(() => {
            this.toastService.showToast(ToastSeverity.success, TOAST_MESSAGE.SUCCESS[ToastAction.action_update]('Password'));
            return resetUserPasswordSuccess();
          }),
          catchError((error: ErrorModel) => {
            this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR[ToastAction.action_update]('Password'));
            return of(resetUserPasswordFailure({ error }));
          }),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly authApiService: AuthApiService,
    private readonly userApiService: UserApiService,
    private readonly toastService: ToastService,
    private readonly router: Router,
  ) {}
}
