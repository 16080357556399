import { ENUM_HelpCaseStatus } from '@api-new/common';
import { RoutePaths } from '@app/app.utils';
import { SharedCaseStatus } from '@shared/enums';

export const ENUM_HelpCaseStatusMap = new Map([
  [
    ENUM_HelpCaseStatus.HELP_CASE_STATUS_LEAD,
    {
      text: 'Lead',
      route: RoutePaths.CasesLeads,
      sharedCaseStatus: SharedCaseStatus.LEAD,
    },
  ],
  [
    ENUM_HelpCaseStatus.HELP_CASE_STATUS_PIPELINE,
    {
      text: 'Pipeline',
      route: RoutePaths.CasesPipeline,
      sharedCaseStatus: SharedCaseStatus.ACTIVE,
    },
  ],
  [
    ENUM_HelpCaseStatus.HELP_CASE_STATUS_CLOSED,
    {
      text: 'Closed',
      route: RoutePaths.CasesClosed,
      sharedCaseStatus: SharedCaseStatus.CLOSED,
    },
  ],
  [
    ENUM_HelpCaseStatus.HELP_CASE_STATUS_UNSPECIFIED,
    {
      text: 'Unspecified',
      route: null,
    },
  ],
  [
    ENUM_HelpCaseStatus.UNRECOGNIZED,
    {
      text: 'Unrecognized',
      route: null,
    },
  ],
]);
