import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFormat',
  standalone: true,
})
export class NameFormatPipe implements PipeTransform {
  transform(value: string, format: 'short' | 'initials' = 'short'): string {
    if (!value) {
      return value;
    }

    const names = value.split(' ');

    if (names.length === 1) {
      return value;
    } else if (names.length === 2) {
      return this.formatTwoPartName(names, format);
    } else {
      return this.formatThreeOrMorePartName(names, format);
    }
  }

  private formatTwoPartName(names: string[], format: 'short' | 'initials'): string {
    const [firstName, lastName] = names;
    if (format === 'initials') {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else {
      return `${firstName.charAt(0)}. ${lastName}`;
    }
  }

  private formatThreeOrMorePartName(names: string[], format: 'short' | 'initials'): string {
    if (format === 'initials') {
      const initials = names.map((name) => name.charAt(0)).join('');
      return `${initials}`;
    } else {
      const initials = names
        .slice(0, -1)
        .map((name) => name.charAt(0))
        .join('. ');
      return `${initials}. ${names[names.length - 1]} `;
    }
  }
}
