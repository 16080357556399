export const createObjectFromQueryParams = (params: any): any => {
  const result = {};
  for (const objectPath in params) {
    const parts = objectPath.split('.');
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }
    target[parts[0]] = params[objectPath];
  }
  return result;
};
