import { createObjectFromQueryParams } from '@shared-lib/utils/createObjectFromQueryParams';
import { isSimpleObject } from '@shared-lib/utils/isSimpleObject';

export function mapStringParamsToCorrectTypes<FormModel>(queryParams: FormModel, defaultTableQueryParams: FormModel): FormModel {
  const mappedParams = {};
  const parsedQueryParams = createObjectFromQueryParams(queryParams);
  Object.entries(defaultTableQueryParams).forEach(([key, defaultValue]) => {
    let mappedParam = parsedQueryParams[key];

    if (!mappedParam) {
      return;
    }

    if (isSimpleObject(defaultValue)) {
      mappedParam = mapStringParamsToCorrectTypes(mappedParam, defaultValue);
    } else {
      if (defaultValue instanceof Array && !(mappedParam instanceof Array)) {
        mappedParam = [mappedParam];
      } else if (typeof defaultValue === 'boolean') {
        mappedParam = mappedParam === 'true';
      } else if (typeof defaultValue === 'number') {
        mappedParam = Number(mappedParam);
      }
    }

    mappedParams[key] = mappedParam;
  });

  return mappedParams as FormModel;
}
