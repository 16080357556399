import { environment } from '@environments/environment';

export function loadGoogleMapsScript(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleCloudPlatformApiKey}`;
    googleMapsScript.onload = () => {
      resolve();
    };
    googleMapsScript.onerror = (error) => {
      console.error('Error loading Google Maps script:', error);
      resolve(); // Resolve the Promise even on error to prevent app crash
    };
    document.body.appendChild(googleMapsScript);
  });
}
