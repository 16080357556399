import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RoutePaths } from '@app/app.utils';
import { AppStateModel } from '../../models/app-state.model';
import { $routeURL } from '../route/route.selectors';
import { setRemoteConfigAction } from './remote-config.action';

@Injectable()
export class RemoteConfigEffect {
  redirectToMaintenance = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(setRemoteConfigAction),
        tap(({ remoteConfig }) => {
          const maintenanceEnabled = (remoteConfig && remoteConfig['enableMaintenanceBackOffice'] === 'true') || false;
          this.store
            .select($routeURL)
            .pipe(take(1))
            .subscribe((url: string) => {
              if (url && url.startsWith(`/${RoutePaths.Maintenance}`)) {
                if (!maintenanceEnabled) {
                  this.router.navigate(['/']);
                }
              } else {
                if (maintenanceEnabled) {
                  this.router.navigate([RoutePaths.Maintenance]);
                }
              }
            });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppStateModel>,
    private readonly router: Router,
  ) {}
}
