import { ENUM_CreditReportAccountPaymentFrequency } from '@api-new/common';

export const ENUM_CreditReportAccountPaymentFrequencyMap = new Map([
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_UNSPECIFIED, ''],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_ANNUALLY, 'Annually'],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_FORTNIGHTLY, 'Fortnightly'],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_MONTHLY, 'Monthly'],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_PERIODICALLY, 'Periodically'],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_QUARTERLY, 'Quarterly'],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_WEEKLY, 'Weekly'],
  [ENUM_CreditReportAccountPaymentFrequency.CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_UNKNOWN, 'Unknown'],
  [ENUM_CreditReportAccountPaymentFrequency.UNRECOGNIZED, ''],
]);
