import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ap-common-table-filters',
  templateUrl: './common-table-filters.component.html',
  styleUrls: ['./common-table-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, ButtonModule],
})
export class CommonTableFiltersComponent<T> implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject<void>();
  filterChangesSubscription: Subscription;
  @Output() filterChange = new EventEmitter<T>();
  @Input() filterForm: FormGroup;

  @Input() set selectedFilter(selectedFilter: T) {
    if (selectedFilter !== this.selectedFilter) {
      this.filterForm.patchValue({ ...selectedFilter }, { emitEvent: false });
    }
  }

  ngOnInit(): void {
    this.filterChangesSubscription = this.filterForm.valueChanges.pipe(takeUntil(this.unsubscribe$), debounceTime(400)).subscribe(() => {
      this.filterChange.emit(this.selectedFilter);
    });
  }

  ngOnDestroy(): void {
    this.filterChangesSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get selectedFilter(): T {
    return this.filterForm.getRawValue();
  }
}
