import { HTTP_AP_ListLenderGroups_ForTable_Request_Filter } from '@api-new/mortgageservice';
import { LenderGroupTableRow } from '@platform/lender-group/pages/lender-group-table/models/lender-group-table-row';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';

export const lenderGroupTableRowDefinition: TableRowDefinitionModel<LenderGroupTableRow>[] = [
  {
    columnLabel: 'Group name',
    columnKey: 'name',
    type: RowDefinitionType.TEXT,
  },
  {
    columnLabel: 'Lenders',
    columnKey: 'lenders',
    type: RowDefinitionType.TEXT,
    width: {
      min: '10rem',
      max: '50rem',
    },
  },
];

export const lenderGroupTableFilterDefaults: HTTP_AP_ListLenderGroups_ForTable_Request_Filter = { name: undefined };
