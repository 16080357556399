import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';

export const ENUM_AdvisorUserPermissionLevelMap = new Map<ENUM_AdvisorUserPermissionLevel, { text: string; value: number }>([
  [ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT, { text: 'Direct', value: 0 }],
  [ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY, { text: 'Company', value: 1 }],
  [ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_NETWORK, { text: 'Network', value: 2 }],
  [ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_FULL, { text: 'Full', value: 3 }],
  [ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_UNSPECIFIED, { text: 'Unspecified', value: -1 }],
  [ENUM_AdvisorUserPermissionLevel.UNRECOGNIZED, { text: 'Unrecognized', value: -1 }],
]);
