import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (!environment.production) {
      console.error(error);
    }
    if (window.location.origin.includes('localhost')) {
      return;
    }
    const eventId = Sentry.captureException(error.originalError || error);
    // Remove Sentry report dialog until there's enough resources to process it
    // Sentry.showReportDialog({ eventId });
  }
}
