import { Action, createReducer, on } from '@ngrx/store';
import { DashboardStateModel } from '@platform/dashboard/models/dashboard-state.model';
import * as DashboardActions from './dashboard.action';

export const dashboardInitialState: DashboardStateModel = {
  selectedAdviserUserEmail: null,
  filter: {
    networks: {
      networkIds: null,
    },
    advisors: {
      advisorUserIds: null,
    },
    companies: {
      companyIds: null,
      isClientsUnassigned: null,
    },
  },
};

const reducer = createReducer(
  dashboardInitialState,
  on(
    DashboardActions.setDashboardFilter,
    (state, { filter }): DashboardStateModel => ({
      ...state,
      filter,
    }),
  ),

  on(
    DashboardActions.resetDashboardFilters,
    (state): DashboardStateModel => ({
      ...state,
      filter: {
        companies: {
          companyIds: null,
          isClientsUnassigned: null,
        },
        advisors: {
          advisorUserIds: null,
        },
        networks: {
          networkIds: null,
        },
      },
    }),
  ),

  on(
    DashboardActions.setSelectedAdviserEmail,
    (state, { selectedAdviserUserEmail }): DashboardStateModel => ({
      ...state,
      selectedAdviserUserEmail,
    }),
  ),
);

export function dashboardReducer(state: DashboardStateModel = dashboardInitialState, action: Action): DashboardStateModel {
  return reducer(state, action);
}
