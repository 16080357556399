// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CreditReportRetrieveFrequency.proto

export enum ENUM_CreditReportRetrieveFrequency {
  CREDIT_REPORT_RETRIEVE_FREQUENCY_UNSPECIFIED = 'CREDIT_REPORT_RETRIEVE_FREQUENCY_UNSPECIFIED',
  CREDIT_REPORT_RETRIEVE_FREQUENCY_NEVER = 'CREDIT_REPORT_RETRIEVE_FREQUENCY_NEVER',
  CREDIT_REPORT_RETRIEVE_FREQUENCY_MONTHLY = 'CREDIT_REPORT_RETRIEVE_FREQUENCY_MONTHLY',
  CREDIT_REPORT_RETRIEVE_FREQUENCY_QUARTERLY = 'CREDIT_REPORT_RETRIEVE_FREQUENCY_QUARTERLY',
  CREDIT_REPORT_RETRIEVE_FREQUENCY_SEMIANNUALLY = 'CREDIT_REPORT_RETRIEVE_FREQUENCY_SEMIANNUALLY',
  CREDIT_REPORT_RETRIEVE_FREQUENCY_ANNUALLY = 'CREDIT_REPORT_RETRIEVE_FREQUENCY_ANNUALLY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
