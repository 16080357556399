import { createSelector } from '@ngrx/store';
import { AppStateModel, RemoteConfigState } from '../../models/app-state.model';
import { RemoteConfigModel } from '../../models/remote-config.model';

export const $remoteConfigState = (state: AppStateModel): RemoteConfigState => state.remoteConfig;

export const $remoteConfigLoading = createSelector(
  $remoteConfigState,
  (remoteConfigState: RemoteConfigState): boolean => remoteConfigState.loading && !remoteConfigState.loaded, // Loading displayed only first fetching remote config
);

export const $remoteConfig = createSelector(
  $remoteConfigState,
  (remoteConfigState: RemoteConfigState): RemoteConfigModel => remoteConfigState.entity || null,
);

export const $configMaintenanceEnabled = createSelector(
  $remoteConfig,
  (remoteConfig: RemoteConfigModel): boolean => (remoteConfig && remoteConfig.maintenanceMode.enabled) || false,
);

export const $configMaintenanceDate = createSelector(
  $remoteConfig,
  (remoteConfig: RemoteConfigModel): string => (remoteConfig && remoteConfig.maintenanceMode.date) || 'Soon',
);
