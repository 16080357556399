// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_SmartSearchCaseStatus.proto

export enum ENUM_SmartSearchCaseStatus {
  SMART_SEARCH_CASE_STATUS_UNSPECIFIED = 'SMART_SEARCH_CASE_STATUS_UNSPECIFIED',
  SMART_SEARCH_CASE_STATUS_LEAD = 'SMART_SEARCH_CASE_STATUS_LEAD',
  SMART_SEARCH_CASE_STATUS_PIPELINE = 'SMART_SEARCH_CASE_STATUS_PIPELINE',
  SMART_SEARCH_CASE_STATUS_CLOSED = 'SMART_SEARCH_CASE_STATUS_CLOSED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
