/**
 * Creates company identifier string, used in Fullstory and Google Analytics
 * @param company
 * @param isAdmin
 */
export const companyIdentifier = (company: { id: string; name: string }, isAdmin: boolean): string => {
  if (isAdmin) {
    return '[sys-admin]';
  }
  if (typeof company === 'string') {
    return `[${company}] - Unknown`;
  }
  if (company?.id == null || company?.name == null) {
    return 'N/A';
  }
  return `[${company?.id}] - ${company?.name}`;
};
