import { HTTP_AP_Company_ForDetails } from '@api-new/companyservice';
import { createSelector } from '@ngrx/store';
import { AppStateModel } from '@shared/models/app-state.model';
import { selectUserCompanyId } from '@shared/store/user/user.selectors';
import { CompanyState } from '../models/company-state.model';
import { companiesAdapter } from './company.reducer';

const { selectEntities: selectAllCompanyDetailEntities } = companiesAdapter.getSelectors();

export const selectCompanyState = (state: AppStateModel): CompanyState => state.company;

export const selectCompanyDetailEntities = createSelector(selectCompanyState, (state: CompanyState) =>
  selectAllCompanyDetailEntities(state.companiesDetails),
);

export const selectIsLoadingCompany = createSelector(selectCompanyState, (state: CompanyState): boolean => state.loading);

export const selectIsCompanyLoaded = createSelector(selectCompanyState, (state: CompanyState): boolean => state.loaded);

export const selectSelectedDetailCompanyId = createSelector(
  selectCompanyState,
  (state: CompanyState): string => state.metadata && state.metadata.selectedCompanyDetailId,
);

export const selectSelectedDetailCompany = createSelector(
  selectCompanyDetailEntities,
  selectSelectedDetailCompanyId,
  (companyEntities, selectedCompanyId): HTTP_AP_Company_ForDetails | null => companyEntities[selectedCompanyId] || null,
);

export const selectCompanyLogo = createSelector(
  selectCompanyDetailEntities,
  selectUserCompanyId,
  (company, userCompanyId: string): string => company[userCompanyId]?.logo,
);

export const selectCompanyName = createSelector(
  selectCompanyDetailEntities,
  selectUserCompanyId,
  (company, userCompanyId: string): string => company[userCompanyId]?.tradingName,
);

export const selectIsUpdatingDetail = createSelector(selectCompanyState, (state): boolean => state.loadingActions.isUpdatingDetail);

export const selectIsUpdatingLogo = createSelector(selectCompanyState, (state): boolean => state.loadingActions.isUpdatingLogo);

export const selectIsCompanyBasicInfoInEditMode = createSelector(
  selectCompanyState,
  (state): boolean => state.form.isCompanyBasicInfoFormInEditMode,
);
