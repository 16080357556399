import { ENUM_PropertyClosedReason } from '@api-new/common';

export const ENUM_PropertyClosedReasonMap = new Map([
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_UNSPECIFIED, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_NOT_CLOSED, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_REMORTGAGE_SAME_LENDER, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_REMORTGAGE_NEW_LENDER, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_PAID_OFF, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_CLIENT_LOST, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE, ''],
  [ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE, ''],
  [ENUM_PropertyClosedReason.UNRECOGNIZED, ''],
  [
    ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_PROPERTY_SOLD,
    'This action will <strong>close the Property and the Mortgage if one is present</strong>. After closing, they will no longer be visible.',
  ],
  [
    ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE,
    'This action will <strong>close the Property and the Mortgage if one is present</strong>. After closing, they will no longer be visible. Please remember to <strong>add the details of the new Property</strong> after closing is complete.',
  ],
  [
    ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE,
    'This action will <strong>close the Property and the Mortgage if one is present</strong>. After closing, they will no longer be visible. Please remember to <strong>add the details of the new Property and the new Mortgage</strong> after closing is complete.',
  ],
  [
    ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT,
    'This action will <strong>close the Property and the Mortgage if one is present</strong>. After closing, they will no longer be visible. Please remember to <strong>add the details of the new Property and the new Mortgage</strong> after closing is complete.',
  ],
]);
