import { MONTHS_IN_YEAR } from '@shared/constants';

export const monthsToYears = (months: number): number => {
  return months ? months / MONTHS_IN_YEAR : null;
};

export const monthsToYearsInWholeNumbers = (months: number): number => {
  return Math.floor(monthsToYears(months));
};

export const yearsToMonths = (years: number): number => {
  return years ? years * MONTHS_IN_YEAR : null;
};
