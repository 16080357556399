import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.dashlydev';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Permission, PlatformType } from '@shared/enums';
import { PermissionService } from '@shared/services/permission.service';
import { setInitialPlatformType, setPlatformType } from '@shared/store/platform/platform.actions';
import { getUserSuccess } from '@shared/store/user/user.action';
import { companyIdentifier, getFullName } from '@shared/utils';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

const LOCAL_STORAGE_PLATFORM_TYPE_KEY = 'PLATFORM_TYPE';

@Injectable()
export class PlatformEffect {
  identifyUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserSuccess),
        filter((_) => !!window.FS && !!window.FS.identify),
        tap(({ user }) => {
          window.FS.identify(user.id, {
            displayName: getFullName(user),
            email: user.email,
            companyIdentifier: companyIdentifier(
              { id: user.companyId, name: user.companyTradingName },
              this.permissionService.hasCurrentUserRequiredPermissions([{ permission: Permission.SYSTEM_ADMIN }]),
            ),
          });
        }),
      ),
    { dispatch: false },
  );

  setInitialPlatformType = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(setInitialPlatformType),
        map(() => {
          if (environment.production) {
            // TODO michal.dvoracek: set platform type based on url/env
            return setPlatformType({ platformType: PlatformType.ADVISER });
          } else {
            const localStorageValue = localStorage.getItem(LOCAL_STORAGE_PLATFORM_TYPE_KEY) as PlatformType;
            return setPlatformType({ platformType: localStorageValue ? localStorageValue : PlatformType.ADVISER });
          }
        }),
      ),
  );

  setPlatformType = createEffect(
    (): Observable<any> =>
      this.actions$.pipe(
        ofType(setPlatformType),
        tap(({ platformType }) => {
          if (!environment.production) {
            localStorage.setItem(LOCAL_STORAGE_PLATFORM_TYPE_KEY, platformType);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly permissionService: PermissionService,
  ) {}
}
