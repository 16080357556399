// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_PropertyValueConfidenceRating.proto

export enum ENUM_PropertyValueConfidenceRating {
  PROPERTY_VALUE_CONFIDENCE_RATING_UNSPECIFIED = 'PROPERTY_VALUE_CONFIDENCE_RATING_UNSPECIFIED',
  PROPERTY_VALUE_CONFIDENCE_RATING_LOW = 'PROPERTY_VALUE_CONFIDENCE_RATING_LOW',
  PROPERTY_VALUE_CONFIDENCE_RATING_MEDIUM = 'PROPERTY_VALUE_CONFIDENCE_RATING_MEDIUM',
  PROPERTY_VALUE_CONFIDENCE_RATING_HIGH = 'PROPERTY_VALUE_CONFIDENCE_RATING_HIGH',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
