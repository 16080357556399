import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ENUM_ImportType } from '@api-new/common';
import { HTTP_AP_ListImports_Request_Filter } from '@api-new/importservice';
import { SharedFileModel } from '@shared-lib/models/shared-file.model';

export interface ImportFormModel {
  companyId: string;
  name: string;
  importType: ENUM_ImportType;
  file: SharedFileModel;
}

export interface ImportFormControlsModel {
  companyId: FormControl<string>;
  name: FormControl<string>;
  importType: FormControl<ENUM_ImportType>;
  file: FormControl<SharedFileModel>;
}

export const importTableFilterDefaults: Partial<HTTP_AP_ListImports_Request_Filter> = {
  name: '',
  importTypes: null,
  companyIds: [],
};

export interface ImportTableFilterForm {
  name: FormControl<string>;
  importTypes: FormControl<ENUM_ImportType[]>;
  companyIds: FormControl<string[]>;
}

export const buildImportCreateForm = (formBuilder: UntypedFormBuilder): FormGroup<ImportFormControlsModel> =>
  formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(100)]],
    companyId: [null, Validators.required],
    file: [null, Validators.required],
    importType: [null, Validators.required],
  });
