// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CaseType.proto

export enum ENUM_CaseType {
  CASE_TYPE_UNSPECIFIED = 'CASE_TYPE_UNSPECIFIED',
  CASE_TYPE_MORTGAGE = 'CASE_TYPE_MORTGAGE',
  CASE_TYPE_SMART_SEARCH = 'CASE_TYPE_SMART_SEARCH',
  CASE_TYPE_HELP = 'CASE_TYPE_HELP',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
