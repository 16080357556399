import { ENUM_ClientUserEmploymentType } from '@api-new/common';

export const ENUM_ClientUserEmploymentTypeMap = new Map([
  [ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_FULL_TIME, 'Full-time'],
  [ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_PART_TIME, 'Part-time'],
  [ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_SELF_EMPLOYED, 'Self-employed'],
  [ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_RETIRED, 'Retired'],
  [ENUM_ClientUserEmploymentType.CLIENT_USER_EMPLOYMENT_TYPE_UNEMPLOYED_HOME, 'Unemployed / home'],
  [ENUM_ClientUserEmploymentType.UNRECOGNIZED, 'Unrecognized'],
]);
