// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_DealRepaymentType.proto

export enum ENUM_DealRepaymentType {
  DEAL_REPAYMENT_TYPE_UNSPECIFIED = 'DEAL_REPAYMENT_TYPE_UNSPECIFIED',
  DEAL_REPAYMENT_TYPE_FULL_REPAYMENT = 'DEAL_REPAYMENT_TYPE_FULL_REPAYMENT',
  DEAL_REPAYMENT_TYPE_INTEREST_ONLY = 'DEAL_REPAYMENT_TYPE_INTEREST_ONLY',
  DEAL_REPAYMENT_TYPE_FULL_REPAYMENT_OR_INTEREST_ONLY = 'DEAL_REPAYMENT_TYPE_FULL_REPAYMENT_OR_INTEREST_ONLY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
