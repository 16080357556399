// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_EmailNotificationReportingEmailType.proto

export enum ENUM_EmailNotificationReportingEmailType {
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_UNSPECIFIED = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_UNSPECIFIED',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_ALL = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_ALL',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_ALL = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_ALL',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_FIRST = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_FIRST',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_REGULAR = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_REGULAR',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_COLD = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_COLD',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_INITIAL = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_INITIAL',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_REMINDER_FIRST = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_REMINDER_FIRST',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_REMINDER_MONTHLY = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_REMINDER_MONTHLY',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_INITIAL = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_INITIAL',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_REMINDER_FIRST = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_REMINDER_FIRST',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_REMINDER_MONTHLY = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_REMINDER_MONTHLY',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_INITIAL = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_INITIAL',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_REMINDER_FIRST = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_REMINDER_FIRST',
  EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_REMINDER_MONTHLY = 'EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_REMINDER_MONTHLY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
