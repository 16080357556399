// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ClientUserCreationMethod.proto

export enum ENUM_ClientUserCreationMethod {
  CLIENT_USER_CREATION_METHOD_UNSPECIFIED = 'CLIENT_USER_CREATION_METHOD_UNSPECIFIED',
  CLIENT_USER_CREATION_METHOD_CLIENT_USER_IMPORT = 'CLIENT_USER_CREATION_METHOD_CLIENT_USER_IMPORT',
  CLIENT_USER_CREATION_METHOD_BY_PROXY = 'CLIENT_USER_CREATION_METHOD_BY_PROXY',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_REGISTRATION = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_REGISTRATION',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_MORTGAGE_MONITOR = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_MORTGAGE_MONITOR',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_REMORTGAGE_NOW = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_REMORTGAGE_NOW',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_FIRST_TIME_BUYER = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_FIRST_TIME_BUYER',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_MOVING_HOME = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_MOVING_HOME',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_BUY_TO_LET = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_BUY_TO_LET',
  CLIENT_USER_CREATION_METHOD_SIGN_UP_LANDLORD = 'CLIENT_USER_CREATION_METHOD_SIGN_UP_LANDLORD',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
