import { HOURS_THRESHOLD_AFTERNOON, HOURS_THRESHOLD_MORNING } from '@shared/constants';
import moment from 'moment/moment';

export function getHelloMessageByTimeOfDay(): string {
  const currentTime = moment();
  return `Good ${
    currentTime.isBefore(moment().hour(HOURS_THRESHOLD_MORNING), 'hours')
      ? 'morning'
      : currentTime.isBefore(moment().hour(HOURS_THRESHOLD_AFTERNOON), 'hours')
        ? 'afternoon'
        : 'evening'
  }`;
}
