import { ENUM_ZooplaPropertyListingStatus } from '@api-new/common';

export const ENUM_ZooplaPropertyListingStatusMap = new Map([
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE,
    {
      text: 'For Sale',
      colorClass: 'status-blue',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER,
    {
      text: 'Sale Under Offer',
      colorClass: 'status-blue',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SOLD,
    {
      text: 'Sold',
      colorClass: 'status-gray',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT,
    {
      text: 'For Rent',
      colorClass: 'status-orange',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER,
    {
      text: 'Rent Under Offer',
      colorClass: 'status-orange',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENTED,
    {
      text: 'Rented',
      colorClass: 'status-orange',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.UNRECOGNIZED,
    {
      text: '-',
      colorClass: 'status-gray',
    },
  ],
  [
    ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_UNSPECIFIED,
    {
      text: '-',
      colorClass: 'status-gray',
    },
  ],
]);
