// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/userservice/HTTP_AP_ListClientUsers_ForTable_Request.proto
import { ENUM_NullableBool } from '../common/ENUM_NullableBool';
import { ENUM_SortType } from '../common/ENUM_SortType';
import { HTTP_Pagination_Request } from '../common/HTTP_Pagination_Request';
import { HTTP_Range_Int32 } from '../common/HTTP_Range_Int32';
import { HTTP_Range_Timestamp } from '../common/HTTP_Range_Timestamp';

export interface HTTP_AP_ListClientUsers_ForTable_Request {
  filter: HTTP_AP_ListClientUsers_ForTable_Request_Filter | undefined;
  sort: HTTP_AP_ListClientUsers_ForTable_Request_Sort | undefined;
  pagination: HTTP_Pagination_Request | undefined;
}

export interface HTTP_AP_ListClientUsers_ForTable_Request_Filter {
  clientUserFullNameOrEmail: string;
  advisorUserFullNameOrEmail: string;
  referralCode: string;
  clientUserCreatedAt: HTTP_Range_Timestamp | undefined;
  clientUserLastSignIn: HTTP_Range_Timestamp | undefined;
  propertyCount: HTTP_Range_Int32 | undefined;
  mortgageCount: HTTP_Range_Int32 | undefined;
  betterDealAlertNotificationsEnabled: ENUM_NullableBool;
  homeReportNotificationsEnabled: ENUM_NullableBool;
  mortgageUpdateNotificationsEnabled: ENUM_NullableBool;
  companyIds: string[];
  creationMethod: HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod[];
}

export enum HTTP_AP_ListClientUsers_ForTable_Request_Filter_ENUM_CreationMethod {
  CREATION_METHOD_UNSPECIFIED = 'CREATION_METHOD_UNSPECIFIED',
  CREATION_METHOD_SIGN_UP = 'CREATION_METHOD_SIGN_UP',
  CREATION_METHOD_BY_ADVISOR = 'CREATION_METHOD_BY_ADVISOR',
  CREATION_METHOD_IMPORTED = 'CREATION_METHOD_IMPORTED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export interface HTTP_AP_ListClientUsers_ForTable_Request_Sort {
  byClientUserFullName?: ENUM_SortType | undefined;
  byCompanyTradingName?: ENUM_SortType | undefined;
  byAdvisorUserFullName?: ENUM_SortType | undefined;
  byClientUserCreatedAt?: ENUM_SortType | undefined;
  byClientUserLastSignIn?: ENUM_SortType | undefined;
  byPropertyCount?: ENUM_SortType | undefined;
  byMortgageCount?: ENUM_SortType | undefined;
}
