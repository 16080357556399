import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { RoutePaths } from '@app/app.utils';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppStateModel } from '../models/app-state.model';
import { $configMaintenanceEnabled } from '../store/remote-config/remote-config.selector';

@Injectable()
export class MaintenanceGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly store: Store<AppStateModel>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select($configMaintenanceEnabled),
      take(1),
      map((maintenanceEnabled: boolean) => {
        if (maintenanceEnabled) {
          if (state.url.startsWith(`/${RoutePaths.Maintenance}`)) {
            return true;
          }
          void this.router.navigate([RoutePaths.Maintenance]);
          return false;
        }
        return !state.url.startsWith(`/${RoutePaths.Maintenance}`);
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
