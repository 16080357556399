import { ENUM_SmartSearchCaseClosedReason } from '@api-new/common';

export const ENUM_SmartSearchCaseClosedReasonMap = new Map([
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_UNSPECIFIED, 'Unspecified'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST, 'Cancelled - lost'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE, 'Cancelled - unresponsive'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE, 'Cancelled - unable to place'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER, 'Cancelled - lost direct to lender'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER, 'Cancelled - lost to another broker'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT, 'Cancelled - By client'],
  [ENUM_SmartSearchCaseClosedReason.SMART_SEARCH_CASE_CLOSED_REASON_COMPLETED, 'Completed'],
  [ENUM_SmartSearchCaseClosedReason.UNRECOGNIZED, 'Unrecognized'],
]);
