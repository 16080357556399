import { Action, createReducer, on } from '@ngrx/store';
import { RemoteConfigState } from '../../models/app-state.model';
import { remoteConfigErrorAction, setRemoteConfigAction } from './remote-config.action';

const initialState: RemoteConfigState = {
  entity: null,
  errors: null,
  loading: true,
  loaded: false,
};

const reducer = createReducer(
  initialState,

  on(setRemoteConfigAction, (state, { remoteConfig }) => ({
    ...state,
    entity: {
      maintenanceMode: {
        enabled:
          window.location.origin.includes('localhost') || remoteConfig.maintenanceMode.enabled === false
            ? false
            : remoteConfig.maintenanceMode.enabled === true,
        date: remoteConfig.maintenanceMode.date ? (remoteConfig.maintenanceMode.date as string) : null,
      },
      enableCreditReport: remoteConfig['enableCreditReport'] ? remoteConfig['enableCreditReport'] === 'true' : null,
      enableSmartSearch: remoteConfig['enableSmartSearch'] ? remoteConfig['enableSmartSearch'] === 'true' : null,
      logoutTimeoutInMinutes: remoteConfig['logoutTimeoutInMinutes'] ? parseInt(remoteConfig['logoutTimeoutInMinutes'] as string) : null,
    },
    loading: false,
    loaded: true,
  })),

  on(remoteConfigErrorAction, (state, { error }) => ({
    ...state,
    entity: null,
    loading: false,
    loaded: true,
    errors: error,
  })),
);

export function remoteConfigReducer(state: RemoteConfigState, action: Action): RemoteConfigState {
  return reducer(state, action);
}
