import { Pipe, PipeTransform } from '@angular/core';
import { booleanToYesNo } from '@shared/utils';

@Pipe({
  name: 'booleanToYesOrNo',
  pure: true,
  standalone: true,
})
export class BooleanToYesOrNoPipe implements PipeTransform {
  transform(booleanToTransform: boolean): string {
    return booleanToYesNo(booleanToTransform);
  }
}
