import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { HTTP_AP_AdvisorUser, HTTP_AP_AdvisorUserPermissionSettings } from '@api-new/userservice';
import { RoutePaths } from '@app/app.utils';
import { Store } from '@ngrx/store';
import { getNestedValue } from '@shared-lib/utils/getNestedValue';
import { Permission } from '@shared/enums';
import { ENUM_AdvisorUserPermissionLevelMap } from '@shared/maps';
import { selectUser, selectUserPermissions } from '@shared/store/user/user.selectors';
import { Observable } from 'rxjs';

export interface PermissionDefinition {
  permission: Permission;
  permissionLevel?: ENUM_AdvisorUserPermissionLevel;
}

export interface HasCurrentUserRequiredPermissionsFunction {
  (permissionDefinitions: PermissionDefinition[]): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  router = inject(Router);
  user$: Observable<HTTP_AP_AdvisorUser> = this.store.select(selectUser);

  userPermissions: HTTP_AP_AdvisorUserPermissionSettings;

  constructor(private store: Store) {
    this.store.select(selectUserPermissions).subscribe((userPermissions) => {
      this.userPermissions = userPermissions;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const routePermissions = route.data.permissions;

    if (this.hasCurrentUserRequiredPermissions(routePermissions)) {
      return true;
    }
    void this.router.navigate([RoutePaths.Platform, RoutePaths.Dashboard]);
  }

  hasCurrentUserRequiredPermissions(permissions: PermissionDefinition[], hasOneOfPermissions?: boolean): boolean {
    const hasRequiredPermissions = permissions?.map(({ permission, permissionLevel }) =>
      this.hasCurrentUserPermissionOnLevel(permission, permissionLevel),
    );
    if (hasOneOfPermissions) {
      if (hasRequiredPermissions?.includes(true)) {
        return true;
      }
    } else {
      if (!hasRequiredPermissions?.includes(false)) {
        return true;
      }
    }
    return false;
  }

  private hasCurrentUserPermissionOnLevel(permission: Permission, permissionLevel?: ENUM_AdvisorUserPermissionLevel): boolean {
    const adviserUserPermissionLevel = getNestedValue(this.userPermissions, permission);
    if (!adviserUserPermissionLevel) {
      return false;
    }
    if (permissionLevel === undefined) {
      return true;
    }
    return (
      ENUM_AdvisorUserPermissionLevelMap.get(adviserUserPermissionLevel).value >=
      ENUM_AdvisorUserPermissionLevelMap.get(permissionLevel).value
    );
  }
}

export const PermissionGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionService).canActivate(next, state);
};
