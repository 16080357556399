import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { Parameter } from '@angular/fire/compat/remote-config/remote-config';
import { RouterOutlet } from '@angular/router';
import { environment } from '@environments/environment.dashlydev';
import { init as initFullStory } from '@fullstory/browser';
import { Store } from '@ngrx/store';
import { SharedToastComponent } from '@shared-lib/components/toast/shared-toast.component';
import { BouncingBallsComponent } from '@shared/components/bouncing-balls/bouncing-balls.component';
import { RemoteConfigModel } from '@shared/models/remote-config.model';
import { setInitialPlatformType } from '@shared/store/platform/platform.actions';
import { setRemoteConfigAction } from '@shared/store/remote-config/remote-config.action';
import { $remoteConfigLoading } from '@shared/store/remote-config/remote-config.selector';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ap-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [BouncingBallsComponent, RouterOutlet, SharedToastComponent, AsyncPipe],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  readonly remoteConfigLoading$: Observable<boolean> = this.store.select($remoteConfigLoading);

  constructor(
    private readonly store: Store,
    private readonly remoteConfig: AngularFireRemoteConfig,
  ) {
    this.initFullStory();
    remoteConfig.parameters
      .pipe(
        map((remoteConfig: Parameter[]) => {
          const remappedParameter: RemoteConfigModel = {
            maintenanceMode: {
              enabled: false,
              date: '',
            },
            enableSmartSearch: false,
            enableCreditReport: false,
          };
          remoteConfig.forEach((parameter) => {
            if (parameter.key === 'maintenanceMode') {
              const value = JSON.parse(parameter._value);
              return (remappedParameter.maintenanceMode.date = value?.date), (remappedParameter.maintenanceMode.enabled = value?.enabled);
            } else {
              return ((remappedParameter[parameter.key] as any) = parameter._value);
            }
          });
          return remappedParameter;
        }),
      )
      .subscribe((remoteConfig: RemoteConfigModel) => {
        this.store.dispatch(
          setRemoteConfigAction({
            remoteConfig: remoteConfig,
          }),
        );
      });
  }

  ngOnInit(): void {
    if (!environment.production) {
      this.store.dispatch(setInitialPlatformType());
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initFullStory() {
    initFullStory({
      orgId: '9ZEQS',
      devMode: !environment.production,
    });
  }
}
