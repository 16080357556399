import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationModel, TableRowDefinitionModel, TableSortingModel } from '@shared/components/table';
import { CommonTableFacade } from '@shared/components/table/common-table/common-table.facade';
import { mapStringParamsToCorrectTypes, setQueryParams } from '@shared/utils';
import { MenuItem } from 'primeng/api';

@Component({
  template: '',
  standalone: true,
})
export class CommonTableBaseComponent<RowModel, SortKey, FilterModel> implements OnInit {
  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);
  protected readonly facade: CommonTableFacade;

  rowDefinitions: TableRowDefinitionModel<RowModel>[];

  tableQueryParams: FilterModel;
  tableFilterDefaults: FilterModel;

  ngOnInit(): void {
    if (this.route.snapshot.queryParams && Object.keys(this.route.snapshot.queryParams).length) {
      if (this.route.snapshot.queryParams.reset === 'true') {
        this.resetFilter();
      }
      this.tableQueryParams = mapStringParamsToCorrectTypes<FilterModel>(
        this.route.snapshot.queryParams as FilterModel,
        this.tableFilterDefaults,
      );
      this.changeFilter(this.tableQueryParams);
    } else {
      this.fetchTableData();
    }
  }

  fetchTableData(): void {
    this.facade.getTableData();
  }

  retryGetTableData(): void {
    this.facade.retryGetTableData();
  }

  openDetail?(row: RowModel): void;

  changeSort?(sort: TableSortingModel<SortKey>): void {
    this.facade.handleChangeSort(sort);
  }

  changeFilter?(selectedFilter: Partial<FilterModel>): void {
    setQueryParams(selectedFilter, this.tableFilterDefaults, this.router, this.route);
    this.facade.handleChangeFilter(selectedFilter);
  }

  resetFilter?(): void {
    setQueryParams({}, this.tableFilterDefaults, this.router, this.route);
    this.facade.handleFilterReset();
  }

  rowsSelected?(rows: RowModel[]): void;

  setActionRowClicked?(row: RowModel): void {
    this.facade.setActionRowClicked(row);
  }

  getBulkActions?(): MenuItem[];

  getRowActionMenuItems?(): MenuItem[] {
    return this.facade.getRowActionMenuItems();
  }

  changePage?(pagination: PaginationModel): void {
    this.facade.handleChangePage(pagination);
  }
}
