export enum ChartColorsEnum {
  BLUE = '#52bed1',
  TURQUOISE = '#70d7c4',
  ORANGE_LIGHT = '#f5bf84',
  RED = '#ff8181',
  PURPLE_LIGHT = '#a273f0',
  YELLOW = '#f4c102',
  GREEN = '#22cb58',
  ORANGE = '#f58a1f',
  BLUE_DARK = '#015891',
  PURPLE = '#b037fa',
  GREY = '#98a5b1',
  PINK = '#ff91c1',
  BROWN = '#a0522d',
  TEAL = '#339999',
}
