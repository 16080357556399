// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CreditReportAccountPaymentStatus.proto

export enum ENUM_CreditReportAccountPaymentStatus {
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_UNSPECIFIED = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_UNSPECIFIED',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NO_UPDATE_RECEIVED = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NO_UPDATE_RECEIVED',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_UP_TO_DATE_WITH_PAYMENTS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_UP_TO_DATE_WITH_PAYMENTS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_ONE_PAYMENT_IN_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_ONE_PAYMENT_IN_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_TWO_PAYMENTS_IN_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_TWO_PAYMENTS_IN_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_THREE_PAYMENTS_IN_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_THREE_PAYMENTS_IN_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_FOUR_PAYMENTS_IN_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_FOUR_PAYMENTS_IN_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_FIVE_PAYMENTS_IN_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_FIVE_PAYMENTS_IN_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_SIX_PAYMENTS_IN_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_SIX_PAYMENTS_IN_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_MODERATE_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_MODERATE_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_BAD_ARREARS = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_BAD_ARREARS',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_ARRANGEMENT_TO_PAY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_ARRANGEMENT_TO_PAY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_SETTLED_OR_SATISFIED = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_SETTLED_OR_SATISFIED',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NO_PAYMENT_DUE_YET_OR_UNCLASSIFIED = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NO_PAYMENT_DUE_YET_OR_UNCLASSIFIED',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_REPOSSESSION = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_REPOSSESSION',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_DEFAULT = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_DEFAULT',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_QUERY_ACCOUNT_UNDER_REVIEW = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_QUERY_ACCOUNT_UNDER_REVIEW',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_GONE_AWAY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_GONE_AWAY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_INACTIVE = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_INACTIVE',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NEVER_TAKEN_UP = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NEVER_TAKEN_UP',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_GOODS_VOLUNTARILY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_GOODS_VOLUNTARILY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_WRITTEN_OFF = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_WRITTEN_OFF',
  CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_TRANSFER = 'CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_TRANSFER',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
