import { HTTP_AP_ListAffiliates_ForTable_Request_Filter } from '@api-new/companyservice';
import { AffiliateTableRow } from '@platform/affiliate/models/affiliate-table.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';

export const affiliateTableFilterDefaults: HTTP_AP_ListAffiliates_ForTable_Request_Filter = {
  nameContains: undefined,
};

export const affiliateTableRowDefinition: TableRowDefinitionModel<AffiliateTableRow>[] = [
  {
    columnLabel: 'Name',
    columnKey: 'name',
    type: RowDefinitionType.TEXT,
    isFrozen: true,
  },
  {
    columnLabel: 'Logo',
    columnKey: 'logo',
    type: RowDefinitionType.IMAGE,
    width: {
      min: '10rem',
      max: '10rem',
    },
  },
  {
    columnLabel: 'ID',
    columnKey: 'id',
    type: RowDefinitionType.TEXT,
    width: {
      min: '10rem',
      max: '50rem',
    },
  },
];
