import { HTTP_AP_Company_ForDetails, HTTP_AP_ListCompanies_ForTable_Response_Company } from '@api-new/companyservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CompanyState } from '../models/company-state.model';
import {
  createCompany,
  createCompanyFailure,
  createCompanySuccess,
  getCompanyById,
  getCompanyByIdFailure,
  getCompanyByIdSuccess,
  getCompanyDetailSuccess,
  setCompanyBasicInfoEditMode,
  setSelectedCompanyDetailById,
  updateCompanyDetail,
  updateCompanyDetailFailure,
  updateCompanyDetailSuccess,
  updateCompanyLogo,
  updateCompanyLogoFailure,
  updateCompanyLogoSuccess,
  updateCompanyNotificationsSettings,
  updateCompanyNotificationsSettingsFailure,
} from './company.action';

export const companiesAdapter: EntityAdapter<HTTP_AP_Company_ForDetails> = createEntityAdapter<HTTP_AP_Company_ForDetails>({
  selectId: (company) => company.id,
});

export const companyListAdapter: EntityAdapter<HTTP_AP_ListCompanies_ForTable_Response_Company> =
  createEntityAdapter<HTTP_AP_ListCompanies_ForTable_Response_Company>({
    selectId: (company) => company.id,
  });

export const companiesInitialState: CompanyState = {
  companiesDetails: companiesAdapter.getInitialState(),
  companiesList: companyListAdapter.getInitialState(),
  errors: null,
  loading: false,
  loaded: false,
  loadingCompanyList: false,
  form: {
    isCompanyBasicInfoFormInEditMode: false,
  },
  loadingActions: {
    isUpdatingNotificationSettings: false,
    isAddingIDD: false,
    isUpdatingIDD: false,
    isUpdatingDetail: false,
    isUpdatingLogo: false,
  },
  metadata: {
    userCompanyID: '',
    selectedCompanyDetailId: '',
  },
};

const reducer = createReducer(
  companiesInitialState,

  on(
    getCompanyById,
    (state): CompanyState => ({
      ...state,
      loading: !state.loaded,
      errors: null,
    }),
  ),

  on(
    setSelectedCompanyDetailById,
    (state, { id }): CompanyState => ({
      ...state,
      metadata: {
        ...state.metadata,
        selectedCompanyDetailId: id,
      },
    }),
  ),

  on(getCompanyByIdSuccess, (state, { company }): CompanyState => {
    return {
      ...state,
      companiesDetails: companiesAdapter.addOne(company, state.companiesDetails),
      loading: false,
      loaded: true,
    };
  }),

  on(createCompany, (state): CompanyState => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(createCompanySuccess, createCompanyFailure, (state): CompanyState => {
    return {
      ...state,
      loading: false,
    };
  }),

  on(getCompanyDetailSuccess, (state, { company }): CompanyState => {
    return {
      ...state,
      companiesDetails: companiesAdapter.addOne(company, state.companiesDetails),
    };
  }),

  on(updateCompanyNotificationsSettings, (state) => ({
    ...state,
    loadingActions: {
      ...state.loadingActions,
      isUpdatingNotificationSettings: true,
    },
  })),

  on(
    updateCompanyNotificationsSettingsFailure,
    (state, { error }): CompanyState => ({
      ...state,
      loadingActions: {
        ...state.loadingActions,
        isUpdatingNotificationSettings: false,
      },
      errors: error,
    }),
  ),

  on(updateCompanyDetail, (state) => ({
    ...state,
    loadingActions: {
      ...state.loadingActions,
      isUpdatingDetail: true,
    },
  })),

  on(
    updateCompanyDetailSuccess,
    (state, { company }): CompanyState => ({
      ...state,
      companiesDetails: companiesAdapter.updateOne(
        {
          id: company.id,
          changes: {
            ...company,
            networkId: company.networkId,
            fax: company.fax,
            networkTradingName: company.networkTradingName,
            registrationNumber: company.registrationNumber,
          },
        },
        state.companiesDetails,
      ),
      form: {
        ...state.form,
        isCompanyBasicInfoFormInEditMode: false,
      },
      loadingActions: {
        ...state.loadingActions,
        isUpdatingDetail: false,
      },
    }),
  ),

  on(
    updateCompanyDetailFailure,
    (state, { error }): CompanyState => ({
      ...state,
      loadingActions: {
        ...state.loadingActions,
        isUpdatingDetail: false,
      },
      errors: error,
    }),
  ),

  on(
    getCompanyByIdFailure,
    (state, { error }): CompanyState => ({
      ...state,
      loading: false,
    }),
  ),

  on(updateCompanyLogo, (state) => ({
    ...state,
    loadingActions: {
      ...state.loadingActions,
      isUpdatingLogo: true,
    },
  })),

  on(
    updateCompanyLogoSuccess,
    (state, { companyDetail }): CompanyState => ({
      ...state,
      companiesDetails: companiesAdapter.updateOne({ id: companyDetail.id, changes: companyDetail }, state.companiesDetails),
      loadingActions: {
        ...state.loadingActions,
        isUpdatingLogo: false,
      },
    }),
  ),

  on(
    updateCompanyLogoFailure,
    (state, { error }): CompanyState => ({
      ...state,
      loadingActions: {
        ...state.loadingActions,
        isUpdatingLogo: false,
      },
      errors: error,
    }),
  ),

  on(
    setCompanyBasicInfoEditMode,
    (state, { isInEditMode }): CompanyState => ({
      ...state,
      form: {
        ...state.form,
        isCompanyBasicInfoFormInEditMode: isInEditMode,
      },
    }),
  ),
);

export function companyReducer(state: CompanyState = companiesInitialState, action: Action): CompanyState {
  return reducer(state, action);
}
