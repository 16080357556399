import { HTTP_AP_AdvisorUser, HTTP_AP_AdvisorUserPermissionSettings } from '@api-new/userservice';
import { createSelector } from '@ngrx/store';
import { PROFILE_NAME_PLACEHOLDER } from '../../constants';
import { AppStateModel, UserState } from '../../models/app-state.model';

export const selectUserState = (state: AppStateModel): UserState => state.currentUser;

export const selectUser = createSelector(selectUserState, (state: UserState): HTTP_AP_AdvisorUser => state.entity ?? null);

// TODO  For mark's 14.9.2023 demo purposes, should be removed after that
const BCM_COMPANY_ID = '295';
export const selectIsUserCompanyIdBCMGlobal = createSelector(
  selectUser,
  (user: HTTP_AP_AdvisorUser): boolean => user?.companyId === BCM_COMPANY_ID,
);

export const selectUserId = createSelector(selectUserState, (state: UserState): string => state.entity?.id);

export const selectIsUserLoading = createSelector(selectUserState, (state: UserState): boolean => state.loading);

export const selectIsUserLoaded = createSelector(selectUserState, (state: UserState): boolean => state.loaded);

export const selectIsUserPasswordReset = createSelector(selectUserState, (state: UserState): boolean => state.metadata.passwordReset);

export const selectUserFullName = createSelector(selectUser, (user: HTTP_AP_AdvisorUser): string => {
  if (user == null || (user.firstName == null && user.lastName == null)) {
    return PROFILE_NAME_PLACEHOLDER;
  }
  return (
    `${user.firstName ? user.firstName : ''}` + `${user.firstName && user.lastName ? ' ' : ''}` + `${user.lastName ? user.lastName : ''}`
  );
});

export const selectUserPermissions = createSelector(
  selectUserState,
  (state: UserState): HTTP_AP_AdvisorUserPermissionSettings => state?.entity?.advisorUserPermissionSettings,
);

export const selectRedirectUrlAfterSignIn = createSelector(
  selectUserState,
  (state: UserState): string => state?.metadata?.redirectUrlAfterSignIn || null,
);

export const selectIsUserDeactivated = createSelector(
  selectUserState,
  (state: UserState): boolean => state?.metadata?.deactivated || false,
);

export const selectUserCompanyId = createSelector(selectUserState, (user: UserState): string => user.entity?.companyId);
