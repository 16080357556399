import moment from 'moment/moment';

/**
 * @param duration
 * @param short
 * @returns duration in "XX year[-s] and YY month[-s]" format
 * If either years or months value is 0, it completely trims it from format
 * e.g. only "10 years" or "2 months".
 */
export function getFormattedDuration(duration: moment.Duration, short?: boolean): string {
  if (duration == null) {
    return 'unknown';
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const momentDuration: any = moment.duration(duration);
  if (momentDuration.asMonths() < 1) {
    return 'less than month';
  }
  // regex => if string ends with " and", removes it.
  // This is used because moment `{trim: 'both'}` removes months value if months === 0, but leave the " and"
  return short
    ? momentDuration.format('Y[y] M[m]', { trim: 'both' })
    : momentDuration.format('Y [year] [and] M [month]', { trim: 'both' }).match(/.*?(?=(?: and)?$)/)[0];
}
