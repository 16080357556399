import { ENUM_MortgageOfferParserValidationResult } from '@api-new/common';

export const ENUM_MortgageOfferParserValidationResultMap = new Map([
  [
    ENUM_MortgageOfferParserValidationResult.MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_UNSPECIFIED,
    {
      text: 'Unspecified',
      colorClass: 'grey',
    },
  ],
  [
    ENUM_MortgageOfferParserValidationResult.MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_UNCHECKED,
    {
      text: 'Unchecked',
      colorClass: 'grey',
    },
  ],
  [
    ENUM_MortgageOfferParserValidationResult.MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_FAILED,
    {
      text: 'Failed',
      colorClass: 'red',
    },
  ],
  [
    ENUM_MortgageOfferParserValidationResult.MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_WARNING,
    {
      text: 'Warning',
      colorClass: 'orange',
    },
  ],
  [
    ENUM_MortgageOfferParserValidationResult.MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_PASSED,
    {
      text: 'Passed',
      colorClass: 'green',
    },
  ],
  [
    ENUM_MortgageOfferParserValidationResult.UNRECOGNIZED,
    {
      text: 'Unrecognized',
      colorClass: 'grey',
    },
  ],
]);
