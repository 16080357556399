import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectInterface } from '@shared/components/form';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AssetAccessorService {
  constructor(private readonly httpClient: HttpClient) {}

  getNationalities$(): Observable<SelectInterface[]> {
    return this.httpClient.get('assets/files/nationalities.csv', { responseType: 'text' }).pipe(
      map(
        (data: string) =>
          <SelectInterface[]>[
            { value: 'British' },
            ...data
              .split(',')
              .filter((key) => key !== 'British')
              .map((key) => ({ value: key })),
          ],
      ),
    );
  }
}
