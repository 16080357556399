import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { withLatestFrom } from 'rxjs/operators';
import { RouteStateModel } from '../../models/route.model';

@Injectable()
export class RouteEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly titleService: Title,
  ) {}
  routeChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        withLatestFrom(({ payload: { routerState } }) => {
          const routerData = (routerState as RouteStateModel).data;
          let finalTitle = 'Dashly - Back Office';
          if (routerData.title) {
            const divider = ' | ';
            finalTitle = routerData.title + divider + finalTitle;
          }
          this.titleService.setTitle(finalTitle);
          return routerState;
        }),
      ),
    { dispatch: false },
  );
}
