import { Pipe, PipeTransform } from '@angular/core';
import { SortType } from '@shared/components/table';

@Pipe({
  name: 'tableSortOrderToNumber',
  standalone: true,
})
export class TableSortOrderToNumberPipe implements PipeTransform {
  transform(value: SortType): number {
    return value === SortType.SORT_TYPE_ASC ? 1 : -1;
  }
}
