import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectIsCompanyLoaded } from '@platform/company/store/company.selector';
import { PlatformState } from '@shared/models/platform-state.model';
import { selectIsUserLoaded } from '@shared/store/user/user.selectors';

export const selectPlatformState = createFeatureSelector<PlatformState>('platform');

export const selectPlatformType = createSelector(selectPlatformState, (state) => state.platformType);

export const selectPlatformDataLoaded = createSelector(
  selectIsUserLoaded,
  selectIsCompanyLoaded,
  (userLoaded: boolean, companyLoaded: boolean): boolean => userLoaded && companyLoaded,
);
