import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BinaryStatusTableColumnModel } from '@shared/components/table';

@Component({
  selector: 'ap-binary-status',
  standalone: true,
  imports: [],
  templateUrl: './binary-status.component.html',
  styleUrls: ['./binary-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BinaryStatusComponent {
  @Input() status: BinaryStatusTableColumnModel;
}
