import { HTTP_AP_GetProduct_Response, HTTP_AP_ListProducts_Response } from '@api-new/mortgageservice';
import { createAction, props } from '@ngrx/store';
import { ProductSearchForm } from '@platform/product-catalogue/pages/product-catalogue-table/product-catalogue-table.component';
import { PaginationModel, TableSortingModel } from '@shared/components/table';
import { ProductsSearchSort } from '@shared/enums';
import { ErrorModel } from '../../models/app-state.model';

export const getFilteredProductsSearch = createAction(
  '[PRODUCTS-SEARCH] Get Filtered ProductsSearch',
  props<{ selectedFilter?: ProductSearchForm }>(),
);
export const getSortedProductsSearch = createAction(
  '[PRODUCTS-SEARCH] Get Sorted ProductsSearch',
  props<{ sort?: TableSortingModel<ProductsSearchSort> }>(),
);

export const getPagedProductsSearch = createAction('[PRODUCTS-SEARCH] Get Paged ProductsSearch', props<{ pagination?: PaginationModel }>());

export const getProductsSearch = createAction('[PRODUCTS-SEARCH] Get ProductsSearch');

export const getProductsSearchSuccess = createAction(
  '[PRODUCTS-SEARCH] Get ProductsSearch Success',
  props<{ response: HTTP_AP_ListProducts_Response }>(),
);

export const getProductsSearchFailure = createAction('[PRODUCTS-SEARCH] Get ProductsSearch Failure', props<{ error: ErrorModel }>());

export const getProductsSearchDetail = createAction('GetProductsSearchDetail', props<{ id: string }>());

export const getProductsSearchDetailSuccess = createAction(
  'GetProductsSearchDetailSuccess',
  props<{ detail: HTTP_AP_GetProduct_Response }>(),
);

export const getProductsSearchDetailFailure = createAction('GetProductsSearchDetailFailure', props<{ error: ErrorModel }>());

export const resetProductCatalogFilter = createAction('[PRODUCT-SEARCH] Reset filter');
