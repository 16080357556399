import { ElementRef } from '@angular/core';

enum Direction {
  UP,
  DOWN,
}

const scrollToViewIfNotVisible = (
  direction: Direction,
  currentListElement: ElementRef,
  focusedItemIndex: number,
  listHeight: number,
  itemHeight: number,
): void => {
  const currentItemTop = currentListElement.nativeElement.children[focusedItemIndex].offsetTop;
  const scrollTop = currentListElement.nativeElement.scrollTop;
  if (currentItemTop < scrollTop + itemHeight || currentItemTop > scrollTop + listHeight - itemHeight) {
    currentListElement.nativeElement.scrollTop = direction === Direction.UP ? currentItemTop - itemHeight : currentItemTop - listHeight;
  }
};

export { Direction, scrollToViewIfNotVisible };
