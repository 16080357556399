// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_AdvisorUserPermissionLevel.proto

export enum ENUM_AdvisorUserPermissionLevel {
  ADVISOR_USER_PERMISSION_LEVEL_UNSPECIFIED = 'ADVISOR_USER_PERMISSION_LEVEL_UNSPECIFIED',
  ADVISOR_USER_PERMISSION_LEVEL_DIRECT = 'ADVISOR_USER_PERMISSION_LEVEL_DIRECT',
  ADVISOR_USER_PERMISSION_LEVEL_COMPANY = 'ADVISOR_USER_PERMISSION_LEVEL_COMPANY',
  ADVISOR_USER_PERMISSION_LEVEL_NETWORK = 'ADVISOR_USER_PERMISSION_LEVEL_NETWORK',
  ADVISOR_USER_PERMISSION_LEVEL_FULL = 'ADVISOR_USER_PERMISSION_LEVEL_FULL',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
