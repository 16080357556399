import { ENUM_ClientUserEmploymentOtherIncomeType } from '@api-new/common';

export const ENUM_ClientUserEmploymentOtherIncomeTypeMap = new Map([
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_UNSPECIFIED, ''],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_BONUS_FROM_ANOTHER_JOB, 'Bonus from another job'],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PENSION, 'Pension'],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_MAINTENANCE_PAYMENTS, 'Maintenance payments'],
  [
    ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_CAR_ALLOWANCE__FROM_ANOTHER_JOB,
    'Car allowance from another job',
  ],
  [
    ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_COMMISSIONS_FROM_ANOTHER_JOB,
    'Commissions from another job',
  ],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_RENTAL_INCOME, 'Rental income'],
  [
    ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_NET_PROFIT_FROM_ANOTHER_JOB,
    'Net profit from another job',
  ],
  [
    ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_OVERTIME__FROM_ANOTHER_JOB,
    'Overtime from another job',
  ],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_SALARY_FROM_ANOTHER_JOB, 'Salary from another job'],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PART_TIME, 'Part time'],
  [
    ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_HOUSE_ALLOWANCE_FROM_ANOTHER_JOB,
    'House allowance from another job',
  ],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_STATE_BENEFIT, 'State benefit'],
  [
    ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_DIVIDENDS_FROM_ANOTHER_JOB,
    'Dividends from another job',
  ],
  [ENUM_ClientUserEmploymentOtherIncomeType.CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_INVESTMENT_INCOME, 'Investment income'],
  [ENUM_ClientUserEmploymentOtherIncomeType.UNRECOGNIZED, ''],
]);
