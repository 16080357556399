import { ENUM_ManualPropertyValueType } from '@api-new/common';

export const ENUM_ManualPropertyValueTypeMap = new Map([
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_PURCHASE_PRICE, 'Purchase price'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_MORTGAGE_OFFER, 'Mortgage offer'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LAND_REGISTRY, 'Land registry'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_INSPECTION, 'Physical Valuation Adjustment - Post Inspection'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_WEBSITE, 'Website'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_ADVISER_ESTIMATION, 'Adviser estimation'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_CLIENT_ESTIMATION, 'Client estimation'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_SPOT_CHECK, 'Spot check'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_SYSTEM_ADJUSTMENT, 'System adjustment'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_UNKNOWN, 'Unknown'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_AVM, 'AVM'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_AGENT_VALUATION, 'Agent valuation'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_HOME_IMPROVEMENT, 'Home improvement'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_RECENT_SURVEY_VALUATION, 'Survey valuation'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_SIMPLY_DONT_AGREE, 'Simply dont agree'],
  [ENUM_ManualPropertyValueType.MANUAL_PROPERTY_VALUE_TYPE_LEGACY_DERIVED_FROM_AVM, 'Derived from AVM'],
  [ENUM_ManualPropertyValueType.UNRECOGNIZED, 'Unrecognized'],
]);
