import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AuthApiService,
  HTTP_AP_SignIn_WithProvider_Authorize_Response,
  HTTP_AP_SignIn_WithProvider_Initialize_Response,
} from '@api-new/authservice';
import { ENUM_AuthorizationProvider } from '@api-new/common';
import { Store } from '@ngrx/store';
import { LocalStorageKey, LoginProvider } from '@shared/enums';
import { LocalStorageService } from '@shared/services/local-storage.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { silentLoginSuccessAction } from '../store/auth/auth.action';
import { getUserById } from '../store/user/user.action';

import auth = firebase.auth;
import User = firebase.User;

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly fireAuth = inject(AngularFireAuth);
  private readonly store = inject(Store);
  private readonly authApiService = inject(AuthApiService);
  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.fireAuth.onAuthStateChanged((user: User) => {
      if (user != null) {
        this.store.dispatch(getUserById({ id: user.uid }));
        this.store.dispatch(silentLoginSuccessAction({ credential: { user, credential: null } }));
      }
    });
  }

  login(token: string): Observable<auth.UserCredential> {
    return from(this.fireAuth.setPersistence(auth.Auth.Persistence.LOCAL).then(() => this.fireAuth.signInWithCustomToken(token)));
  }

  initLoginWithProvider(loginProvider: LoginProvider): Observable<HTTP_AP_SignIn_WithProvider_Initialize_Response> {
    let authorizationProvider: ENUM_AuthorizationProvider;
    switch (loginProvider) {
      case LoginProvider.GOOGLE:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_GOOGLE;
        break;
      case LoginProvider.MAB:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_MAB;
        break;
      default:
        throw new Error('Invalid SSO provider');
    }
    return this.authApiService.HTTP_AP_SignIn_WithProvider_Initialize({ authorizationProvider });
  }

  authenticateLoginWithProvider(
    loginProvider: LoginProvider,
    authorizationResponseUrl: string,
  ): Observable<HTTP_AP_SignIn_WithProvider_Authorize_Response> {
    let authorizationProvider: ENUM_AuthorizationProvider;
    switch (loginProvider) {
      case LoginProvider.GOOGLE:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_GOOGLE;
        break;
      case LoginProvider.MAB:
        authorizationProvider = ENUM_AuthorizationProvider.AUTHORIZATION_PROVIDER_MAB;
        break;
      default:
        throw new Error('Invalid SSO provider');
    }
    return this.authApiService.HTTP_AP_SignIn_WithProvider_Authorize({ authorizationProvider, authorizationResponseUrl });
  }

  logout(): Promise<void> {
    return this.fireAuth.signOut();
  }

  getToken(): Observable<string> {
    return this.fireAuth.idToken;
  }

  authUser(): Observable<User> {
    return this.fireAuth.user;
  }

  userId(): Observable<string> {
    return this.authUser().pipe(map((user) => (user && user.uid) || null));
  }

  setRedirectUrl(redirectUrl: string): void {
    this.localStorageService.setItem(LocalStorageKey.LOGIN_REDIRECT_URL, redirectUrl);
  }

  getRedirectUrl(): string {
    return this.localStorageService.getItem(LocalStorageKey.LOGIN_REDIRECT_URL);
  }

  clearRedirectUrl(): void {
    this.localStorageService.removeItem(LocalStorageKey.LOGIN_REDIRECT_URL);
  }
}
