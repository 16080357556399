import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { PermissionDefinition, PermissionService } from '@shared/services/permission.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private readonly permissionService: PermissionService) {}

  preload(route: Route, load: Function): Observable<any> {
    const preload = route?.data?.preload;
    const permissionDefinitions = route?.data?.permissions as PermissionDefinition[];
    return preload || this.permissionService.hasCurrentUserRequiredPermissions(permissionDefinitions) ? load() : of(null);
  }
}
