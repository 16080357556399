import { Injectable } from '@angular/core';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { TOAST_MESSAGE } from '@shared/utils';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AppStateModel } from '../../models/app-state.model';
import {
  getFilteredProductsSearch,
  getPagedProductsSearch,
  getProductsSearch,
  getProductsSearchDetail,
  getProductsSearchDetailFailure,
  getProductsSearchDetailSuccess,
  getProductsSearchFailure,
  getProductsSearchSuccess,
  getSortedProductsSearch,
  resetProductCatalogFilter,
} from './products-search.action';
import { $hasProductSearchDetail, $productsSearchMetadata } from './products-search.selectors';

@Injectable()
export class ProductsSearchEffect {
  getProductsSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductsSearch, resetProductCatalogFilter),
      switchMap(() =>
        this.store.select($productsSearchMetadata).pipe(
          take(1),
          switchMap(({ search, paging, sort }) => {
            const filter = {
              filter: {
                lenderIds: search.lenderIds,
                mortgageType: search.mortgageType,
                maxLTV: search.maxLTV,
              },
              pagination: {
                pageNumber: paging?.pageNumber,
                pageSize: paging?.pageSize,
              },
              sort: {
                ...(sort?.sortKey && {
                  [sort.sortKey]: `${sort?.sortOrder}`,
                }),
              },
            };
            return this.mortgageApiService.HTTP_AP_ListProducts(filter).pipe(
              map((response) =>
                getProductsSearchSuccess({
                  response,
                }),
              ),
              catchError((error) => {
                this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR.getting_data);
                return of(getProductsSearchFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );

  getProductsSearchDetail = createEffect(() =>
    this.actions$.pipe(
      ofType(getProductsSearchDetail),
      switchMap(({ id }) =>
        this.store.pipe(
          select($hasProductSearchDetail(id)),
          filter((productDetail) => !productDetail),
          take(1),
          switchMap(() =>
            this.mortgageApiService.HTTP_AP_GetProduct({ productId: id }).pipe(
              map((detail) => getProductsSearchDetailSuccess({ detail })),
              catchError((error) => {
                this.toastService.showToast(ToastSeverity.error, TOAST_MESSAGE.ERROR.getting_data);
                return of(getProductsSearchDetailFailure({ error }));
              }),
            ),
          ),
        ),
      ),
    ),
  );

  handleTableProductSearchChanges = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getPagedProductsSearch, getSortedProductsSearch, getFilteredProductsSearch),
      map(() => getProductsSearch()),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppStateModel>,
    private readonly toastService: ToastService,
    private readonly mortgageApiService: MortgageApiService,
  ) {}
}
