<div class="paginator-container">
  <div class="page-size-container">
    <label>Number of rows</label>
    @for (pageSize of pageSizeOptions; track pageSize) {
      <span (click)="setPageSize(pageSize)" [class.active]="pageSize === selectedPageSize">
        {{ pageSize }}
      </span>
    }
  </div>

  <div class="pages-stepper">
    <button
      class="pagination-button pagination-button-previous"
      [disabled]="previousPage < 0"
      (click)="selectPage(previousPage)">
      <i class="icon-back"></i>
    </button>

    @if (selectedPage > 2) {
      <div class="page-button" (click)="selectPage(0)">1</div>
    }

    @if (selectedPage > DOTS_DISPLAY_MARGIN) {
      <div class="dot-box">...</div>
    }

    @if (previousPreviousPage >= 0) {
      <div class="page-button" (click)="selectPage(previousPreviousPage)">
        {{ displayedPageNumber(previousPreviousPage) }}
      </div>
    }

    @if (previousPage >= 0) {
      <div class="page-button" (click)="selectPage(previousPage)">
        {{ displayedPageNumber(previousPage) }}
      </div>
    }

    <div class="page-button active">
      {{ displayedPageNumber(selectedPage) }}
    </div>

    @if (nextPage < normalisedNumberOfPages) {
      <div class="page-button" (click)="selectPage(nextPage)">
        {{ displayedPageNumber(nextPage) }}
      </div>
    }

    @if (nextNextPage < normalisedNumberOfPages) {
      <div class="page-button" (click)="selectPage(nextNextPage)">
        {{ displayedPageNumber(nextNextPage) }}
      </div>
    }

    @if (selectedPage + DOTS_DISPLAY_MARGIN < normalisedNumberOfPages) {
      <div class="dot-box">...</div>
    }

    @if (numberOfPages > 1 && nextPage < numberOfPages) {
      <div class="page-button" (click)="selectPage(numberOfPages - 1)">
        {{ numberOfPages }}
      </div>
    }

    <button
      class="pagination-button pagination-button-next"
      [disabled]="numberOfPages < 1 || nextPage > numberOfPages - 1"
      (click)="selectPage(nextPage)">
      <i class="icon-back"></i>
    </button>
  </div>

  <div class="page-input">
    Go to page
    <p-inputNumber
      [allowEmpty]="true"
      [inputStyle]="{ width: '88px' }"
      [(ngModel)]="selectedGoToPage"
      [min]="1"
      [max]="numberOfPages"
      mode="decimal"
      [useGrouping]="false"
      (onKeyDown)="goToPageKeyDown($event)"
      (onInput)="onGoToPageInput($event)" />
    <i class="icon-arrow-right go-to-page-button" (click)="selectPage(selectedGoToPage - 1)"></i>
  </div>
</div>
