// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageCaseStatus.proto

export enum ENUM_MortgageCaseStatus {
  MORTGAGE_CASE_STATUS_UNSPECIFIED = 'MORTGAGE_CASE_STATUS_UNSPECIFIED',
  MORTGAGE_CASE_STATUS_AWAITING_APPROVAL = 'MORTGAGE_CASE_STATUS_AWAITING_APPROVAL',
  MORTGAGE_CASE_STATUS_OPPORTUNITY = 'MORTGAGE_CASE_STATUS_OPPORTUNITY',
  MORTGAGE_CASE_STATUS_LEAD = 'MORTGAGE_CASE_STATUS_LEAD',
  MORTGAGE_CASE_STATUS_PIPELINE = 'MORTGAGE_CASE_STATUS_PIPELINE',
  MORTGAGE_CASE_STATUS_CLOSED = 'MORTGAGE_CASE_STATUS_CLOSED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
