import {
  HTTP_AP_ListCases_ForOpportunitiesTable_Request_Filter,
  HTTP_AP_ListCases_ForOpportunitiesTable_Request_Sort,
} from '@api-new/caseservice';
import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { OpportunityTableRow } from '@platform/case/models/better-deal-table-row.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const opportunityTableFilterDefaults: HTTP_AP_ListCases_ForOpportunitiesTable_Request_Filter = {
  createdAt: null,
  clientUserFullNameOrEmail: '',
  companyIds: [],
  advisorUserFullNameOrEmail: '',
  mortgageExpiryTypes: null,
  saving: {
    min: null,
    max: null,
  },
  loanAmount: {
    min: null,
    max: null,
  },
};

export const getOpportunityTableHeaderDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<OpportunityTableRow, HTTP_AP_ListCases_ForOpportunitiesTable_Request_Sort>[] => [
  {
    columnLabel: 'Client name',
    columnKey: 'clientUserFullName',
    sortKey: 'byClientUserFullName',
    type: RowDefinitionType.FULLNAME_SHORT,
    width: {
      min: '200px',
      max: '200px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Id',
    columnKey: 'id',
    type: RowDefinitionType.TEXT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'company',
    columnKey: 'companyTradingName',
    type: RowDefinitionType.TEXT,
    sortKey: 'byCompanyTradingName',
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CASE_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'PED',
    columnKey: 'productEndDate',
    sortKey: 'byProductEndDate',
    type: RowDefinitionType.DATE,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'Expiry type',
    columnKey: 'mortgageExpiryTypeMapped',
    type: RowDefinitionType.TEXT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Adviser',
    columnKey: 'advisorUserFullName',
    type: RowDefinitionType.FULLNAME_INITIALS,
    sortKey: 'byAdvisorUserFullName',
    width: {
      min: '85px',
      max: '85px',
    },
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CASE_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
      },
    ]),
  },
  {
    columnLabel: 'Loan amount',
    columnKey: 'loanAmount',
    sortKey: 'byLoanAmount',
    type: RowDefinitionType.CURRENCY,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'Saving',
    columnKey: 'saving',
    sortKey: 'bySaving',
    type: RowDefinitionType.CURRENCY,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'Address',
    columnKey: 'address',
    type: RowDefinitionType.TEXT,
    width: {
      min: '300px',
      max: '300px',
    },
  },
  {
    columnLabel: 'Current lender',
    columnKey: 'lender',
    type: RowDefinitionType.IMAGE,
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'Created',
    columnKey: 'createdAt',
    type: RowDefinitionType.DATE_TIME_SINCE,
    sortKey: 'byCreatedAt',
    width: {
      min: '125px',
      max: '125px',
    },
  },
];
