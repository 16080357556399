import { FormControl, FormGroup } from '@angular/forms';
import { ENUM_AdvisorUserPermissionLevel, ENUM_MortgageCaseStatus, ENUM_MortgageType, ENUM_NullableBool } from '@api-new/common';
import { HTTP_AP_ListMortgages_ForTable_Request_Filter, HTTP_AP_ListMortgages_ForTable_Request_Sort } from '@api-new/mortgageservice';
import { MortgageTableRow } from '@platform/mortgage/models/mortgage-table.model';
import { ProtoFormModel } from '@shared-lib/models/proto-form.model';
import { SelectInterface } from '@shared/components/form';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission, PlatformType } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';
import { IsPlatformTypeActiveFunction } from '@shared/services/platform-type.service';

export enum MortgageTableColumnKey {
  ID = 'id',
  PRIMARY_MORTGAGE_BORROWER_FULL_NAME = 'primaryMortgageBorrowerFullName',
  COMPANY_TRADING_NAME = 'companyTradingName',
  ACCOUNT_NUMBER = 'accountNumbers',
  ADVISOR_USER_FULL_NAME = 'advisorUserFullName',
  PROPERTY_VALUE = 'propertyValue',
  CURRENT_OUTSTANDING_BALANCE = 'currentOutstandingBalance',
  LTV = 'ltv',
  MORTGAGE_TYPE = 'mortgageType',
  LENDER_LOGO = 'lenderLogo',
  FLAGS = 'flags',
  MORTGAGE_CASE_STATUS = 'mortgageCaseStatus',
  MORTGAGE_EXPIRY_TYPE = 'mortgageExpiryType',
}

export const getMortgageRowsDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
  isPlatformTypeActive: IsPlatformTypeActiveFunction,
): TableRowDefinitionModel<MortgageTableRow, HTTP_AP_ListMortgages_ForTable_Request_Sort>[] => [
  {
    columnLabel: 'Client name',
    type: RowDefinitionType.TEXT,
    columnKey: MortgageTableColumnKey.PRIMARY_MORTGAGE_BORROWER_FULL_NAME,
    sortKey: 'byPrimaryMortgageBorrowerFullName',
    width: {
      min: '240px',
      max: '240px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Account number',
    type: RowDefinitionType.TEXT,
    columnKey: MortgageTableColumnKey.ACCOUNT_NUMBER,
    isHidden: isPlatformTypeActive(PlatformType.ADVISER),
    width: {
      min: '130px',
      max: '130px',
    },
  },
  {
    columnLabel: 'Company',
    type: RowDefinitionType.TEXT,
    columnKey: MortgageTableColumnKey.COMPANY_TRADING_NAME,
    sortKey: 'byCompanyTradingName',
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Assigned to',
    type: RowDefinitionType.TEXT,
    columnKey: MortgageTableColumnKey.ADVISOR_USER_FULL_NAME,
    sortKey: 'byAdvisorUserFullName',
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Prop. value',
    type: RowDefinitionType.CURRENCY,
    columnKey: MortgageTableColumnKey.PROPERTY_VALUE,
    sortKey: 'byPropertyValue',
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'Current balance',
    type: RowDefinitionType.CURRENCY,
    columnKey: MortgageTableColumnKey.CURRENT_OUTSTANDING_BALANCE,
    sortKey: 'byMortgageCurrentOutstandingBalance',
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'LTV',
    type: RowDefinitionType.PERCENT,
    columnKey: MortgageTableColumnKey.LTV,
    sortKey: 'byLtv',
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Mort. type',
    type: RowDefinitionType.TEXT,
    columnKey: MortgageTableColumnKey.MORTGAGE_TYPE,
    sortKey: 'byMortgageType',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Lender',
    type: RowDefinitionType.IMAGE,
    columnKey: MortgageTableColumnKey.LENDER_LOGO,
    sortKey: 'byLenderName',
    isHidden: isPlatformTypeActive(PlatformType.LENDER),
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Expiry type',
    type: RowDefinitionType.TEXT,
    columnKey: MortgageTableColumnKey.MORTGAGE_EXPIRY_TYPE,
    isHidden: isPlatformTypeActive(PlatformType.LENDER),
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Flags',
    type: RowDefinitionType.IMAGE,
    columnKey: MortgageTableColumnKey.FLAGS,
    isHidden: isPlatformTypeActive(PlatformType.LENDER),
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Case status',
    type: RowDefinitionType.IMAGE,
    columnKey: MortgageTableColumnKey.MORTGAGE_CASE_STATUS,
    sortKey: 'byMortgageCaseStatus',
    isHidden:
      isPlatformTypeActive(PlatformType.LENDER) ||
      !hasCurrentUserRequiredPermissions([
        {
          permission: Permission.CASE_READ,
        },
      ]),
    width: {
      min: '120px',
      max: '120px',
    },
  },
];

export const MORTGAGE_FLAG_OPTIONS: SelectInterface<ENUM_NullableBool>[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Yes',
    value: ENUM_NullableBool.NULLABLE_BOOL_TRUE,
  },
  {
    label: 'No',
    value: ENUM_NullableBool.NULLABLE_BOOL_FALSE,
  },
];

export const MORTGAGE_CASE_OPTIONS: SelectInterface<ENUM_MortgageCaseStatus>[] = [
  {
    label: 'Approval required',
    value: ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_AWAITING_APPROVAL,
  },
  {
    label: 'Opportunity',
    value: ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_OPPORTUNITY,
  },
  {
    label: 'Lead',
    value: ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_LEAD,
  },
  {
    label: 'Pipeline',
    value: ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_PIPELINE,
  },
];

export const MORTGAGE_TYPE_OPTIONS: SelectInterface<ENUM_MortgageType>[] = [
  {
    label: 'Any',
    value: null,
  },
  {
    label: 'Residential',
    value: ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL,
  },
  {
    label: 'Buy to let',
    value: ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET,
  },
];

export type MortgageTableFilterFormModel = ProtoFormModel<HTTP_AP_ListMortgages_ForTable_Request_Filter>;

export const mortgageTableFilterDefaults: HTTP_AP_ListMortgages_ForTable_Request_Filter = {
  primaryMortgageBorrowerFullNameOrEmail: '',
  companyIds: [],
  advisorUserFullNameOrEmail: '',
  currentOutstandingBalance: { min: null, max: null },
  ltv: { min: null, max: null },
  mortgageType: null,
  lenderIds: [],
  isMortgageClosed: ENUM_NullableBool.NULLABLE_BOOL_FALSE,
  mortgageCaseStatuses: [],
  mortgageExpiryType: null,
  isMortgageCaseCreationSnoozed: null,
  accountNumber: '',
};

export const buildMortgageTableFilterForm = (): FormGroup<MortgageTableFilterFormModel> =>
  new FormGroup<MortgageTableFilterFormModel>({
    primaryMortgageBorrowerFullNameOrEmail: new FormControl(null),
    companyIds: new FormControl(null),
    advisorUserFullNameOrEmail: new FormControl(''),
    currentOutstandingBalance: new FormControl({ min: null, max: null }),
    ltv: new FormControl({ min: null, max: null }),
    mortgageType: new FormControl(null),
    lenderIds: new FormControl(null),
    isMortgageClosed: new FormControl(ENUM_NullableBool.NULLABLE_BOOL_FALSE),
    isMortgageCaseCreationSnoozed: new FormControl(null),
    mortgageCaseStatuses: new FormControl([]),
    mortgageExpiryType: new FormControl(null),
    accountNumber: new FormControl(''),
  });
