import { FormControl, FormGroup } from '@angular/forms';
import { ENUM_AdvisorUserPermissionLevel, ENUM_PreCompletionStatus } from '@api-new/common';
import {
  HTTP_AP_ListPreCompletions_ForTable_Request_Filter,
  HTTP_AP_ListPreCompletions_ForTable_Request_Sort,
} from '@api-new/mortgageservice';
import { PreCompletionTableRow } from '@platform/mortgage/models/pre-completion-table.model';
import { SelectInterface } from '@shared/components/form';
import { RangeNumber } from '@shared/components/range-select/range-select.component';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { ENUM_PreCompletionStatusMap } from '@shared/maps';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export enum PreCompletionTableColumnKey {
  ID = 'id',
  PRIMARY_MORTGAGE_BORROWER_FULL_NAME = 'primaryMortgageBorrowerFullName',
  ADDRESS_LINES = 'addressLines',
  COMPANY_TRADING_NAME = 'companyTradingName',
  ADVISOR_USER_FULL_NAME = 'advisorUserFullName',
  LOAN_AMOUNT = 'loanAmount',
  LTV = 'ltv',
  PRODUCT_NAME_HEADLINE = 'productNameHeadline',
  OFFER_DATE = 'offerDate',
  OFFER_EXPIRY_DATE = 'offerExpiryDate',
  PRE_COMPLETION_STATUS = 'preCompletionStatus',
}

export const getPreCompletionRowsDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<PreCompletionTableRow, HTTP_AP_ListPreCompletions_ForTable_Request_Sort>[] => [
  {
    columnLabel: 'Client name',
    type: RowDefinitionType.TEXT,
    columnKey: PreCompletionTableColumnKey.PRIMARY_MORTGAGE_BORROWER_FULL_NAME,
    isFrozen: true,
  },
  {
    columnLabel: 'Property Address',
    type: RowDefinitionType.ADDRESS,
    columnKey: PreCompletionTableColumnKey.ADDRESS_LINES,
  },
  {
    columnLabel: 'Company',
    type: RowDefinitionType.TEXT,
    columnKey: PreCompletionTableColumnKey.COMPANY_TRADING_NAME,
    isHidden: hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
  },
  {
    columnLabel: 'Assigned to',
    type: RowDefinitionType.TEXT,
    columnKey: PreCompletionTableColumnKey.ADVISOR_USER_FULL_NAME,
    isHidden: hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
      },
    ]),
  },
  {
    columnLabel: 'Loan amount',
    columnKey: PreCompletionTableColumnKey.LOAN_AMOUNT,
    type: RowDefinitionType.CURRENCY,
  },
  {
    columnLabel: 'Ltv',
    columnKey: PreCompletionTableColumnKey.LTV,
    type: RowDefinitionType.PERCENT,
  },
  {
    columnLabel: 'Product Detail',
    type: RowDefinitionType.TEXT,
    columnKey: PreCompletionTableColumnKey.PRODUCT_NAME_HEADLINE,
  },
  {
    columnLabel: 'Offer Date',
    columnKey: PreCompletionTableColumnKey.OFFER_DATE,
    type: RowDefinitionType.DATE,
  },
  {
    columnLabel: 'Offer Expiry Date',
    columnKey: PreCompletionTableColumnKey.OFFER_EXPIRY_DATE,
    type: RowDefinitionType.DATE,
  },
  {
    columnLabel: 'Pre-completion status',
    type: RowDefinitionType.TEXT,
    columnKey: PreCompletionTableColumnKey.PRE_COMPLETION_STATUS,
  },
];

export interface PreCompletionTableFilterFormModel {
  primaryMortgageBorrowerFullNameOrEmail: FormControl<string>;
  companyIds: FormControl<string[]>;
  advisorUserFullNameOrEmail: FormControl<string>;
  loanAmount: FormControl<RangeNumber>;
  ltv: FormControl<RangeNumber>;
  preCompletionStatus: FormControl<ENUM_PreCompletionStatus>;
}

export const preCompletionTableFilterDefaults: HTTP_AP_ListPreCompletions_ForTable_Request_Filter = {
  primaryMortgageBorrowerFullNameOrEmail: '',
  companyIds: [],
  advisorUserFullNameOrEmail: '',
  loanAmount: { min: null, max: null },
  ltv: { min: null, max: null },
  preCompletionStatus: null,
};

export const buildPreCompletionListFilterForm = (): FormGroup<PreCompletionTableFilterFormModel> =>
  new FormGroup<PreCompletionTableFilterFormModel>({
    primaryMortgageBorrowerFullNameOrEmail: new FormControl(null),
    companyIds: new FormControl(null),
    advisorUserFullNameOrEmail: new FormControl(''),
    loanAmount: new FormControl({ min: null, max: null }),
    ltv: new FormControl({ min: null, max: null }),
    preCompletionStatus: new FormControl(null),
  });
