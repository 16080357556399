import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly auth: AuthService) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // assets need to use local in develop
    if (req.url.search('assets') >= 0 || req.url.startsWith('https://storage.googleapis.com')) {
      return next.handle(req);
    } else {
      return this.auth.getToken().pipe(
        take(1),
        switchMap((token) => {
          if (token == null || token === '') {
            return next.handle(req.clone({ url: `${req.url}` }));
          }
          return next.handle(
            req.clone({
              url: `${req.url}`,
              headers: req.headers.set('Authorization', 'Bearer ' + token),
            }),
          );
        }),
      );
    }
  }
}
