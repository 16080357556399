<main>
  <section class="section maintenance">
    <header>
      <h1 class="maintenance__title">
        Sorry, we're down for a bit of planned maintenance right now.
      </h1>
    </header>

    <div class="maintenance__body">
      <p>
        We'll be back up and running as fast as possible, making millions of calculations to compare
        your unique mortgage to hundreds of deals on the market.
      </p>
      <p>
        We'll be back up and helping you manage your clients from:
        <br />
        <strong>{{ maintenanceDate$ | async }}</strong>
      </p>
    </div>
  </section>
</main>
