// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageOfferParserValidationResult.proto

export enum ENUM_MortgageOfferParserValidationResult {
  MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_UNSPECIFIED = 'MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_UNSPECIFIED',
  MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_UNCHECKED = 'MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_UNCHECKED',
  MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_FAILED = 'MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_FAILED',
  MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_WARNING = 'MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_WARNING',
  MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_PASSED = 'MORTGAGE_OFFER_PARSER_VALIDATION_RESULT_PASSED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
