// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_EmailNotificationReportingMetricType.proto

export enum ENUM_EmailNotificationReportingMetricType {
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNSPECIFIED = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNSPECIFIED',
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_PROCESSED = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_PROCESSED',
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_DELIVERED = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_DELIVERED',
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_BOUNCED = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_BOUNCED',
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNIQ_OPENS = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNIQ_OPENS',
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNIQ_CLICKS = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNIQ_CLICKS',
  EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNSUBSCRIBED = 'EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNSUBSCRIBED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
