import { HTTP_AP_GetDashboardData_Request } from '@api-new/statisticsservice';
import { createAction, props } from '@ngrx/store';

export const setDashboardFilter = createAction('[Dashboard] Set Dashboard Filter', props<{ filter: HTTP_AP_GetDashboardData_Request }>());

export const setSelectedAdviserEmail = createAction(
  '[Dashboard] Set Selected AdviserUserEmail',
  props<{ selectedAdviserUserEmail: string }>(),
);

export const resetDashboardFilters = createAction('[Dashboard] Clear Dashboard Filter');
