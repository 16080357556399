// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_DealInterestRateType.proto

export enum ENUM_DealInterestRateType {
  DEAL_INTEREST_RATE_TYPE_UNSPECIFIED = 'DEAL_INTEREST_RATE_TYPE_UNSPECIFIED',
  DEAL_INTEREST_RATE_TYPE_FIXED = 'DEAL_INTEREST_RATE_TYPE_FIXED',
  DEAL_INTEREST_RATE_TYPE_VARIABLE = 'DEAL_INTEREST_RATE_TYPE_VARIABLE',
  DEAL_INTEREST_RATE_TYPE_LIBOR = 'DEAL_INTEREST_RATE_TYPE_LIBOR',
  DEAL_INTEREST_RATE_TYPE_BBR = 'DEAL_INTEREST_RATE_TYPE_BBR',
  DEAL_INTEREST_RATE_TYPE_DISCOUNT = 'DEAL_INTEREST_RATE_TYPE_DISCOUNT',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
