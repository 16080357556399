import { ENUM_EmailNotificationReportingEmailType } from '@api-new/common';
import { ChartColorsEnum } from '@shared/enums/chart-colors.enum';

export const ENUM_EmailNotificationReportingEmailTypeMap = new Map<
  ENUM_EmailNotificationReportingEmailType,
  { text: string; color: string }
>([
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_UNSPECIFIED,
    { text: 'EMAIL_TYPE_UNSPECIFIED', color: null },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_ALL,
    { text: 'Home report - All', color: ChartColorsEnum.BLUE },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_ALL,
    { text: 'Better deal alert - All', color: ChartColorsEnum.TURQUOISE },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_FIRST,
    { text: 'Home report - First send', color: ChartColorsEnum.ORANGE_LIGHT },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_REGULAR,
    { text: 'Home report - Regular send', color: ChartColorsEnum.RED },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_HOME_REPORT_NOTIFICATION_TYPE_COLD,
    { text: 'Home report - Lost', color: ChartColorsEnum.GREY },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_INITIAL,
    { text: 'Better deal alert - Early reminder - Initial send', color: ChartColorsEnum.YELLOW },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_REMINDER_FIRST,
    { text: 'Better deal alert - Early reminder - First send', color: ChartColorsEnum.GREEN },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EARLY_REMINDER_MONTHLY,
    { text: 'Better deal alert - Early reminder - Regular send', color: ChartColorsEnum.ORANGE },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_INITIAL,
    { text: 'Better deal alert - Expiring - Initial send', color: ChartColorsEnum.BLUE_DARK },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_REMINDER_FIRST,
    { text: 'Better deal alert - Expiring - First send', color: ChartColorsEnum.PURPLE },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRING_REMINDER_MONTHLY,
    { text: 'Better deal alert - Expiring - Regular send', color: ChartColorsEnum.PURPLE_LIGHT },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_INITIAL,
    { text: 'Better deal alert - Expired - Initial send', color: ChartColorsEnum.PINK },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_REMINDER_FIRST,
    { text: 'Better deal alert - Expired - First send', color: ChartColorsEnum.BROWN },
  ],
  [
    ENUM_EmailNotificationReportingEmailType.EMAIL_NOTIFICATION_REPORTING_EMAIL_TYPE_BETTER_DEAL_ALERT_NOTIFICATION_TYPE_EXPIRED_REMINDER_MONTHLY,
    { text: 'Better deal alert - Expired - Regular send', color: ChartColorsEnum.TEAL },
  ],
  [ENUM_EmailNotificationReportingEmailType.UNRECOGNIZED, { text: 'UNRECOGNIZED', color: null }],
]);
