import moment from 'moment';

function dateToLastDayOfMonth(date: Date) {
  const momentDate = moment(date);
  return momentDate.endOf('month').toDate();
}

export function isDateLastDayOfMonth(date: Date | string) {
  const momentDate = moment(date);
  const lastDayOfMonth = moment(date).endOf('month').date();
  return momentDate.date() === lastDayOfMonth;
}

export function addDaysToDate(date: Date | string, daysToAdd: number = 0) {
  const momentDate = moment(date);
  return momentDate.add(daysToAdd, 'day').toDate();
}

export function addMonthsToDate(date: Date | string, months: number | string, daysToAdd: number = 0) {
  if (!date) {
    return null;
  }
  let newDate = typeof date === 'string' ? new Date(date) : new Date(date.getTime());

  const targetMonth = newDate.getMonth() + +months;
  newDate.setMonth(targetMonth);

  // Handle cases where the day is out of range in the new month
  if (newDate.getMonth() !== targetMonth % 12) {
    newDate.setDate(0); // Set to the last day of the previous month
  }
  // if input date is last day of month, output must be too, for example 28.2 +1 month must be 31.3, not 28.3
  if (isDateLastDayOfMonth(date)) {
    newDate = dateToLastDayOfMonth(newDate);
    // skip addition of days
    return newDate;
  }

  newDate.setDate(newDate.getDate() + daysToAdd);
  return newDate;
}
