import { ENUM_MortgageCaseStatus } from '@api-new/common';
import { RoutePaths } from '@app/app.utils';
import { SharedCaseStatus } from '@shared/enums';

export const ENUM_MortgageCaseStatusMap = new Map([
  [
    ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_AWAITING_APPROVAL,
    {
      text: 'Approval Required',
      icon: 'icon-hourglass',
      route: RoutePaths.OpportunitiesApprovalRequired,
      sharedCaseStatus: SharedCaseStatus.APPROVAL_REQUIRED,
    },
  ],
  [
    ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_OPPORTUNITY,
    {
      text: 'Opportunity',
      icon: 'icon-person-with-arrows',
      route: RoutePaths.Opportunities,
      sharedCaseStatus: SharedCaseStatus.APPROVED,
    },
  ],
  [
    ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_LEAD,
    {
      text: 'Lead',
      icon: 'icon-diamond',
      route: RoutePaths.CasesLeads,
      sharedCaseStatus: SharedCaseStatus.LEAD,
    },
  ],
  [
    ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_PIPELINE,
    {
      text: 'Pipeline',
      icon: 'icon-pipeline',
      route: RoutePaths.CasesPipeline,
      sharedCaseStatus: SharedCaseStatus.ACTIVE,
    },
  ],
  [
    ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_CLOSED,
    {
      text: 'Closed',
      icon: 'icon-closed-sign',
      route: RoutePaths.CasesClosed,
      sharedCaseStatus: SharedCaseStatus.CLOSED,
    },
  ],
  [
    ENUM_MortgageCaseStatus.MORTGAGE_CASE_STATUS_UNSPECIFIED,
    {
      text: 'Unspecified',
      icon: 'Unspecified',
      route: RoutePaths.Cases,
      sharedCaseStatus: null,
    },
  ],
  [
    ENUM_MortgageCaseStatus.UNRECOGNIZED,
    {
      text: 'Unrecognized',
      icon: 'Unrecognized',
      route: RoutePaths.Cases,
      sharedCaseStatus: null,
    },
  ],
]);
