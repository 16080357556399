import { ENUM_MortgageType } from '@api-new/common';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { SortType } from '@shared/components/table';
import { ProductsSearchState } from '../../models/app-state.model';
import { GoldfishSimpleProduct } from '../../models/products-search.model';
import {
  getFilteredProductsSearch,
  getPagedProductsSearch,
  getProductsSearch,
  getProductsSearchDetailSuccess,
  getProductsSearchFailure,
  getProductsSearchSuccess,
  getSortedProductsSearch,
  resetProductCatalogFilter,
} from './products-search.action';

export const productAdapter: EntityAdapter<GoldfishSimpleProduct> = createEntityAdapter<GoldfishSimpleProduct>({
  selectId: (product) => product.id,
});
export const productCatalogFilterDefaults = {
  lenderIds: null,
  mortgageType: ENUM_MortgageType.MORTGAGE_TYPE_UNSPECIFIED,
  maxLTV: null,
};

const initialState: ProductsSearchState = {
  products: productAdapter.getInitialState(),
  loading: false,
  errors: null,
  table: {
    selectedFilter: productCatalogFilterDefaults,
    pagination: {
      pageSize: 10,
      pageNumber: 0,
      totalPages: null,
      totalCount: null,
    },
    sort: {
      sortKey: null,
      sortOrder: SortType.SORT_TYPE_ASC,
    },
  },
};

const reducer = createReducer(
  initialState,

  on(
    getProductsSearch,
    (state): ProductsSearchState => ({
      ...state,
      products: productAdapter.removeAll(state.products),
      loading: true,
    }),
  ),

  on(getSortedProductsSearch, (state, { sort }) => ({
    ...state,
    table: {
      ...state.table,
      sort,
    },
  })),

  on(getFilteredProductsSearch, (state, { selectedFilter }) => ({
    ...state,
    table: {
      ...state.table,
      selectedFilter,
      pagination: {
        pageNumber: 0,
        pageSize: state.table.pagination.pageSize,
        totalCount: null,
        totalPages: null,
      },
    },
  })),

  on(getPagedProductsSearch, (state, { pagination }) => ({
    ...state,
    table: {
      ...state.table,
      pagination: {
        totalCount: state.table.pagination.totalCount,
        ...pagination,
      },
    },
  })),

  on(
    getProductsSearchFailure,
    (state, { error }): ProductsSearchState => ({
      ...state,
      products: productAdapter.getInitialState(),
      loading: false,
      errors: error,
      table: {
        ...state.table,
        pagination: {
          ...state.table.pagination,
          pageNumber: 0,
          pageSize: 10,
          totalPages: 0,
          totalCount: 0,
        },
      },
    }),
  ),

  on(
    getProductsSearchSuccess,
    (state, { response }): ProductsSearchState => ({
      ...state,
      products: productAdapter.setAll(response?.products ?? [], state.products),
      loading: false,
      errors: null,
      table: {
        ...state.table,
        pagination: {
          ...state.table.pagination,
          totalPages: response.pagination?.totalPages,
          totalCount: response.pagination?.totalCount,
        },
      },
    }),
  ),

  on(
    getProductsSearchDetailSuccess,
    (state, { detail }): ProductsSearchState => ({
      ...state,
      products: productAdapter.updateOne({ id: detail.productId, changes: { detail: detail as any } }, state.products),
    }),
  ),

  on(resetProductCatalogFilter, (state): ProductsSearchState => {
    return {
      ...state,
      loading: true,
      table: {
        ...state.table,
        selectedFilter: {
          ...state.table.selectedFilter,
          ...productCatalogFilterDefaults,
        },
        pagination: {
          ...state.table.pagination,
          totalCount: null,
          totalPages: null,
        },
      },
    };
  }),
);

export function productsSearchReducer(state: ProductsSearchState = initialState, action: Action): ProductsSearchState {
  return reducer(state, action);
}
