import { ENUM_CreditReportAccountType } from '@api-new/common';

export const ENUM_CreditReportAccountTypeMap = new Map([
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_UNSPECIFIED, ''],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_BRIDGING_FINANCE, 'Bridging finance'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_BUY_TO_LET_MORTGAGE, 'Buy to let mortgage'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_HOME_LENDING_AGREEMENT, 'Home lending agreement'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_SECURED_LOAN, 'Secured loan'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_HIRE_PURCHASE, 'Hire purchase'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_CONSOLIDATED_DEBT, 'Consolidated debt'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_FIXED_TERM_AGREEMENT, 'Fixed term agreement'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_GREEN_DEALS, 'Green deals'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_INSURANCE_AGREEMENT, 'Insurance agreement'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_PAY_DAY_OR_SHORT_TERM_LOAN, 'Pay day or short term loan'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_UNSECURED_LOAN, 'Unsecured loan'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_BUDGET_ACCOUNT, 'Budget account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_CHARGE_CARD, 'Charge card'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_CREDIT_CARD, 'Credit card'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_MAIL_ORDER_ACCOUNT, 'Mail order account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_OPTION_ACCOUNT, 'option account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_XMAS_CLUB, 'Xmas club'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_BANK_DEFAULT_AGREEMENT, 'Bank default agreement'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_BASIC_BANK_ACCOUNT, 'Basic bank account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_CURRENT_ACCOUNT, 'Current account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_UNPRESENTABLE_CHEQUE, 'Unpresentable cheque'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_COMMS_SUPPLY_ACCOUNT, 'Comms supply account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_COUNCIL_ARREARS, 'Council arrears'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_LOCAL_AUTHORITY_HOUSING, 'Local authority housing'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_PROPERTY_RENTAL, 'Property rental'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_PUBLIC_UTILITY_ACCOUNT, 'Public utility account'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_RENTAL_AGREEMENT, 'Rental agreement'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_SOCIAL_HOUSING_RENTAL, 'Social housing rental'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_STUDENT_LOAN, 'Student loan'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_UNCATEGORISED_AGREEMENT, 'Uncategorised agreement'],
  [ENUM_CreditReportAccountType.CREDIT_REPORT_ACCOUNT_TYPE_MORTGAGE, 'Mortgage'],
  [ENUM_CreditReportAccountType.UNRECOGNIZED, ''],
]);
