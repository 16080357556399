import { Injectable } from '@angular/core';
import { LocalStorageKey } from '@shared/enums';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  protected readonly localStorage: Storage = window.localStorage;

  setItem(key: LocalStorageKey, value: string): void {
    this.localStorage.setItem(key, value);
  }

  getItem(key: LocalStorageKey): string {
    return this.localStorage.getItem(key);
  }

  removeItem(key: LocalStorageKey): void {
    this.localStorage.removeItem(key);
  }

  clear(): void {
    this.localStorage.clear();
  }
}
