import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CollapseDirective } from '@shared/directives/collapse.directive';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'ap-common-table-page-header',
  templateUrl: './common-table-page-header.component.html',
  styleUrls: ['./common-table-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CollapseDirective, ButtonModule],
})
export class CommonTablePageHeaderComponent implements AfterViewInit {
  @Input() header: string;
  @Input() set isFilterExpanded(isFilterExpanded) {
    this._isFilterExpanded = isFilterExpanded;
  }
  @Input() numberOfAppliedFilters: number;
  @Output() resetFilter: EventEmitter<void> = new EventEmitter();

  @ViewChild('filter') filter: ElementRef;
  hasFilter = false;

  protected _isFilterExpanded = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.hasFilter = this.filter?.nativeElement.children.length > 0;
    this.cdRef.markForCheck();
  }

  get isFilterExpanded(): boolean {
    return this._isFilterExpanded;
  }

  protected toggleFilterExpanded(): void {
    this.isFilterExpanded = !this._isFilterExpanded;
  }

  protected emitResetFilter(): void {
    this.resetFilter.emit();
  }
}
