import { HTTP_AP_ListCases_ForLeadsTable_Request_Filter, HTTP_AP_ListCases_ForLeadsTable_Request_Sort } from '@api-new/caseservice';
import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { LeadsTableRow } from '@platform/case/models/leads-table-row.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const leadCaseTableHeaderDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<LeadsTableRow, HTTP_AP_ListCases_ForLeadsTable_Request_Sort>[] => [
  {
    columnLabel: 'Client name',
    columnKey: 'clientUserFullName',
    sortKey: 'byClientUserFullName',
    isFrozen: true,
    type: RowDefinitionType.FULLNAME_SHORT,
    width: {
      min: '150px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Case type',
    columnKey: 'caseTypeMapped',
    sortKey: 'byCaseType',
    type: RowDefinitionType.TEXT,
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'Id',
    columnKey: 'id',
    type: RowDefinitionType.TEXT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Company',
    columnKey: 'companyTradingName',
    sortKey: 'byCompanyTradingName',
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CASE_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    type: RowDefinitionType.TEXT,
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Introducer',
    columnKey: 'affiliateName',
    type: RowDefinitionType.TEXT,
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'Adviser',
    columnKey: 'advisorUserFullName',
    sortKey: 'byAdvisorUserFullName',
    type: RowDefinitionType.FULLNAME_INITIALS,
    width: {
      min: '85px',
      max: '85px',
    },
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CASE_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
      },
    ]),
  },
  {
    columnLabel: 'Loan amount',
    columnKey: 'loanAmount',
    sortKey: 'byLoanAmount',
    type: RowDefinitionType.CURRENCY,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'Ltv',
    columnKey: 'ltv',
    type: RowDefinitionType.PERCENT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Property value',
    columnKey: 'propertyValue',
    sortKey: 'byPropertyValue',
    type: RowDefinitionType.CURRENCY,
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Created',
    columnKey: 'createdAt',
    sortKey: 'byCreatedAt',
    type: RowDefinitionType.DATE_TIME_SINCE,
    width: {
      min: '120px',
      max: '120px',
    },
  },
];

export const leadCaseTableFilterDefaults: HTTP_AP_ListCases_ForLeadsTable_Request_Filter = {
  createdAt: null,
  clientUserFullNameOrEmail: '',
  companyIds: [],
  advisorUserFullNameOrEmail: '',
  caseType: null,
  loanAmount: { min: null, max: null },
  propertyValue: { min: null, max: null },
};
