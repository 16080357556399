export enum FeatureFlag {
  LANDCLAN_ADDRESSES = 'LANDCLAN_ADDRESSES',
  CREDIT_REPORT_CLIENT_TABS = 'CREDIT_REPORT_CLIENT_TABS',
  CREDIT_REPORT_SETTINGS = 'CREDIT_REPORT_SETTINGS',
  PRE_COMPLETIONS = 'PRE_COMPLETIONS',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
}

export interface FeatureConfig {
  [key: string]: boolean;
}
