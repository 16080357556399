import { throwError } from 'rxjs';

/**
 * Replace all the placeholders in given string, which are in curly braces and exactly match the param in params object.
 * Example: Input string like "/advisors/{id}" with params object {id: 27} will return "/advisor/27"
 * @param input: string with
 * @param params: pairs of keys and values that will be replaced in input string
 * @returns {string} - final string with replaced placeholder
 * */
export const replaceVariablesInString = (input: string, params: {}): string => {
  let replacedString = input;
  for (const key of Object.keys(params)) {
    const replace = `{${key}}`;
    const regex = new RegExp(replace, 'i');
    replacedString = replacedString.replace(regex, params[key]);
  }

  const isPlaceholderLeftInString = replacedString.match(/{\w*}/g);
  if (isPlaceholderLeftInString !== null) {
    console.error(`API path '${input}' have unresolved placeholders: ${isPlaceholderLeftInString.toString()}`);
    throw throwError(isPlaceholderLeftInString);
  }

  return replacedString;
};
