// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CompanyType.proto

export enum ENUM_CompanyType {
  COMPANY_TYPE_UNSPECIFIED = 'COMPANY_TYPE_UNSPECIFIED',
  COMPANY_TYPE_BROKER_COMPANY = 'COMPANY_TYPE_BROKER_COMPANY',
  COMPANY_TYPE_LENDER_COMPANY = 'COMPANY_TYPE_LENDER_COMPANY',
  COMPANY_TYPE_AGGREGATOR_COMPANY = 'COMPANY_TYPE_AGGREGATOR_COMPANY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
