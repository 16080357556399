import { ENUM_MortgageType } from '@api-new/common';
import { GoldfishERC, GoldfishFee, GoldfishIncentive, GoldfishLegalData, GoldfishStepRate, GoldfishValuation } from '@api/OLD_models';

enum ProductType {
  NONE = 0,
  STANDARD = 1,
  STEPPED = 2,
  FIXED = 4,
  DISCOUNT = 8,
  STBTL = 16,
  AMDM = 32,
  SELF = 64,
  BBR = 128,
  LIBOR = 256,
}

export interface GoldfishProduct {
  // ProductHistorical status of product
  productHistorical: boolean; // false: actual product, true: historical product
  productHistoricalSince: number; // ProductHistoricalSince timestamp of historical product
  productId: string;
  providerId: string;
  lender: string;
  product: string;
  productCode: string;
  productBrandName: string;
  productType: string;
  existingCustomerType: string;
  existingBorrowerType: string;
  introductionDate: string;
  initialRate: number;
  initialRateType: string;
  initialRateToBeLoaded: number;
  variableRateTerm: number;
  interestRestPeriod: string;
  initialYears: number;
  minLtv: number;
  maxLtv: number;
  maxLoan: number;
  minTerm: number;
  maxTerm: number;
  termMonths: number;
  homemover: boolean;
  ftb: boolean;
  remortgage: boolean;
  btl: boolean;
  portable: boolean;
  offset: boolean;
  sharedOwnership: string;
  rtb: string;
  ercTermMonths: number;
  ercCalculation: string;
  ercEndDate: string;
  rtbRemoMaxLtv: number;
  rtbMaxLtv: number;
  securedLoanMaxLtv: number;
  purchaseMaxLtv: number;
  remortgageMaxLtv: number;
  ftbMaxLtv: number;
  btlFtbMaxLtv: number;
  btlRemoMaxLtv: number;
  btlMaxLtv: number;
  letToBuyMaxLtv: number;
  sharedMaxLtv: number;
  scSeMaxLtv: number;
  scMaxLtv: number;
  minLoan: number;
  minLoanRemortgage: number;
  minLoanFtb: number;
  minLoanHomemove: number;
  minLoanRtb: number;
  minLoanSo: number;
  minLoanIntOnly: number;
  minLoanRepayVehicle: number;
  totalApplicationFeesGbp: number;
  finalRate: number;
  finalRateType: string;
  valuationFee: string;
  typeRepayment: boolean;
  typeEndowment: boolean;
  typePension: boolean;
  typeIsa: boolean;
  typeInterestOnly: boolean;
  selfCertification: boolean;
  ercAvailable: boolean;
  distributionDirect: boolean;
  distributionIntermediary: boolean;
  distributionChannels: string;
  brokerNetworks: string;
  stepRates: GoldfishStepRate[];
  type: ProductType;
  ercs: GoldfishERC[];
  fees: GoldfishFee[];
  incentives: GoldfishIncentive[];
  legalData: GoldfishLegalData[];
  valuations: GoldfishValuation[];
  availableInEngland: boolean;
  availableInWales: boolean;
  availableInScotland: boolean;
  availableInNIreland: boolean;
  geographicAvailability: string;
  regionalRestriction: boolean;
  newBuild: boolean;
}

export interface GoldfishSimpleProduct {
  id: string;
  lenderName: string;
  productCode: string;
  name: string;
  mortgageType: string;
  initialRate: number;
  rateType: string;
  initialPeriod: number;
  maxLtv: number;
  incentives: boolean;
  fees: boolean;
  maxLoan: number;
  detail?: GoldfishProduct; // Added for FE purposes only
}

export interface ProductsSearchSearchModel {
  lenderIds?: string[];
  productIds?: string[];
  mortgageType?: ENUM_MortgageType;
  purposes?: number[];
  maxLtv?: number;
  productTypes?: number[];
  initialPeriod?: number;
  distributions?: number[];
  historical?: boolean;
  productCode?: string;
}

export interface UpdateDealTableRow {
  id?: string;
  lenderLogo?: string;
  maxLtv?: number;
  maxLoan?: number;
  initialRate?: number;
  button?: number;
}

export enum ListProductType {
  PRODUCT_TYPE_UNSPECIFIED = 'PRODUCT_TYPE_UNSPECIFIED',
  PRODUCT_TYPE_STANDARD = 'PRODUCT_TYPE_STANDARD',
  PRODUCT_TYPE_STEPPED = 'PRODUCT_TYPE_STEPPED',
  PRODUCT_TYPE_FIXED = 'PRODUCT_TYPE_FIXED',
  PRODUCT_TYPE_DISCOUNT = 'PRODUCT_TYPE_DISCOUNT',
  PRODUCT_TYPE_STBTL = 'PRODUCT_TYPE_STBTL',
  PRODUCT_TYPE_AMDM = 'PRODUCT_TYPE_AMDM',
  PRODUCT_TYPE_SELF = 'PRODUCT_TYPE_SELF',
  PRODUCT_TYPE_BBR = 'PRODUCT_TYPE_BBR',
  PRODUCT_TYPE_LIBOR = 'PRODUCT_TYPE_LIBOR',
}

export interface GoldfishProductDetail {
  product: string;
  lender: string;
  productCode: string;
  productHistorical: string;
  portable: string;
  minLtv?: number;
  maxLtv?: number;
  maxLoan: number;
  minLoan: number;
  minLoanFtb: number;
  minLoanHomemove: number;
  minLoanIntOnly: number;
  minLoanRemortgage: number;
  minLoanRepayVehicle: number;
  minLoanRtb: number;
  minLoanSo: number;
  purpose: string[];
  sharedOwnership: string;
  rtb: string;
  stepRates: {
    type: string;
    rate: number;
    months: string;
  }[];
  incentives: {
    product: string;
    amount: number;
  }[];
  fees: {
    typeString: string;
    type: 'percent' | 'currency';
    amount: number;
    timingOfPayment: string;
  }[];
  ercs: string[];
  typeRepayment: string;
  typeEndowment: string;
  typePension: string;
  typeIsa: string;
  typeInterestOnly: string;
}

export interface GoldfishProductSearch {
  id: string;
  name: string;
  lenderName: string;
  productCode: string;
  mortgageType: string;
  initialRate: number;
  rateType: ProductType | string;
  initialPeriod: string;
  maxLtv: number;
  incentives: string;
  fees: string;
  maxLoan: number;
  detail?: GoldfishProductDetail;
}
