import { HTTP_AP_Company, HTTP_AP_Company_ForDetails } from '@api-new/companyservice';
import { HTTP_AP_CompanyNotificationSettings } from '@api-new/notificationservice';
import { createAction, props } from '@ngrx/store';
import { CreateCompanyFormRawValues } from '@platform/company/pages/company-create/utils/buildCreateCompanyForm';
import { SharedFileModel } from '@shared-lib/models/shared-file.model';
import { ErrorModel } from '@shared/models/app-state.model';
import { UpdateCompanyFormRawValues } from '../utils/formUtils';

export const getCompanyById = createAction('[COMPANY] Get Company By ID', props<{ id: string }>());

export const getCompanyByIdSuccess = createAction('[COMPANY] Get Company By ID Success', props<{ company: HTTP_AP_Company }>());

export const getCompanyByIdFailure = createAction('[COMPANY] Get Company By ID Failure', props<{ error: ErrorModel }>());

export const setSelectedCompanyDetailById = createAction('[COMPANY] Set Selected Company By Id', props<{ id: string }>());

export const getCompanyDetailId = createAction('[COMPANY] Get Company Detail By Id', props<{ id: string }>());

export const getCompanyDetailSuccess = createAction(
  '[COMPANY] Get Company Detail By Id Success',
  props<{ company: HTTP_AP_Company_ForDetails }>(),
);

export const getCompanyDetailFailure = createAction('[COMPANY] Get Company Detail By Id Failure', props<{ error: ErrorModel }>());

export const updateCompanyNotificationsSettings = createAction(
  '[COMPANY] Update Company Notification Settings',
  props<{ notificationSettings: HTTP_AP_CompanyNotificationSettings }>(),
);

export const updateCompanyNotificationsSettingsFailure = createAction(
  '[COMPANY] Update Company Notification Settings Failure',
  props<{ error: ErrorModel }>(),
);

export const updateCompanyDetail = createAction(
  '[COMPANY] Update Company',
  props<{ company: UpdateCompanyFormRawValues; companyId: string }>(),
);

export const updateCompanyDetailSuccess = createAction(
  '[COMPANY] Update Company Success',
  props<{ company: HTTP_AP_Company_ForDetails }>(),
);

export const updateCompanyDetailFailure = createAction('[COMPANY] Update Company Failure', props<{ error: ErrorModel }>());

export const updateCompanyLogo = createAction('[COMPANY] Update Company Logo', props<{ logoFile: SharedFileModel; companyId: string }>());

export const updateCompanyLogoSuccess = createAction(
  '[COMPANY] Update Company Logo Success',
  props<{ companyDetail: HTTP_AP_Company_ForDetails }>(),
);

export const updateCompanyLogoFailure = createAction('[COMPANY] Update Company Logo Failure', props<{ error: ErrorModel }>());

export const createCompany = createAction('[COMPANY] Create Company', props<{ company: CreateCompanyFormRawValues }>());

export const createCompanySuccess = createAction('[COMPANY] Create Company Success', props<{ company: HTTP_AP_Company }>());

export const createCompanyFailure = createAction('[COMPANY] Create Company Failure', props<{ error: ErrorModel }>());

export const setCompanyBasicInfoEditMode = createAction('[COMPANY] Set Basic Information Edit Mode', props<{ isInEditMode: boolean }>());
