import { ENUM_MortgageType } from '@api-new/common';
import { createSelector } from '@ngrx/store';
import { ProductSearchForm } from '@platform/product-catalogue/pages/product-catalogue-table/product-catalogue-table.component';
import { isEqual } from '@shared-lib/utils/isEqual';
import { PaginationModel, TableSortingModel } from '@shared/components/table';
import { productAdapter, productCatalogFilterDefaults } from '@shared/store/products-search/products-search.reducer';
import { booleanToYesNo, capitalize, getFormattedDurationMonths } from '@shared/utils';
import { ProductsSearchSort } from '../../enums';
import { AppStateModel, ProductsSearchState } from '../../models/app-state.model';
import { GoldfishProductSearch, ListProductType } from '../../models/products-search.model';

const { selectAll } = productAdapter.getSelectors();
export const selectProductCatalogState = (state: AppStateModel): ProductsSearchState => state.productsSearch;

export const $productsSearchLoading = createSelector(selectProductCatalogState, (state: ProductsSearchState) => state.loading);

export const $productsSearchMetadata = createSelector(
  selectProductCatalogState,
  (
    state: ProductsSearchState,
  ): {
    search: ProductSearchForm;
    paging: PaginationModel;
    sort: TableSortingModel<ProductsSearchSort>;
  } =>
    state
      ? {
          search: state.table.selectedFilter,
          paging: state.table.pagination,
          sort: state.table.sort,
        }
      : null,
);

export const rateTypeEnumToText = (rateType: string): string => {
  const rateTypeNames: { [T in ListProductType]: string } = {
    [ListProductType.PRODUCT_TYPE_AMDM]: 'AMDM',
    [ListProductType.PRODUCT_TYPE_UNSPECIFIED]: 'Unspecified',
    [ListProductType.PRODUCT_TYPE_STANDARD]: 'Standard',
    [ListProductType.PRODUCT_TYPE_STEPPED]: 'Stepped',
    [ListProductType.PRODUCT_TYPE_FIXED]: 'Fixed',
    [ListProductType.PRODUCT_TYPE_DISCOUNT]: 'Discount',
    [ListProductType.PRODUCT_TYPE_STBTL]: 'Stbtl',
    [ListProductType.PRODUCT_TYPE_SELF]: 'Self',
    [ListProductType.PRODUCT_TYPE_BBR]: 'Bbr',
    [ListProductType.PRODUCT_TYPE_LIBOR]: 'Libor',
  };
  return rateTypeNames[rateType.toString()];
};

const mortgageTypeEnumToText = (mortgageType: string): string => {
  const mortgageTypeNames: { [T in ENUM_MortgageType]: string } = {
    [ENUM_MortgageType.MORTGAGE_TYPE_RESIDENTIAL]: 'Residential',
    [ENUM_MortgageType.MORTGAGE_TYPE_BUY_TO_LET]: 'Buy to let',
    [ENUM_MortgageType.UNRECOGNIZED]: 'Unrecognized',
    [ENUM_MortgageType.MORTGAGE_TYPE_UNSPECIFIED]: '-',
  };
  return mortgageTypeNames[mortgageType.toString()];
};

/* eslint-disable complexity */
export const $productsTable = createSelector(
  selectProductCatalogState,
  (state: ProductsSearchState): GoldfishProductSearch[] =>
    state != null &&
    selectAll(state.products)?.map((product) => ({
      id: product.id,
      name: product.name,
      lenderName: product.lenderName,
      productCode: product.productCode,
      mortgageType: mortgageTypeEnumToText(product.mortgageType),
      initialRate: product.initialRate,
      rateType: rateTypeEnumToText(product.rateType),
      initialPeriod: getFormattedDurationMonths(product.initialPeriod),
      maxLtv: product.maxLtv,
      incentives: booleanToYesNo(product.incentives),
      fees: booleanToYesNo(product.fees),
      maxLoan: product.maxLoan,
      ...(product.detail && {
        detail: {
          product: product.detail.product,
          lender: product.detail.lender,
          productCode: product.detail.productCode,
          productHistorical: booleanToYesNo(!product.detail.productHistorical),
          portable: booleanToYesNo(product.detail.portable),
          ...((product.detail.maxLtv && {
            maxLtv: product.detail.maxLtv / 100,
          }) ||
            []),
          ...((product.detail.minLtv && {
            minLtv: product.detail.minLtv / 100,
          }) ||
            []),
          maxLoan: product.detail.maxLoan,
          minLoan: product.detail.minLoan,
          minLoanFtb: product.detail.minLoanFtb,
          minLoanHomemove: product.detail.minLoanHomemove,
          minLoanIntOnly: product.detail.minLoanIntOnly,
          minLoanRemortgage: product.detail.minLoanRemortgage,
          minLoanRepayVehicle: product.detail.minLoanRepayVehicle,
          minLoanRtb: product.detail.minLoanRtb,
          minLoanSo: product.detail.minLoanSo,
          purpose: [
            ...(product.detail.homemover ? ['Homemover'] : []),
            ...(product.detail.remortgage ? ['Remortgage'] : []),
            ...(product.detail.ftb ? (product.detail.typeInterestOnly ? ['Interest Only FTB'] : ['FTB']) : []),
            ...(product.detail.typeInterestOnly ? ['Interest Only FTB'] : []),
            ...(product.detail.existingBorrowerType === 'moving' ? ['ExB Moving'] : []),
            ...(product.detail.existingBorrowerType === 'not moving' ? ['ExB Not Moving'] : []),
          ],
          sharedOwnership: capitalize(product.detail.sharedOwnership || 'Unknown'),
          rtb: capitalize(product.detail.rtb || 'Unknown'),
          stepRates: (product.detail.stepRates || []).map((rate) => ({
            type: capitalize(rateTypeEnumToText(rate.type || 'Fixed') || 'Fixed'),
            rate: rate.rate,
            months: rate.months ? getFormattedDurationMonths(rate.months) : 'Remaining Term',
          })),
          incentives: (product.detail.incentives || []).map((incentive) => ({
            type: capitalize(incentive.type || 'Unknown type'),
            product: incentive.product,
            amount: Math.max(incentive.value, 0) + Math.max(incentive.discountedLegalFee, 0),
          })),
          fees: (product.detail.fees || []).map((fee) => ({
            typeString: (fee.type || '').replace(/[%£]/, '').trim(),
            type: (fee.type || '').startsWith('%') ? 'percent' : (fee.type || '').startsWith('£') ? 'currency' : null,
            amount: fee.amount,
            timingOfPayment: fee.timingOfPayment,
          })),
          typeRepayment: booleanToYesNo(product.detail.typeRepayment),
          typeEndowment: booleanToYesNo(product.detail.typeEndowment),
          typePension: booleanToYesNo(product.detail.typePension),
          typeIsa: booleanToYesNo(product.detail.typeIsa),
          typeInterestOnly: booleanToYesNo(product.detail.typeInterestOnly),
          ercs: (product.detail.ercs || []).map(
            (erc) => `${erc.amount}${erc.calculationType} in year ${(erc.stepNumber || '').split('/')[0]}`,
          ),
        },
      }),
    })),
);

export const $hasProductSearchDetail = (id: string) =>
  createSelector($productsTable, (products: GoldfishProductSearch[]): boolean => {
    const productById = (products || []).find((product) => product.id === id);
    return !!(productById && productById.detail);
  });

export const selectProductsTablePagination = createSelector(
  selectProductCatalogState,
  (state: ProductsSearchState): PaginationModel => state.table.pagination,
);

export const selectProductsTableSort = createSelector(
  selectProductCatalogState,
  (state: ProductsSearchState): TableSortingModel<ProductsSearchSort> => {
    return state.table.sort;
  },
);

export const selectedProductsTableFilters = createSelector(
  selectProductCatalogState,
  (state: ProductsSearchState): ProductSearchForm => state.table.selectedFilter,
);

export const selectNumberOfAppliedProductCatalogTableFilters = createSelector(
  selectProductCatalogState,
  (state: ProductsSearchState): number => {
    if (state.table.selectedFilter) {
      let numberOfAppliedFilters = 0;
      Object.keys(state.table.selectedFilter).forEach((key) => {
        if (!isEqual(productCatalogFilterDefaults[key], state.table.selectedFilter[key])) {
          numberOfAppliedFilters += 1;
        }
      });
      return numberOfAppliedFilters;
    }
    return null;
  },
);

export const selectedErrors = createSelector(selectProductCatalogState, (state: ProductsSearchState) => state.errors);
