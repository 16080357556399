import { Action, createReducer, on } from '@ngrx/store';
import { PlatformType } from '@shared/enums';
import { PlatformState } from '@shared/models/platform-state.model';
import { setPlatformType } from '@shared/store/platform/platform.actions';

const initialPlatformState: PlatformState = {
  platformType: PlatformType.ADVISER,
};

const reducer = createReducer(
  initialPlatformState,

  on(setPlatformType, (state, { platformType }) => ({
    ...state,
    platformType,
  })),
);

export function platformReducer(state: PlatformState = initialPlatformState, action: Action): PlatformState {
  return reducer(state, action);
}
