import { ENUM_CaseType } from '@api-new/common';
import { RoutePaths } from '@app/app.utils';

export const ENUM_CaseTypeMap = new Map([
  [
    ENUM_CaseType.CASE_TYPE_UNSPECIFIED,
    {
      text: 'Unspecified',
      routerPath: null,
    },
  ],
  [
    ENUM_CaseType.CASE_TYPE_MORTGAGE,
    {
      text: 'Mortgage',
      routerPath: RoutePaths.MortgageCaseDetail,
    },
  ],
  [
    ENUM_CaseType.CASE_TYPE_SMART_SEARCH,
    {
      text: 'Smart search',
      routerPath: RoutePaths.SmartSearchCaseDetail,
    },
  ],
  [
    ENUM_CaseType.CASE_TYPE_HELP,
    {
      text: 'Help',
      routerPath: RoutePaths.HelpCaseDetail,
    },
  ],
  [
    ENUM_CaseType.UNRECOGNIZED,
    {
      text: 'Unrecognized',
      routerPath: null,
    },
  ],
]);
