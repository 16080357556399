import {
  HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Filter,
  HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Sort,
} from '@api-new/propertyservice';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';

export const outcodeToRegionFilterDefaults: HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Filter = {
  region: '',
  outcode: '',
};

export enum OutcodeToRegionTableColumns {
  outcode = 'outcode',
  region = 'region',
}

export interface OutcodeToRegionTableRow {
  outcode: string;
  region: string;
  hasActionInProgress: boolean;
  isRecentlySaved: boolean;
}

export const getOutcodeToRegionTableDefinition = (): TableRowDefinitionModel<
  OutcodeToRegionTableRow,
  HTTP_AP_ListHPIOutcodeToRegionEntries_Request_Sort
>[] => [
  {
    columnLabel: 'Outcode',
    columnKey: OutcodeToRegionTableColumns.outcode,
    sortKey: 'byOutcode',
    type: RowDefinitionType.TEXT,
    width: {
      min: '80px',
      max: '80px',
    },
  },
  {
    columnLabel: 'Region',
    columnKey: OutcodeToRegionTableColumns.region,
    sortKey: 'byRegion',
    type: RowDefinitionType.TEXT,
    width: {
      min: '150px',
      max: '150px',
    },
  },
];
