import moment from 'moment/moment';
import { momentify } from './momentify';

export const dateToFormat = (date: string | moment.Moment, format: string): string => {
  const newDate = momentify(date);
  if (newDate) {
    return newDate.format(format);
  } else {
    return null;
  }
};
