import { ENUM_PropertyValuationFrequency } from '@api-new/common';

export const ENUM_PropertyValuationFrequencyMap = new Map([
  [ENUM_PropertyValuationFrequency.PROPERTY_VALUATION_FREQUENCY_UNSPECIFIED, '-'],
  [ENUM_PropertyValuationFrequency.PROPERTY_VALUATION_FREQUENCY_NEVER, 'Never'],
  [ENUM_PropertyValuationFrequency.PROPERTY_VALUATION_FREQUENCY_MONTHLY, 'Monthly'],
  [ENUM_PropertyValuationFrequency.PROPERTY_VALUATION_FREQUENCY_QUARTERLY, 'Quarterly'],
  [ENUM_PropertyValuationFrequency.PROPERTY_VALUATION_FREQUENCY_SEMIANNUALLY, 'Semi-annually'],
  [ENUM_PropertyValuationFrequency.PROPERTY_VALUATION_FREQUENCY_ANNUALLY, 'Annually'],
  [ENUM_PropertyValuationFrequency.UNRECOGNIZED, '-'],
]);
