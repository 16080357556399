import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from '../../models/app-state.model';
import {
  authError,
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  resetPasswordResetDoneAction,
  silentLoginSuccessAction,
} from './auth.action';

const initialState: AuthState = {
  errors: null,
  loading: false,
  metadata: {
    resetRequestDone: false,
    authErrorCode: null,
  },
};

const reducer = createReducer(
  initialState,

  on(loginAction, (state) => ({
    ...state,
    loading: true,
  })),

  on(loginSuccessAction, silentLoginSuccessAction, (state, { credential }) => ({
    ...state,
    entity: credential,
  })),

  on(loginFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(resetPasswordResetDoneAction, (state) => ({
    ...state,
    metadata: {
      ...state.metadata,
      resetRequestDone: false,
    },
  })),

  on(
    authError,
    (state, { authErrorCode }): AuthState => ({
      ...state,
      metadata: {
        ...state.metadata,
        authErrorCode,
      },
    }),
  ),
);

export function authReducer(state: AuthState = initialState, action: Action): AuthState {
  return reducer(state, action);
}
