import { HTTP_AP_CreateCompany_Request, HTTP_AP_CreateCompany_Request_CreateCompanyPropertySettings } from '@api-new/companyservice';
import { CreateCompanyFormRawValues } from '@platform/company/pages/company-create/utils/buildCreateCompanyForm';
import { addressFromToStringAdapter } from '@shared-lib/components/forms/address-form/adapters/address-form.adapter';
import { CreateCompanyPropertyValuationSettingsFormRawValues } from '../pages/company-create/utils/buildCreateCompanyPropertyValuationSettingsForm';

export const createCompanyFormToHTTPRequestAdapter = (
  data: CreateCompanyFormRawValues,
  logoUploadUrl: string,
): HTTP_AP_CreateCompany_Request => {
  if (!data.networkId) {
    delete data.companyNotificationSettings.useNetworkNotificationImagesAsFallback;
  }
  delete data.newLogo;
  return {
    ...data,
    logoUploadUrl,
    address: addressFromToStringAdapter(data.address),
    registeredAddress: addressFromToStringAdapter(data.registeredAddress),
    fcaNumber: data.fcaNumber.toString(),
    primaryLightColor: data.primaryLightColor.replace('#', ''),
    secondaryColor: data.secondaryColor.replace('#', ''),
    primaryColor: data.primaryColor.replace('#', ''),
    companyPropertySettings: propertySettingsFormsToPropertySettingsRequest(data.companyPropertyValuationSettings),
  };
};

const propertySettingsFormsToPropertySettingsRequest = (
  valuationFormData: CreateCompanyPropertyValuationSettingsFormRawValues,
): HTTP_AP_CreateCompany_Request_CreateCompanyPropertySettings => ({
  ...valuationFormData,
  ...createCompanyPropertyListingDefault,
});

export const createCompanyPropertyListingDefault = {
  zooplaPropertyListingStatusEnabled: false,
  zooplaPropertyListingStatusNumberOfDaysForRecurringCallListed: 0,
  zooplaPropertyListingStatusNumberOfDaysForRecurringCallUnlisted: 0,
};
