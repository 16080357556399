import { FormControl, FormGroup } from '@angular/forms';
import { ENUM_AdvisorUserPermissionLevel, ENUM_PropertyInvestmentType, ENUM_ZooplaPropertyListingStatus } from '@api-new/common';
import {
  HTTP_AP_ListProperties_ForTable_Request_Filter,
  HTTP_AP_ListProperties_ForTable_Request_Sort,
  HTTP_AP_ListProperties_ForTable_Response_Property,
} from '@api-new/propertyservice';
import { ENUM_PropertyInvestmentTypeMap } from '@app/shared/maps';
import { ProtoFormModel } from '@shared-lib/models/proto-form.model';
import { SelectInterface } from '@shared/components/form';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const getPropertyRowsDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<HTTP_AP_ListProperties_ForTable_Response_Property, HTTP_AP_ListProperties_ForTable_Request_Sort>[] => [
  {
    columnLabel: 'Address',
    type: RowDefinitionType.TEXT,
    columnKey: 'propertyAddressLines',
    sortKey: 'byAddress',
    width: {
      min: '240px',
      max: '240px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Status',
    type: RowDefinitionType.TEXT,
    columnKey: 'latestZooplaPropertyListingStatus',
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'Status Update',
    type: RowDefinitionType.DATE,
    columnKey: 'latestZooplaPropertyListingStatusListedDatetime',
    sortKey: 'byLatestZooplaPropertyListingStatusListedDatetime',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Investment type',
    type: RowDefinitionType.TEXT,
    columnKey: 'propertyInvestmentType',
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'Client name',
    type: RowDefinitionType.TEXT,
    columnKey: 'clientUserFullName',
    sortKey: 'byClientName',
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Account number',
    type: RowDefinitionType.TEXT,
    columnKey: 'mortgagePartAccountReferenceNumbers',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Company',
    type: RowDefinitionType.TEXT,
    columnKey: 'companyTradingName',
    sortKey: 'byCompanyName',
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CLIENT_USER_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },
  {
    columnLabel: 'Equity',
    type: RowDefinitionType.CURRENCY,
    columnKey: 'currentEquityValue',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Equity (%)',
    type: RowDefinitionType.PERCENT,
    columnKey: 'currentEquityPercentage',
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Value',
    type: RowDefinitionType.CURRENCY,
    columnKey: 'propertyCurrentPropertyValue',
    width: {
      min: '120px',
      max: '120px',
    },
  },
];

export type PropertyTableFilterFormModel = ProtoFormModel<HTTP_AP_ListProperties_ForTable_Request_Filter>;

export const propertyTableFilterDefaults: HTTP_AP_ListProperties_ForTable_Request_Filter = {
  propertyAddress: '',
  clientUserFullNameOrEmail: '',
  latestZooplaPropertyListingStatuses: [],
  companyIds: null,
  mortgagePartAccountReferenceNumber: '',
  propertyInvestmentType: null,
};

export const buildPropertyTableFilterForm = (): FormGroup<PropertyTableFilterFormModel> =>
  new FormGroup<PropertyTableFilterFormModel>({
    propertyAddress: new FormControl(''),
    clientUserFullNameOrEmail: new FormControl(''),
    latestZooplaPropertyListingStatuses: new FormControl([]),
    companyIds: new FormControl(null),
    mortgagePartAccountReferenceNumber: new FormControl(''),
    propertyInvestmentType: new FormControl(null),
  });

export const PROPERTY_STATUS_OPTION_LABEL = {
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE]: 'For sale',
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER]: 'Sale under offer',
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SOLD]: 'Sold',
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT]: 'To rent',
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER]: 'Rent under offer',
  [ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENTED]: 'Rented',
};

export const PROPERTY_INVESTMENT_TYPE_OPTIONS: SelectInterface<ENUM_PropertyInvestmentType>[] = [
  {
    label: ENUM_PropertyInvestmentTypeMap.get(ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL),
    value: ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL,
  },
  {
    label: ENUM_PropertyInvestmentTypeMap.get(ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET),
    value: ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_BUY_TO_LET,
  },
];

export const PROPERTY_STATUS_OPTIONS: SelectInterface<ENUM_ZooplaPropertyListingStatus>[] = [
  {
    label: PROPERTY_STATUS_OPTION_LABEL[ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE],
    value: ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE,
  },
  {
    label: PROPERTY_STATUS_OPTION_LABEL[ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER],
    value: ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER,
  },
  {
    label: PROPERTY_STATUS_OPTION_LABEL[ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SOLD],
    value: ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_SOLD,
  },
  {
    label: PROPERTY_STATUS_OPTION_LABEL[ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT],
    value: ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT,
  },
  {
    label: PROPERTY_STATUS_OPTION_LABEL[ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER],
    value: ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER,
  },
  {
    label: PROPERTY_STATUS_OPTION_LABEL[ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENTED],
    value: ENUM_ZooplaPropertyListingStatus.ZOOPLA_PROPERTY_LISTING_STATUS_RENTED,
  },
];
