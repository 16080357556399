export enum Permission {
  ADVISOR_USER_READ = 'permissionLevelForAdvisorUser.read', // permission_level_for__advisor_user__read
  ADVISOR_USER_CREATE = 'permissionLevelForAdvisorUser.create', // permission_level_for__advisor_user__create
  ADVISOR_USER_UPDATE = 'permissionLevelForAdvisorUser.update', // permission_level_for__advisor_user__update
  ADVISOR_USER_UPDATE_MANAGEMENT = 'permissionLevelForAdvisorUser.updateManagement', // permission_level_for__advisor_user__update_management
  ADVISOR_USER_DEACTIVATE = 'permissionLevelForAdvisorUser.deactivate', // permission_level_for__advisor_user__deactivate
  ADVISOR_USER_ASSIGN_TO = 'permissionLevelForAdvisorUser.assignTo', // permission_level_for__advisor_user__assign_to
  ADVISOR_USER_DELETE = 'permissionLevelForAdvisorUser.delete', // permission_level_for__advisor_user__delete

  CASE_READ = 'permissionLevelForCase.read', // permission_level_for__case__read
  CASE_CREATE = 'permissionLevelForCase.create', // permission_level_for__case__create
  CASE_UPDATE = 'permissionLevelForCase.update', // permission_level_for__case__update
  CASE_CLOSE = 'permissionLevelForCase.close', // permission_level_for__case__close

  CLIENT_USER_READ = 'permissionLevelForClientUser.read', // permission_level_for__client_user__read
  CLIENT_USER_READ_CREDIT_REPORT = 'permissionLevelForClientUser.readCreditReport', // permission_level_for__client_user__read_credit_report
  CLIENT_USER_CREATE = 'permissionLevelForClientUser.create', // permission_level_for__client_user__create
  CLIENT_USER_UPDATE = 'permissionLevelForClientUser.update', // permission_level_for__client_user__update
  CLIENT_USER_UPDATE_ADVISOR = 'permissionLevelForClientUser.updateAdvisor', // permission_level_for__client_user__update_advisor
  CLIENT_USER_UPDATE_CREDIT_REPORT_SETTINGS = 'permissionLevelForClientUser.updateCreditReportSettings', // permission_level_for__client_user__update_credit_report_setting
  CLIENT_USER_UPDATE_NOTIFICATION_SETTINGS = 'permissionLevelForClientUser.updateNotificationSettings', // permission_level_for__client_user__update_notification_settings
  CLIENT_USER_DELETE = 'permissionLevelForClientUser.delete', // permission_level_for__client_user__delete
  CLIENT_USER_IMPERSONATE = 'permissionLevelForClientUser.impersonate', // permission_level_for__client_user__impersonate

  COMPANY_READ = 'permissionLevelForCompany.read', // permission_level_for__company__read
  COMPANY_CREATE = 'permissionLevelForCompany.create', // permission_level_for__company__create
  COMPANY_UPDATE = 'permissionLevelForCompany.update', // permission_level_for__company__update
  COMPANY_UPDATE_CREDIT_REPORT_SETTINGS = 'permissionLevelForCompany.updateCreditReportSettings', // permission_level_for__company__update_credit_report_settings
  COMPANY_UPDATE_NETWORK = 'permissionLevelForCompany.updateNetwork', // permission_level_for__company__update_network
  COMPANY_UPDATE_NOTIFICATION_SETTINGS = 'permissionLevelForCompany.updateNotificationSettings', // permission_level_for__company__update_notification_settings
  COMPANY_ASSIGN_TO = 'permissionLevelForCompany.assignTo', // permission_level_for__company__assign_to
  COMPANY_DELETE = 'permissionLevelForCompany.delete', // permission_level_for__company__delete

  NETWORK_READ = 'permissionLevelForNetwork.read', // permission_level_for__network__read
  NETWORK_CREATE = 'permissionLevelForNetwork.create', // permission_level_for__network__create
  NETWORK_UPDATE = 'permissionLevelForNetwork.update', // permission_level_for__network__update
  NETWORK_UPDATE_NOTIFICATION_SETTINGS = 'permissionLevelForNetwork.updateNotificationSettings', // permission_level_for__network__update_notification_settings
  NETWORK_ASSIGN_TO = 'permissionLevelForNetwork.assignTo', // permission_level_for__network__assign_to

  REFERRAL_READ = 'permissionLevelForReferral.read', // permission_level_for__referral__read
  REFERRAL_CREATE = 'permissionLevelForReferral.create', // permission_level_for__referral__create
  REFERRAL_UPDATE = 'permissionLevelForReferral.update', // permission_level_for__referral__update

  TINY_URL_READ = 'permissionLevelForTinyUrl.read', // permission_level_for__tiny_url__read
  TINY_URL_CREATE = 'permissionLevelForTinyUrl.create', // permission_level_for__tiny_url__create
  TINY_URL_UPDATE = 'permissionLevelForTinyUrl.update', // permission_level_for__tiny_url__update
  TINY_URL_DELETE = 'permissionLevelForTinyUrl.delete', // permission_level_for__tiny_url__delete

  SYSTEM_ADMIN = 'permissionLevelForSystem.admin', // permission_level_for__system__admin
  SYSTEM_SPECIAL = 'permissionLevelForSystem.special', // permission_level_for__system__special
}
