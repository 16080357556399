import { ENUM_ClientUserEmploymentSelfEmployedType } from '@api-new/common';

export const ENUM_ClientUserEmploymentSelfEmployedTypeMap = new Map([
  [ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_SOLE_TRADER, 'Sole trader'],
  [ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_PARTNERSHIP, 'Partnership'],
  [ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LIMITED_COMPANY, 'Limited company'],
  [ENUM_ClientUserEmploymentSelfEmployedType.CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LLP, 'LLP'],
  [ENUM_ClientUserEmploymentSelfEmployedType.UNRECOGNIZED, 'Unrecognized'],
]);
