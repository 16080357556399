import { ENUM_MortgagePartEarlyRepaymentChargeType } from '@api-new/common';

export const ENUM_MortgagePartEarlyRepaymentChargeTypeMap = new Map([
  [ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_FIXED_AMOUNT, 'Fixed amount'],
  [ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_INITIAL_AMOUNT, 'Initial amount'],
  [ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_REPAID_AMOUNT, 'Repaid amount'],
  [ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_MONTHS_INTEREST, 'Months interest'],
  [ENUM_MortgagePartEarlyRepaymentChargeType.UNRECOGNIZED, 'Unrecognized'],
]);
