import { ENUM_NullableBool } from '@api-new/common';
import { HTTP_AP_ListLenders_ForTable_Request_Filter, HTTP_AP_ListLenders_ForTable_Request_Sort } from '@api-new/mortgageservice';
import { BinaryStatusTableColumnModel, RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Moment } from 'moment/moment';

export const lenderTableFilterDefaults: HTTP_AP_ListLenders_ForTable_Request_Filter = {
  isProvidedByDefaqto: ENUM_NullableBool.NULLABLE_BOOL_UNSPECIFIED,
  name: '',
  isCurrentlyOfferingMortgages: ENUM_NullableBool.NULLABLE_BOOL_UNSPECIFIED,
};

export enum LenderTableColumns {
  ID = 'id',
  LOGO = 'logo',
  NAME = 'name',
  UPDATED_AT = 'updatedAt',
  ACTIVE_LENDER = 'isCurrentlyOfferingMortgages',
  DEFAQTO = 'isProvidedByDefaqto',
}

export interface LenderTableRow {
  [LenderTableColumns.ID]: string;
  [LenderTableColumns.NAME]: string;
  [LenderTableColumns.LOGO]: string;
  [LenderTableColumns.UPDATED_AT]?: string | Moment;
  [LenderTableColumns.DEFAQTO]?: boolean;
  [LenderTableColumns.ACTIVE_LENDER]: BinaryStatusTableColumnModel;
}

export const lenderRowDefinitions: TableRowDefinitionModel<LenderTableRow, HTTP_AP_ListLenders_ForTable_Request_Sort>[] = [
  {
    columnLabel: 'name',
    columnKey: LenderTableColumns.NAME,
    type: RowDefinitionType.TEXT,
    sortKey: 'byName',
    width: {
      min: '240px',
      max: '240px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Logo',
    columnKey: LenderTableColumns.LOGO,
    type: RowDefinitionType.IMAGE,
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'id',
    columnKey: LenderTableColumns.ID,
    type: RowDefinitionType.TEXT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'defaqto',
    columnKey: LenderTableColumns.DEFAQTO,
    type: RowDefinitionType.BOOLEAN,
    width: {
      min: '80px',
      max: '80px',
    },
  },
  {
    columnLabel: 'Active lender',
    columnKey: LenderTableColumns.ACTIVE_LENDER,
    type: RowDefinitionType.BINARY_STATUS,
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'last update',
    columnKey: LenderTableColumns.UPDATED_AT,
    type: RowDefinitionType.DATE,
    width: {
      min: '120px',
      max: '120px',
    },
  },
];
