// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_PreCompletionStatus.proto

export enum ENUM_PreCompletionStatus {
  PRE_COMPLETION_STATUS_UNSPECIFIED = 'PRE_COMPLETION_STATUS_UNSPECIFIED',
  PRE_COMPLETION_STATUS_NO_BETTER_DEALS_AVAILABLE = 'PRE_COMPLETION_STATUS_NO_BETTER_DEALS_AVAILABLE',
  PRE_COMPLETION_STATUS_BETTER_DEALS_AVAILABLE = 'PRE_COMPLETION_STATUS_BETTER_DEALS_AVAILABLE',
  PRE_COMPLETION_STATUS_EXPIRED = 'PRE_COMPLETION_STATUS_EXPIRED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
