import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentApiService, HTTP_X_GenerateUploadUrl_Response } from '@api-new/documentservice';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SharedFileModel } from '../models/shared-file.model';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly documentApiService: DocumentApiService,
  ) {}

  requestFileUpload(file: SharedFileModel): Observable<HTTP_X_GenerateUploadUrl_Response> {
    return this.documentApiService
      .HTTP_X_GenerateUploadUrl({
        fileName: file.file.name,
        fileContentType: file.contentType,
      })
      .pipe(
        switchMap((response) =>
          this.httpClient.put<void>(response.uploadUrl, file.file).pipe(
            map(() => {
              return {
                ...response,
                uploadUrl: response.uploadUrl.split('?')[0],
              } as HTTP_X_GenerateUploadUrl_Response;
            }),
          ),
        ),
      );
  }
}
