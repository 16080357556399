import { HTTP_AP_ListCases_ForClientDetail_Request_Filter, HTTP_AP_ListCases_ForClientDetail_Request_Sort } from '@api-new/caseservice';
import { ClientCasesTableRow } from '@platform/client/models/client-cases-table-row.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';

export const clientCasesTableFilterDefaults: HTTP_AP_ListCases_ForClientDetail_Request_Filter = {
  caseStatus: null,
  caseType: null,
};

export const getClientCasesTableHeaderDefinition = (): TableRowDefinitionModel<
  ClientCasesTableRow,
  HTTP_AP_ListCases_ForClientDetail_Request_Sort
>[] => [
  {
    columnLabel: 'Case type',
    columnKey: 'caseTypeMapped',
    type: RowDefinitionType.TEXT,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'Created',
    columnKey: 'createdAt',
    type: RowDefinitionType.DATE_TIME_SINCE,
    sortKey: 'byCreatedAt',
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'Case status',
    columnKey: 'mortgageCaseStatusMapped',
    type: RowDefinitionType.TEXT,
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'Address',
    columnKey: 'address',
    type: RowDefinitionType.TEXT,
    width: {
      min: '140px',
      max: '140px',
    },
  },
  {
    columnLabel: 'New loan amount',
    columnKey: 'borrowedAmount',
    type: RowDefinitionType.CURRENCY,
    sortKey: 'byBestDealBorrowedAmount',
    width: {
      min: '140px',
      max: '140px',
    },
  },
];
