import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouteStateModel } from '../models/route.model';

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouteStateModel> {
  constructor(private readonly router: Router) {}

  serialize(routerState: RouterStateSnapshot): RouteStateModel {
    let route = routerState.root;

    while (route.firstChild) {
      // eslint-disable-line
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;
    const { data } = route;

    const urlTree = this.router.parseUrl(url);
    const { segments } = urlTree.root.children.primary || {
      segments: [] as UrlSegment[],
    };

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {
      url,
      params,
      queryParams,
      path: segments.map((segment) => segment.path),
      data,
    };
  }
}
