export enum StatusStyle {
  NEUTRAL = 'neutral',
  PROGRESS = 'progress',
  WARNING = 'warning',
  HIGHLIGHT = 'highlight',
  ERROR = 'error',
  DONE = 'done',
}

export interface StatusDetail<T> {
  label: string;
  description?: string;
  type?: T;
  icon: string;
  style?: StatusStyle;
}
