import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: '[expanded]',
  standalone: true,
  template: '<ng-content></ng-content>',
  animations: [
    trigger('collapse', [
      state('0', style({ height: '0', padding: '0rem 1rem', overflow: 'hidden' })),
      state('1', style({ height: '*', padding: '*', overflow: 'visible' })),
      transition('0 => 1', [animate('300ms ease-in-out', style({ height: '*', padding: '*' }))]),
      transition('1 => 0', [style({ overflow: 'hidden' }), animate('300ms ease-in-out', style({ height: '0', padding: '0rem 1rem' }))]),
    ]),
  ],
})
export class CollapseDirective {
  @HostBinding('@collapse')
  @Input()
  expanded: boolean;
}
