import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class StripEmptyParamsInterceptor implements HttpInterceptor {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let params = request.params;
    for (const key of request.params.keys()) {
      if (params.get(key) === null || params.get(key) === undefined || params.get(key) === '') {
        params = params.delete(key);
      }
    }
    request = request.clone({ params });
    return next.handle(request);
  }
}
