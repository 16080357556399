import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export class CommonTableFiltersBase<T> {
  @Input() set selectedFilter(value: T) {
    this._selectedFilter = value;
  }
  get selectedFilter(): T {
    return this._selectedFilter;
  }

  @Output() filterChange = new EventEmitter<T>();
  // TODO: 18/04/2023 michal.dvoracek - remove resetFilterToDefaultValues once it is removed from userGroups
  @Output() resetFilterToDefaultValues = new EventEmitter();
  protected _selectedFilter: T;
  filterForm: FormGroup;
}
