import { DATA_Address, DATA_AddressLines } from '@api-new/common';
import { AddressData, AddressFormValuesModel, ManualAddressFormValuesModel } from '../models/address-form.models';
import { isPickedAddressValues } from '../models/address-form.typeguards';

const buildLinesForAddress = (formAddressData: Partial<ManualAddressFormValuesModel & DATA_Address>): DATA_AddressLines => ({
  lines: [
    `${formAddressData.buildingName || ''} ${formAddressData.companyName || ''}`.trim(),
    `${formAddressData.buildingNumber || ''} ${formAddressData.street}`.trim(),
    `${formAddressData.city}`,
    `${formAddressData.postcode}`,
  ].filter((l) => l),
});

export const addressFormToHTTPAddressAdapter = (formAddressData: AddressFormValuesModel): AddressData => {
  // handle picked Address
  if (isPickedAddressValues(formAddressData)) return { ...formAddressData.pickedAddress };

  // handle manual input Address
  const addressLines = buildLinesForAddress(formAddressData);

  return {
    addressLines,
    address: {
      ...formAddressData,
      dependentStreet: null,
      subBuildingName: null,
      uprn: null,
    },
    coordinates: null,
    details: null,
  };
};

export const addressFromToStringAdapter = (formAddressData: AddressFormValuesModel): string => {
  const isPicked = isPickedAddressValues(formAddressData);
  if (isPicked && typeof formAddressData.pickedAddress == 'string') return formAddressData.pickedAddress;
  const { lines } = buildLinesForAddress(isPicked ? formAddressData.pickedAddress?.address : formAddressData);
  lines.unshift(lines.pop()); // string Address line starts with postcode
  return lines.join(', ');
};
