export const scrollViewToElement = (parentClassName: string, targetElementClassName: string): void => {
  setTimeout(() => {
    const parent = document.getElementsByClassName(parentClassName);
    const targetElement = parent[0]?.getElementsByClassName(targetElementClassName);
    if (targetElement != null && targetElement[0] != null && parent != null && parent[0] != null) {
      const offsetTop = (targetElement[0] as HTMLElement).offsetTop;
      (parent[0] as HTMLElement).scrollTop = offsetTop - 80;
    }
  }, 0);
};
