import { ENUM_ImportCreatedByUserType } from '@api-new/common';

export const ENUM_ImportCreatedByUserTypeRecord: Record<ENUM_ImportCreatedByUserType, {
  label: string,
  icon: string
}> = {
  [ENUM_ImportCreatedByUserType.IMPORT_CREATED_BY_USER_TYPE_UNSPECIFIED]: { label: 'Unspecified', icon: null },
  [ENUM_ImportCreatedByUserType.IMPORT_CREATED_BY_USER_TYPE_ADVISOR_USER]: { label: 'User', icon: 'person' },
  [ENUM_ImportCreatedByUserType.IMPORT_CREATED_BY_USER_TYPE_SYSTEM]: { label: 'System', icon: 'gear' },
  [ENUM_ImportCreatedByUserType.UNRECOGNIZED]: { label: 'Unrecognized', icon: null },
};
