import { ENUM_DealInterestRateType } from '@api-new/common';

export const ENUM_DealInterestRateTypeMap = new Map([
  [ENUM_DealInterestRateType.DEAL_INTEREST_RATE_TYPE_UNSPECIFIED, 'Unspecified'],
  [ENUM_DealInterestRateType.DEAL_INTEREST_RATE_TYPE_FIXED, 'Fixed'],
  [ENUM_DealInterestRateType.DEAL_INTEREST_RATE_TYPE_VARIABLE, 'Variable'],
  [ENUM_DealInterestRateType.DEAL_INTEREST_RATE_TYPE_LIBOR, 'Libor'],
  [ENUM_DealInterestRateType.DEAL_INTEREST_RATE_TYPE_BBR, 'Bbr'],
  [ENUM_DealInterestRateType.DEAL_INTEREST_RATE_TYPE_DISCOUNT, 'Discount'],
  [ENUM_DealInterestRateType.UNRECOGNIZED, 'Unrecognized'],
]);
