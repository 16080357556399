import { ENUM_PreCompletionStatus } from '@api-new/common';

export const ENUM_PreCompletionStatusMap = new Map([
  [
    ENUM_PreCompletionStatus.PRE_COMPLETION_STATUS_UNSPECIFIED,
    {
      text: 'Unspecified',
      iconImage: '/assets/icons/exclamation-mark-in-triangle.svg',
      colorClass: 'pre-completion-status-gray',
    },
  ],
  [
    ENUM_PreCompletionStatus.PRE_COMPLETION_STATUS_NO_BETTER_DEALS_AVAILABLE,
    {
      text: 'Rate Unbeatable',
      iconImage: '/assets/icons/checkmark-rounded-success-white.svg',
      colorClass: 'pre-completion-status-green',
    },
  ],
  [
    ENUM_PreCompletionStatus.PRE_COMPLETION_STATUS_BETTER_DEALS_AVAILABLE,
    {
      text: 'Better Rate Available',
      iconImage: '/assets/icons/exclamation-mark-in-triangle.svg',
      colorClass: 'pre-completion-status-orange',
    },
  ],
  [
    ENUM_PreCompletionStatus.PRE_COMPLETION_STATUS_EXPIRED,
    {
      text: 'Offer Expired',
      iconImage: '/assets/icons/calendar-with-exclamation-mark.svg',
      colorClass: 'pre-completion-status-gray',
    },
  ],
  [
    ENUM_PreCompletionStatus.UNRECOGNIZED,
    {
      text: 'Unrecognized',
      iconImage: '/assets/icons/exclamation-mark-in-triangle.svg',
      colorClass: 'pre-completion-status-gray',
    },
  ],
]);
