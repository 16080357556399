@if (status.value) {
  <div class="activation-status">
    <div class="status">{{ status.label }}</div>
    <div class="circle green-back"></div>
  </div>
}
@if (!status.value) {
  <div class="activation-status">
    <div class="status">{{ status.label }}</div>
    <div class="circle gray-back"></div>
  </div>
}
