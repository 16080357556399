import { HTTP_AP_AdvisorUser } from '@api-new/userservice';
import { createAction, props } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import { ErrorModel } from '../../models/app-state.model';

import auth = firebase.auth;

export const getUserById = createAction('[CURRENT USER] Get Adviser User By Id', props<{ id: string }>());

export const getUserSuccess = createAction('[CURRENT USER] Get User Success', props<{ user: HTTP_AP_AdvisorUser }>());

export const getUserFailure = createAction('[CURRENT USER] Get User Failure', props<{ error: auth.AuthError }>());

export const setRedirectUrlAfterSignIn = createAction(
  '[CURRENT USER] Set Redirect Url after sign in',
  props<{ redirectUrlAfterSignIn: string }>(),
);

export const activateUser = createAction('[CURRENT USER] Activate User', props<{ oobCode: string }>());

export const activateUserSuccess = createAction('[CURRENT USER] Activate User Success');

export const activateUserFailure = createAction('[CURRENT USER] Activate User Failure', props<{ error: ErrorModel }>());

export const sendResetPasswordLink = createAction('[CURRENT USER] Send Reset Password Link', props<{ email: string }>());

export const sendResetPasswordLinkSuccess = createAction('[CURRENT USER] Send Reset Password Link Success');

export const sendResetPasswordLinkFailure = createAction('[CURRENT USER] Send Reset Password Link Failure', props<{ error: ErrorModel }>());

export const resetUserPassword = createAction('[CURRENT USER] Reset User Password', props<{ code: string; password: string }>());

export const resetUserPasswordSuccess = createAction('[CURRENT USER] Reset User Password Success');

export const resetUserPasswordFailure = createAction('[CURRENT USER] Reset User Password Failure', props<{ error: ErrorModel }>());
