import { SignInApiErrorCode } from '@shared/enums';

export const SignInApiErrorCodeMap = new Map<string, string>([
  [
    SignInApiErrorCode.CLIENT_SIGN_IN_INTO_ADVISOR_PORTAL,
    ' Looks like you are trying to log in as a Client. Click on this banner to be redirected to Client portal.',
  ],
  [
    SignInApiErrorCode.ADVISOR_SIGN_IN_SSO_ACCOUNT_DOESNT_EXIST,
    "Looks like you don't have a Dashly account under the email you are trying to log in with.",
  ],
  [SignInApiErrorCode.ADVISOR_SIGN_IN_SSO_NOT_ALLOWED_EMAIL_DOMAIN, 'Email domain you are trying to log in with is not allowed.'],
  [
    SignInApiErrorCode.ADVISOR_SIGN_IN_INTO_CLIENT_PORTAL,
    'Looks like you are trying to log in as an Adviser. Click on this banner to be redirected to Adviser portal.',
  ],
  [SignInApiErrorCode.SIGN_IN_CREDENTIALS_INVALID, 'You have entered an incorrect e-mail or password'],
]);
