// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageOfferParserBatchStatus.proto

export enum ENUM_MortgageOfferParserBatchStatus {
  MORTGAGE_OFFER_PARSER_BATCH_STATUS_UNDEFINED = 'MORTGAGE_OFFER_PARSER_BATCH_STATUS_UNDEFINED',
  MORTGAGE_OFFER_PARSER_BATCH_STATUS_CREATED = 'MORTGAGE_OFFER_PARSER_BATCH_STATUS_CREATED',
  MORTGAGE_OFFER_PARSER_BATCH_STATUS_PROCESSING = 'MORTGAGE_OFFER_PARSER_BATCH_STATUS_PROCESSING',
  MORTGAGE_OFFER_PARSER_BATCH_STATUS_FINISHED = 'MORTGAGE_OFFER_PARSER_BATCH_STATUS_FINISHED',
  MORTGAGE_OFFER_PARSER_BATCH_STATUS_FAILED = 'MORTGAGE_OFFER_PARSER_BATCH_STATUS_FAILED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
