import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthApiService } from '@api-new/authservice';
import { RoutePaths } from '@app/app.utils';
import { SignInApiErrorCodeMap } from '@app/landing/maps/SignInApiErrorCodeMap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { SignInApiErrorCode } from '@shared/enums';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorModel } from '../../models/app-state.model';
import { AuthService } from '../../services/auth.service';
import {
  authError,
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  loginWithTokenAction,
  loginWithTokenFromSSOAction,
  logoutAction,
} from './auth.action';

@Injectable()
export class AuthEffect {
  login = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(loginAction),
      tap(() => {
        this.authService.clearRedirectUrl();
      }),
      switchMap(({ email, password }) =>
        this.authApiService
          .HTTP_AP_SignIn_WithPassword({
            email,
            password,
          })
          .pipe(
            switchMap(({ customToken }: { customToken: string }) => {
              return this.authService.login(customToken).pipe(map((credential) => loginSuccessAction({ credential })));
            }),
            catchError((error: ErrorModel) => {
              return of(loginFailureAction({ error }));
            }),
          ),
      ),
    );
  });

  loginWithToken = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(loginWithTokenAction),
      tap(() => {
        this.authService.clearRedirectUrl();
      }),
      switchMap(({ customToken }: { customToken: string }) => {
        return this.authService.login(customToken).pipe(map((credential) => loginSuccessAction({ credential })));
      }),
      catchError((error: ErrorModel) => {
        return of(loginFailureAction({ error }));
      }),
    );
  });

  loginWithTokenFromSSO = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(loginWithTokenFromSSOAction),
      switchMap(({ customToken }: { customToken: string }) => {
        return this.authService.login(customToken).pipe(map((credential) => loginSuccessAction({ credential })));
      }),
      catchError((error: ErrorModel) => {
        return of(loginFailureAction({ error }));
      }),
    );
  });

  logout = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(logoutAction),
        tap(() => {
          this.authService.logout().then(() => this.router.navigate([RoutePaths.Landing, RoutePaths.Login]));
        }),
      );
    },
    { dispatch: false },
  );

  loginSuccess = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(loginSuccessAction),
        tap(() => {
          const redirectUrl = this.authService.getRedirectUrl();
          if (redirectUrl) {
            void this.router.navigate([redirectUrl]);
            this.authService.clearRedirectUrl();
            return;
          }
          void this.router.navigate([RoutePaths.Platform, RoutePaths.OpportunitiesApprovalRequired]);
        }),
      );
    },
    { dispatch: false },
  );

  loginFailure$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(loginFailureAction),
      map(({ error }: { error: ErrorModel }) => {
        let errorMessage = error?.error?.message;
        let authErrorCode: SignInApiErrorCode = null;
        if (SignInApiErrorCodeMap.has(errorMessage)) {
          authErrorCode = error.error.message as SignInApiErrorCode;
          switch (authErrorCode) {
            case SignInApiErrorCode.SIGN_IN_CREDENTIALS_INVALID: {
              errorMessage = SignInApiErrorCodeMap.get(SignInApiErrorCode.SIGN_IN_CREDENTIALS_INVALID);
              break;
            }
            case SignInApiErrorCode.CLIENT_SIGN_IN_INTO_ADVISOR_PORTAL: {
              return authError({ authErrorCode });
            }
          }
        }
        this.toastService.showToast(ToastSeverity.error, errorMessage, { summary: 'Login error' });
        return authError({ authErrorCode });
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly authApiService: AuthApiService,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {}
}
