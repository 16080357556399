import { ENUM_VisualStatus } from '@api-new/common';
import { VisualStatusStyle } from '@shared/enums';

export const ENUM_VisualStatusMap = new Map([
  [ENUM_VisualStatus.VISUAL_STATUS_OK, VisualStatusStyle.OK],
  [ENUM_VisualStatus.VISUAL_STATUS_WARNING, VisualStatusStyle.WARNING],
  [ENUM_VisualStatus.VISUAL_STATUS_DANGER, VisualStatusStyle.DANGER],
  [ENUM_VisualStatus.VISUAL_STATUS_UNSPECIFIED, VisualStatusStyle.UNSPECIFIED],
  [ENUM_VisualStatus.UNRECOGNIZED, null],
]);
