import { propertyTableFilterDefaults } from '@app/platform/property/pages/property-table/utils/tableDefinition';
import { Action, createReducer, on } from '@ngrx/store';
import { outcodeToRegionFilterDefaults } from '@platform/admin-tools/utils/outcodeToRegionTableDefinition';
import { affiliateTableFilterDefaults } from '@platform/affiliate/pages/affiliate-table/constants/affiliate-table-row-definition';
import { closedCaseTableFilterDefaults } from '@platform/case/utils/closedCaseTableDefinition';
import { leadCaseTableFilterDefaults } from '@platform/case/utils/leadCaseTableDefinition';
import { opportunityApprovalRequiredTableFilterDefaults } from '@platform/case/utils/opportunityApprovalRequiredTableDefinition';
import { opportunityTableFilterDefaults } from '@platform/case/utils/opportunityTableDefinition';
import { pipelineCaseTableFilterDefaults } from '@platform/case/utils/pipelineCaseTableDefinition';
import { clientTableFilterDefaults } from '@platform/client/pages/client-table/utils/tableDefinition';
import { clientCasesTableFilterDefaults } from '@platform/client/utils/clientCasesTableDefinition';
import { companyTableFilterDefaults } from '@platform/company/utils/tableDefinition';
import { importTableFilterDefaults } from '@platform/import/utils/formUtils';
import { lenderGroupTableFilterDefaults } from '@platform/lender-group/pages/lender-group-table/constants/lender-group-table-row-definition';
import { lenderTableFilterDefaults } from '@platform/lender/utils/tableDefinition';
import { mortgageDocAiTableFilterDefaults } from '@platform/mortgage-doc-ai/utils/tableDefinition';
import { mortgageTableFilterDefaults } from '@platform/mortgage/pages/mortgage-table/utils/tableDefinition';
import { preCompletionTableFilterDefaults } from '@platform/mortgage/utils/pre-completion-table-definition';
import { referralCodeTableFilterDefaults } from '@platform/referral-code/utils/tableDefinition';
import { tinyUrlTableFilterDefaults } from '@platform/tiny-url/utils/tableDefinition';
import { userTableFilterDefaults } from '@platform/user/pages/user-table/utils/tableDefinition';
import { TablesState } from '@shared/models/app-state.model';
import { BaseTableModel } from '@shared/models/tables.model';
import {
  affiliateTableActions,
  clientCaseTableActions,
  clientTableActions,
  companyTableActions,
  importTableActions,
  lenderGroupTableActions,
  lenderTableActions,
  mortgageClosedCaseTableActions,
  mortgageDocAiTableActions,
  mortgageLeadTableActions,
  mortgagePipelineCaseTableActions,
  mortgageTableActions,
  opportunityApproveRequiredTableActions,
  opportunityTableActions,
  outcodeToRegionTableActions,
  preCompletionTableActions,
  propertyTableActions,
  referralCodesTableActions,
  tinyUrlsTableActions,
  userTableActions,
} from '@shared/store/table/table.action';
import { TablesEnum } from './tables.enum';

const createTableState = (filterDefaults): BaseTableModel<any, any, any> => ({
  filter: filterDefaults,
  sort: null,
  pagination: {
    pageNumber: 0,
    pageSize: 10,
  },
  selectedRows: [],
});

const initialState: TablesState = {
  [TablesEnum.USER_TABLE]: createTableState(userTableFilterDefaults),
  [TablesEnum.CLIENT_TABLE]: createTableState(clientTableFilterDefaults),
  [TablesEnum.PROPERTY_TABLE]: createTableState(propertyTableFilterDefaults),
  [TablesEnum.COMPANY_TABLE]: createTableState(companyTableFilterDefaults),
  [TablesEnum.MORTGAGE_TABLE]: createTableState(mortgageTableFilterDefaults),
  [TablesEnum.OPPORTUNITY_APPROVAL_REQUIRED_TABLE]: createTableState(opportunityApprovalRequiredTableFilterDefaults),
  [TablesEnum.OPPORTUNITY_TABLE]: createTableState(opportunityTableFilterDefaults),
  [TablesEnum.MORTGAGE_PIPELINE_CASE_TABLE]: createTableState(pipelineCaseTableFilterDefaults),
  [TablesEnum.MORTGAGE_CLOSED_CASE_TABLE]: createTableState(closedCaseTableFilterDefaults),
  [TablesEnum.LEAD_CASE_TABLE]: createTableState(leadCaseTableFilterDefaults),
  [TablesEnum.CLIENT_CASE_TABLE]: createTableState(clientCasesTableFilterDefaults),
  [TablesEnum.OUTCODE_TO_REGION_TABLE]: createTableState(outcodeToRegionFilterDefaults),
  [TablesEnum.LENDER_TABLE]: createTableState(lenderTableFilterDefaults),
  [TablesEnum.LENDER_GROUP_TABLE]: createTableState(lenderGroupTableFilterDefaults),
  [TablesEnum.MORTGAGE_DOC_AI_TABLE]: createTableState(mortgageDocAiTableFilterDefaults),
  [TablesEnum.TINY_URLS_TABLE]: createTableState(tinyUrlTableFilterDefaults),
  [TablesEnum.PRE_COMPLETION_TABLE]: createTableState(preCompletionTableFilterDefaults),
  [TablesEnum.REFERRAL_CODE_TABLE]: createTableState(referralCodeTableFilterDefaults),
  [TablesEnum.IMPORT_TABLE]: createTableState(importTableFilterDefaults),
  [TablesEnum.AFFILIATE_TABLE]: createTableState(affiliateTableFilterDefaults),
};

const reducer = createReducer(
  initialState,
  //Paging actions
  on(
    userTableActions.setPagination,
    clientCaseTableActions.setPagination,
    companyTableActions.setPagination,
    opportunityApproveRequiredTableActions.setPagination,
    opportunityTableActions.setPagination,
    mortgageLeadTableActions.setPagination,
    opportunityApproveRequiredTableActions.setPagination,
    mortgageLeadTableActions.setPagination,
    opportunityTableActions.setPagination,
    mortgageClosedCaseTableActions.setPagination,
    mortgagePipelineCaseTableActions.setPagination,
    clientTableActions.setPagination,
    propertyTableActions.setPagination,
    mortgageTableActions.setPagination,
    outcodeToRegionTableActions.setPagination,
    lenderTableActions.setPagination,
    lenderGroupTableActions.setPagination,
    mortgageDocAiTableActions.setPagination,
    tinyUrlsTableActions.setPagination,
    preCompletionTableActions.setPagination,
    referralCodesTableActions.setPagination,
    importTableActions.setPagination,
    affiliateTableActions.setPagination,
    (state, { tableName, pagination }) => {
      const tableState = state[tableName];
      return { ...state, [tableName]: { ...tableState, pagination } };
    },
  ),
  //Sorting actions
  on(
    userTableActions.setSorting,
    clientCaseTableActions.setSorting,
    companyTableActions.setSorting,
    opportunityApproveRequiredTableActions.setSorting,
    opportunityTableActions.setSorting,
    mortgageLeadTableActions.setSorting,
    opportunityApproveRequiredTableActions.setSorting,
    mortgageLeadTableActions.setSorting,
    opportunityTableActions.setSorting,
    mortgageClosedCaseTableActions.setSorting,
    mortgagePipelineCaseTableActions.setSorting,
    clientTableActions.setSorting,
    propertyTableActions.setSorting,
    mortgageTableActions.setSorting,
    outcodeToRegionTableActions.setSorting,
    lenderTableActions.setSorting,
    lenderGroupTableActions.setSorting,
    tinyUrlsTableActions.setSorting,
    preCompletionTableActions.setSorting,
    referralCodesTableActions.setSorting,
    importTableActions.setSorting,
    (state, { tableName, sort }) => {
      const tableState = state[tableName];
      return { ...state, [tableName]: { ...tableState, sort } };
    },
  ),
  //Filter actions
  on(
    userTableActions.setFilter,
    clientCaseTableActions.setFilter,
    companyTableActions.setFilter,
    opportunityApproveRequiredTableActions.setFilter,
    opportunityTableActions.setFilter,
    mortgageLeadTableActions.setFilter,
    opportunityApproveRequiredTableActions.setFilter,
    mortgageLeadTableActions.setFilter,
    opportunityTableActions.setFilter,
    mortgageClosedCaseTableActions.setFilter,
    mortgagePipelineCaseTableActions.setFilter,
    clientTableActions.setFilter,
    propertyTableActions.setFilter,
    mortgageTableActions.setFilter,
    outcodeToRegionTableActions.setFilter,
    lenderTableActions.setFilter,
    lenderGroupTableActions.setFilter,
    mortgageDocAiTableActions.setFilter,
    tinyUrlsTableActions.setFilter,
    preCompletionTableActions.setFilter,
    referralCodesTableActions.setFilter,
    importTableActions.setFilter,
    affiliateTableActions.setFilter,
    (state, { tableName, filter }) => {
      const tableState = state[tableName];
      return {
        ...state,
        [tableName]: {
          ...tableState,
          filter,
          selectedRows: [],
          pagination: { pageSize: tableState.pagination.pageSize, pageNumber: 0 },
        },
      };
    },
  ),
  //Selected rows actions
  on(
    userTableActions.setSelectedRows,
    opportunityApproveRequiredTableActions.setSelectedRows,
    opportunityTableActions.setSelectedRows,
    mortgageLeadTableActions.setSelectedRows,
    mortgagePipelineCaseTableActions.setSelectedRows,
    clientTableActions.setSelectedRows,
    mortgageTableActions.setSelectedRows,
    referralCodesTableActions.setSelectedRows,
    (state, { tableName, entity }) => {
      const tableState = state[tableName];
      const selectedRows = [...entity];
      return { ...state, [tableName]: { ...tableState, selectedRows } };
    },
  ),

  on(
    userTableActions.resetTableFilters,
    clientCaseTableActions.resetTableFilters,
    companyTableActions.resetTableFilters,
    opportunityApproveRequiredTableActions.resetTableFilters,
    opportunityTableActions.resetTableFilters,
    mortgageLeadTableActions.resetTableFilters,
    opportunityApproveRequiredTableActions.resetTableFilters,
    mortgageLeadTableActions.resetTableFilters,
    opportunityTableActions.resetTableFilters,
    mortgageClosedCaseTableActions.resetTableFilters,
    mortgagePipelineCaseTableActions.resetTableFilters,
    clientTableActions.resetTableFilters,
    mortgageTableActions.resetTableFilters,
    outcodeToRegionTableActions.resetTableFilters,
    lenderTableActions.resetTableFilters,
    lenderGroupTableActions.resetTableFilters,
    mortgageDocAiTableActions.resetTableFilters,
    tinyUrlsTableActions.resetTableFilters,
    preCompletionTableActions.resetTableFilters,
    referralCodesTableActions.resetTableFilters,
    importTableActions.resetTableFilters,
    affiliateTableActions.resetTableFilters,
    (state, { tableName, entity }) => {
      const tableState = state[tableName];
      return {
        ...state,
        [tableName]: {
          ...tableState,
          selectedRows: [],
          filter: entity,
          pagination: { pageSize: tableState.pagination.pageSize, pageNumber: 0 },
        },
      };
    },
  ),
);

export function tableReducer(state: TablesState = initialState, action: Action): TablesState {
  return reducer(state, action);
}
