// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_SmartSearchCaseClosedReason.proto

export enum ENUM_SmartSearchCaseClosedReason {
  /** SMART_SEARCH_CASE_CLOSED_REASON_UNSPECIFIED - TODO CLOSING_REFACTOR migrate */
  SMART_SEARCH_CASE_CLOSED_REASON_UNSPECIFIED = 'SMART_SEARCH_CASE_CLOSED_REASON_UNSPECIFIED',
  /** SMART_SEARCH_CASE_CLOSED_REASON_COMPLETED - ------------------------- 7; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_COMPLETED = 'SMART_SEARCH_CASE_CLOSED_REASON_COMPLETED',
  /** SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST - -------------------- 1; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST = 'SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST',
  /** SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE - ------------ 2; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE = 'SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNRESPONSIVE',
  /** SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE - --------- 3; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE = 'SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_UNABLE_TO_PLACE',
  /** SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER - --- 4; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER = 'SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_DIRECT_TO_LENDER',
  /** SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER - ----- 5; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER = 'SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_LOST_ANOTHER_BROKER',
  /** SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT - --------------- 6; = 0x ---> - */
  SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT = 'SMART_SEARCH_CASE_CLOSED_REASON_CANCELLED_BY_CLIENT',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
