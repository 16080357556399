// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_AdvisorUserPermissionSettingsTemplate.proto

export enum ENUM_AdvisorUserPermissionSettingsTemplate {
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_UNSPECIFIED = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_UNSPECIFIED',
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_ADVISOR = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_ADVISOR',
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_AGENT = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_AGENT',
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_MANAGER = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_MANAGER',
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_OWNER = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_OWNER',
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_ADMIN = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_ADMIN',
  ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_NETWORK_MANAGER = 'ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_NETWORK_MANAGER',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
