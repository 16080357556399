import { Permission } from '@shared/enums';

export const PermissionMap = new Map<Permission, string>([
  [Permission.ADVISOR_USER_READ, 'Read'],
  [Permission.ADVISOR_USER_CREATE, 'Create'],
  [Permission.ADVISOR_USER_UPDATE, 'Update'],
  [Permission.ADVISOR_USER_UPDATE_MANAGEMENT, 'Update Management'],
  [Permission.ADVISOR_USER_DEACTIVATE, 'Deactivate'],
  [Permission.ADVISOR_USER_ASSIGN_TO, 'Assign To'],
  [Permission.ADVISOR_USER_DELETE, 'Delete'],

  [Permission.CASE_READ, 'Read'],
  [Permission.CASE_CREATE, 'Create'],
  [Permission.CASE_UPDATE, 'Update'],
  [Permission.CASE_CLOSE, 'Close'],

  [Permission.CLIENT_USER_READ, 'Read'],
  [Permission.CLIENT_USER_READ_CREDIT_REPORT, 'Read Credit Report'],
  [Permission.CLIENT_USER_CREATE, 'Create'],
  [Permission.CLIENT_USER_UPDATE, 'Update'],
  [Permission.CLIENT_USER_UPDATE_ADVISOR, 'Update Advisor'],
  [Permission.CLIENT_USER_UPDATE_CREDIT_REPORT_SETTINGS, 'Update Credit Report Settings'],
  [Permission.CLIENT_USER_UPDATE_NOTIFICATION_SETTINGS, 'Update Notification Settings'],
  [Permission.CLIENT_USER_DELETE, 'Delete'],
  [Permission.CLIENT_USER_IMPERSONATE, 'Impersonate'],

  [Permission.COMPANY_READ, 'Read'],
  [Permission.COMPANY_CREATE, 'Create'],
  [Permission.COMPANY_UPDATE, 'Update'],
  [Permission.COMPANY_UPDATE_CREDIT_REPORT_SETTINGS, 'Update Credit Report Settings'],
  [Permission.COMPANY_UPDATE_NETWORK, 'Update Network'],
  [Permission.COMPANY_UPDATE_NOTIFICATION_SETTINGS, 'Update Notification Settings'],
  [Permission.COMPANY_ASSIGN_TO, 'Assign To'],
  [Permission.COMPANY_DELETE, 'Delete'],

  [Permission.NETWORK_READ, 'Read'],
  [Permission.NETWORK_CREATE, 'Create'],
  [Permission.NETWORK_UPDATE, 'Update'],
  [Permission.NETWORK_UPDATE_NOTIFICATION_SETTINGS, 'Update Notification Settings'],
  [Permission.NETWORK_ASSIGN_TO, 'Assign To'],

  [Permission.REFERRAL_READ, 'Read'],
  [Permission.REFERRAL_CREATE, 'Create'],
  [Permission.REFERRAL_UPDATE, 'Update'],

  [Permission.TINY_URL_READ, 'Read'],
  [Permission.TINY_URL_CREATE, 'Create'],
  [Permission.TINY_URL_UPDATE, 'Update'],
  [Permission.TINY_URL_DELETE, 'Delete'],

  [Permission.SYSTEM_ADMIN, 'Admin'],
  [Permission.SYSTEM_SPECIAL, 'Special'],
]);
