// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageProductPurpose.proto

export enum ENUM_MortgageProductPurpose {
  MORTGAGE_PRODUCT_PURPOSE_UNSPECIFIED = 'MORTGAGE_PRODUCT_PURPOSE_UNSPECIFIED',
  MORTGAGE_PRODUCT_PURPOSE_REMORTGAGE = 'MORTGAGE_PRODUCT_PURPOSE_REMORTGAGE',
  MORTGAGE_PRODUCT_PURPOSE_FIRST_TIME_BUYER = 'MORTGAGE_PRODUCT_PURPOSE_FIRST_TIME_BUYER',
  MORTGAGE_PRODUCT_PURPOSE_HOUSE_MOVE = 'MORTGAGE_PRODUCT_PURPOSE_HOUSE_MOVE',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
