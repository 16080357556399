import { ActivatedRoute, Params, Router } from '@angular/router';
import { explodeObjectIntoQueryParams } from '@shared-lib/utils/explodeObjectIntoQueryParams';
import { removeNullValuesFromObject } from '@shared/utils';

export function setQueryParams<FormModel>(
  queryParams: Params,
  defaultTableQueryParams: FormModel,
  router: Router,
  route: ActivatedRoute,
): void {
  let changedQueryParams = structuredClone(queryParams);
  Object.keys(defaultTableQueryParams).forEach((key) => {
    if (defaultTableQueryParams[key] !== queryParams[key]) {
      changedQueryParams[key] = queryParams[key];
    } else {
      delete changedQueryParams[key];
    }
  });
  changedQueryParams = explodeObjectIntoQueryParams(changedQueryParams);

  void router.navigate([], {
    relativeTo: route,
    queryParams: { ...removeNullValuesFromObject(changedQueryParams) },
    queryParamsHandling: '',
  });
}
