/**
 * Transform first letter to uppercase and rest to lowercase
 */
export function upperCaseFirstLetter(text: string): string {
  if (text == null) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

/**
 * @deprecated
 * Use upperCaseFirstLetter() instead.
 */
export function capitalize(word: string): string {
  if (word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  }
}
