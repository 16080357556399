import { HTTP_Range_Timestamp } from '@api-new/common';
import { isSimpleObject } from '@shared-lib/utils/isSimpleObject';
import { TimeIntervalEnum } from '@shared/enums';
import moment from 'moment';

export const TableFilterUtils = {
  remapFilterValues<Filter>(filter: Filter, attributesToRemap: { [key: string]: (value: any) => any }) {
    const remappedFilter = structuredClone(filter);
    Object.keys(attributesToRemap).forEach((key) => {
      if (remappedFilter[key]) {
        remappedFilter[key] = attributesToRemap[key](remappedFilter[key]);
      }
    });
    return remappedFilter;
  },

  getRangeTimestampByTimeInterval(timeInterval: TimeIntervalEnum | HTTP_Range_Timestamp): HTTP_Range_Timestamp {
    if (isSimpleObject(timeInterval)) {
      return timeInterval as HTTP_Range_Timestamp;
    }
    switch (timeInterval) {
      case TimeIntervalEnum.TODAY:
        return {
          min: moment().utc().startOf('day').toISOString(),
          max: moment().utc().endOf('day').toISOString(),
        };
      case TimeIntervalEnum.LAST_WEEK:
        return {
          min: moment().utc().subtract(1, 'week').startOf('day').toISOString(),
          max: moment().utc().endOf('day').toISOString(),
        };
      case TimeIntervalEnum.LAST_TWO_WEEKS:
        return {
          min: moment().utc().subtract(2, 'week').startOf('day').toISOString(),
          max: moment().utc().endOf('day').toISOString(),
        };
      case TimeIntervalEnum.LAST_FOUR_WEEKS:
        return {
          min: moment().utc().subtract(4, 'week').startOf('day').toISOString(),
          max: moment().utc().endOf('day').toISOString(),
        };
      case TimeIntervalEnum.LAST_THREE_MONTHS:
        return {
          min: moment().utc().subtract(3, 'month').startOf('day').toISOString(),
          max: moment().utc().endOf('day').toISOString(),
        };
      case TimeIntervalEnum.LAST_TWELVE_MONTHS:
        return {
          min: moment().utc().subtract(12, 'month').startOf('day').toISOString(),
          max: moment().utc().endOf('day').toISOString(),
        };
      default:
        return null;
    }
  },
};
