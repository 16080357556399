import { ENUM_PropertySaleDataSource } from '@api-new/common';

export const ENUM_PropertySaleDataSourceMap = new Map([
  [
    ENUM_PropertySaleDataSource.PROPERTY_VALUE_CONFIDENCE_UNSPECIFIED,
    {
      logo: null,
      text: 'Unspecified',
    },
  ],
  [
    ENUM_PropertySaleDataSource.PROPERTY_VALUE_CONFIDENCE_LAND_REGISTRY,
    {
      logo: 'HM_Land_Registry_logo.png',
      text: 'LandRegistry',
    },
  ],
  [
    ENUM_PropertySaleDataSource.PROPERTY_VALUE_CONFIDENCE_HOME_TRACK,
    {
      logo: 'hometrack-logo.svg',
      text: 'Hometrack',
    },
  ],
  [
    ENUM_PropertySaleDataSource.UNRECOGNIZED,
    {
      logo: null,
      text: 'Unrecognized',
    },
  ],
]);
