<div class="table-header">
  @if (selectedRows?.length > 0 && bulkActionsEnabled) {
    <div class="bulk-actions">
      {{ selectedRows.length }}
      <span>selected:</span>
      <div class="bulk-actions-button" (click)="menu.toggle($event)">
        <i class="icon-bulk-actions"></i> Bulk actions
      </div>
      <div class="unselect-all" (click)="emitUnselectAllRows()">Unselect all</div>
      <ng-content select="bulkActionsMenu"></ng-content>
      <p-menu
        #menu
        [popup]="true"
        [model]="bulkActionMenuItems"
        [style]="{ width: '130px' }"
        appendTo="body" />
    </div>
  }
  @if (pagination && !errors) {
    <div class="entries-number">
      {{ (pagination?.totalCount | number) ?? "-" }} {{ countLabel }}
    </div>
  }
</div>

@if (errors) {
  <ap-table-error (retryButtonClicked)="emitFetchData()" />
} @else {
  <p-table
    #table
    responsiveLayout="stack"
    class="table-wrapper"
    [class.horizontally-scrollable]="isHorizontallyScrollable"
    [class.vertically-scrollable]="isVerticallyScrollable"
    [value]="loading ? null : rowData"
    [dataKey]="dataKey"
    [selection]="selectedRows"
    (selectionChange)="emitRowsSelected($event)"
    [selectionPageOnly]="true"
    [sortField]="latestSort?.sortKey"
    [sortOrder]="latestSort?.sortOrder | tableSortOrderToNumber"
    (sortFunction)="emitSort($event)"
    [customSort]="true"
    [tableStyle]="{ width: '100%', tableLayout: 'fixed' }">
    <ng-template pTemplate="header">
      <tr #tableHeader>
        @if (bulkActionsEnabled) {
          <th class="checkbox" pFrozenColumn [frozen]="true">
            <p-tableHeaderCheckbox />
          </th>
        }
        @for (rowDefinition of rowDefinitions; track rowDefinition) {
          @if (!rowDefinition.isHidden) {
            <th
              [class]="rowDefinition.type"
              [style.min-width]="rowDefinition.width?.min"
              [style.max-width]="rowDefinition.width?.max"
              class="table-header-column"
              [pSortableColumn]="rowDefinition.sortKey"
              pFrozenColumn
              [frozen]="rowDefinition.isFrozen">
              @if (!!rowDefinition.sortKey) {
                <div class="sortable-column" [ngStyle]="rowDefinition.headerStyle">
                  {{ rowDefinition.columnLabel }}
                  <ap-sort-icon [sort]="latestSort" [field]="rowDefinition.sortKey" />
                </div>
              } @else {
                <div [ngStyle]="rowDefinition.headerStyle">
                  {{ rowDefinition.columnLabel }}
                </div>
              }
            </th>
          }
        }
        @if (rowActionsEnabled || customRowActions) {
          <th class="table-header-column actions"></th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr
        [pSelectableRow]="row"
        [tabIndex]="rowData.indexOf(row)"
        (mouseenter)="onHoverEnterRow(row)"
        (mouseleave)="onHoverLeaveRow()"
        (click)="customRow && emitRowClicked($event, row)"
        [class.hover]="row === hoverOverRow">
        @if (customRow) {
          @if (bulkActionsEnabled) {
            <td class="checkbox" pFrozenColumn [frozen]="true">
              <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()" />
            </td>
          }
          <ng-template
            [ngTemplateOutlet]="customRowRef"
            [ngTemplateOutletContext]="{ $implicit: row }"></ng-template>
        } @else {
          @if (bulkActionsEnabled) {
            <td class="checkbox" pFrozenColumn [frozen]="true">
              <p-tableCheckbox [value]="row" (click)="$event.stopPropagation()" />
            </td>
          }
          @for (rowDefinition of rowDefinitions; track rowDefinition) {
            @if (!rowDefinition.isHidden) {
              <td
                (click)="emitRowClicked($event, row)"
                [class]="rowDefinition.type"
                [ngStyle]="rowDefinition.style"
                pFrozenColumn
                [frozen]="rowDefinition.isFrozen"
                [style.min-width]="rowDefinition.width?.min"
                [style.max-width]="rowDefinition.width?.max">
                <ng-content select="customRow"></ng-content>
                @switch (rowDefinition.type) {
                  @case (rowDefinitionType.TEXT) {
                    <span>
                      {{ row[rowDefinition.columnKey] || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.FULLNAME_SHORT) {
                    <span [pTooltip]="row[rowDefinition.columnKey]">
                      {{ (row[rowDefinition.columnKey] | nameFormat: "short") || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.FULLNAME_INITIALS) {
                    <span [pTooltip]="row[rowDefinition.columnKey]">
                      {{ (row[rowDefinition.columnKey] | nameFormat: "initials") || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.NUMBER) {
                    <span>
                      {{ row[rowDefinition.columnKey] || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.BOOLEAN) {
                    <span>
                      {{ (row[rowDefinition.columnKey] | booleanToYesOrNo) || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.PERCENT) {
                    <span>
                      {{ (row[rowDefinition.columnKey] | percent: "1.2-2") || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.CURRENCY) {
                    <span>
                      {{
                        (row[rowDefinition.columnKey] | currency: "GBP" : "symbol" : "1.0-0") || "-"
                      }}
                    </span>
                  }
                  @case (rowDefinitionType.ADDRESS) {
                    <span>
                      {{ row[rowDefinition.columnKey]?.lines | join: "," || "-" }}
                    </span>
                  }
                  @case (rowDefinitionType.BINARY_STATUS) {
                    <div>
                      <ap-binary-status [status]="row[rowDefinition.columnKey]" />
                    </div>
                  }
                  @case (rowDefinitionType.COPY) {
                    <div>
                      <div
                        class="copy-link"
                        (click)="copyLink($event, row[rowDefinition.columnKey].link)">
                        <span>{{ row[rowDefinition.columnKey].label }}</span>
                        <i class="icon-copy"></i>
                      </div>
                    </div>
                  }
                  @case (rowDefinitionType.IMAGE) {
                    <div class="logo-container">
                      <img [src]="row[rowDefinition.columnKey]" class="logo" />
                    </div>
                  }
                  @case (rowDefinitionType.LABELED_IMG) {
                    <div class="labeled-logo-container">
                      <img [src]="row[rowDefinition.columnKey].src" class="logo" />
                      <div class="labeled-logo-label">{{ row[rowDefinition.columnKey].label }}</div>
                    </div>
                  }
                  @case (rowDefinitionType.DATE) {
                    <div>
                      {{ (row[rowDefinition.columnKey] | dateFormat: "long") ?? "Never" }}
                    </div>
                  }
                  @case (rowDefinitionType.DATE_WITH_TIME) {
                    <div>
                      {{ (row[rowDefinition.columnKey] | dateFormat: "datetime") ?? "Never" }}
                    </div>
                  }
                  @case (rowDefinitionType.DATE_TIME_SINCE) {
                    <div>
                      <div
                        class="date-since"
                        [pTooltip]="row[rowDefinition.columnKey] | dateFormat: 'datetime'">
                        {{ (row[rowDefinition.columnKey] | dateFormat: "timeSince") ?? "Never" }}
                      </div>
                    </div>
                  }
                }
              </td>
            }
          }
          @if ((bulkActionsEnabled || rowActionsEnabled) && row.hasActionInProgress) {
            <td
              [style]="{ padding: '12px' }"
              class="row-loading-spinner"
              pFrozenColumn
              [frozen]="true"
              alignFrozen="right">
              <p-progressSpinner styleClass="table-row-progress-spinner" />
            </td>
          }
        }
        @if (rowActionsEnabled) {
          @if (!row.hasActionInProgress) {
            <td
              (click)="showMenu(row, $event)"
              pFrozenColumn
              [frozen]="true"
              alignFrozen="right"
              class="actions">
              <div class="menu-button">
                <i class="action-menu-button icon-three-dots"></i>
              </div>
            </td>
          }
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      @if (loading) {
        <tr class="table-skeleton" *ngRepeat="pagination?.pageSize ?? 10">
          <td
            [attr.colspan]="
              rowDefinitions.length +
              (bulkActionsEnabled ? 1 : 0) +
              (customRowActions || rowActionMenuItems?.length ? 1 : 0)
            ">
            <p-skeleton height="50px" width="100%" />
          </td>
        </tr>
      }
      @if (!loading && !errors) {
        <tr class="table-empty-message">
          <td
            [attr.colspan]="
              rowDefinitions.length +
              (bulkActionsEnabled && 1) +
              (customRowActions || rowActionMenuItems?.length ? 1 : 0)
            ">
            <span class="ap-label">{{ emptyMessage }}</span>
          </td>
        </tr>
      }
    </ng-template>
  </p-table>
  @if (rowData?.length && pagination) {
    <ap-paginator
      [numberOfPages]="pagination.totalPages"
      [selectedPage]="pagination.pageNumber"
      [pageSize]="pagination.pageSize"
      [numberOfEntries]="pagination.totalCount"
      (onPage)="emitPaginate($event)" />
  }
}

<p-menu
  #actionMenu
  [popup]="true"
  [style]="{ marginTop: '-34px' }"
  [model]="rowActionMenuItems"
  appendTo="body" />
