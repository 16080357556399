import { ENUM_CreditReportAccountPaymentStatus } from '@api-new/common';

export const ENUM_CreditReportAccountPaymentStatusMap = new Map([
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_UNSPECIFIED,
    {
      text: '',
      code: '',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NO_UPDATE_RECEIVED,
    {
      text: 'No update received',
      code: '?',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_UP_TO_DATE_WITH_PAYMENTS,
    {
      text: 'Up to date with payments',
      code: '0',
      color: 'GREEN',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_ONE_PAYMENT_IN_ARREARS,
    {
      text: '1 payment in arrears',
      code: '1',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_TWO_PAYMENTS_IN_ARREARS,
    {
      text: '2 payment in arrears',
      code: '2',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_THREE_PAYMENTS_IN_ARREARS,
    {
      text: '3 payment in arrears',
      code: '3',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_FOUR_PAYMENTS_IN_ARREARS,
    {
      text: '4 payment in arrears',
      code: '4',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_FIVE_PAYMENTS_IN_ARREARS,
    {
      text: '5 payment in arrears',
      code: '5',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_SIX_PAYMENTS_IN_ARREARS,
    {
      text: '6 payment in arrears',
      code: '6',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_MODERATE_ARREARS,
    {
      text: 'Moderate arrears (legacy status)',
      code: 'A',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_BAD_ARREARS,
    {
      text: 'Bad arrears (legacy status)',
      code: 'B',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_ARRANGEMENT_TO_PAY,
    {
      text: 'Arrangement to pay',
      code: 'I',
      color: 'AMBER',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_SETTLED_OR_SATISFIED,
    {
      text: 'Settled or Satisfied',
      code: 'S',
      color: 'GREEN',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NO_PAYMENT_DUE_YET_OR_UNCLASSIFIED,
    {
      text: 'No payment due yet or unclassified',
      code: 'U',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_REPOSSESSION,
    {
      text: 'Repossession',
      code: 'R',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_DEFAULT,
    {
      text: 'Default',
      code: 'D',
      color: 'RED',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_QUERY_ACCOUNT_UNDER_REVIEW,
    {
      text: 'Query (account is under review)',
      code: 'Q',
      color: 'PURPLE',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_GONE_AWAY,
    {
      text: 'Gone away (legacy status)',
      code: 'G',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_INACTIVE,
    {
      text: 'Inactive',
      code: 'N',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_NEVER_TAKEN_UP,
    {
      text: 'Never taken up',
      code: 'Z',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_GOODS_VOLUNTARILY,
    {
      text: 'Goods voluntarily surrendered (legacy status)',
      code: 'V',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_WRITTEN_OFF,
    {
      text: 'Written off (legacy status)',
      code: 'W',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.CREDIT_REPORT_ACCOUNT_PAYMENT_STATUS_TRANSFER,
    {
      text: 'Transfer',
      code: 'X',
      color: 'GRAY',
    },
  ],
  [
    ENUM_CreditReportAccountPaymentStatus.UNRECOGNIZED,
    {
      text: '',
      code: '-',
      color: 'GRAY',
    },
  ],
]);
