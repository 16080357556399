// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_PropertyValuationFrequency.proto

export enum ENUM_PropertyValuationFrequency {
  PROPERTY_VALUATION_FREQUENCY_UNSPECIFIED = 'PROPERTY_VALUATION_FREQUENCY_UNSPECIFIED',
  PROPERTY_VALUATION_FREQUENCY_NEVER = 'PROPERTY_VALUATION_FREQUENCY_NEVER',
  PROPERTY_VALUATION_FREQUENCY_MONTHLY = 'PROPERTY_VALUATION_FREQUENCY_MONTHLY',
  PROPERTY_VALUATION_FREQUENCY_QUARTERLY = 'PROPERTY_VALUATION_FREQUENCY_QUARTERLY',
  PROPERTY_VALUATION_FREQUENCY_SEMIANNUALLY = 'PROPERTY_VALUATION_FREQUENCY_SEMIANNUALLY',
  PROPERTY_VALUATION_FREQUENCY_ANNUALLY = 'PROPERTY_VALUATION_FREQUENCY_ANNUALLY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
