import { ENUM_CreditReportAccountSupplementaryInformationType } from '@api-new/common';

export const ENUM_CreditReportAccountSupplementaryInformationTypeMap = new Map([
  [ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_UNSPECIFIED, ''],
  [ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DECEASED, 'Deceased'],
  [ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_GONE_AWAY, 'Gone away'],
  [
    ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DEPT_MANAGEMENT,
    'Dept management',
  ],
  [
    ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_ACCOUNT_PAID_BY_A_THIRD_PARTY,
    'Account paid by a third party',
  ],
  [
    ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_VOLUNTARY_TERMINATION,
    'Voluntary termination',
  ],
  [ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_PARTIAL, 'Partial'],
  [
    ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DEBT_ASSIGNED,
    'Debt assigned',
  ],
  [
    ENUM_CreditReportAccountSupplementaryInformationType.CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_SOLD_TO_INSIGHT_MEMBER,
    'Sold to insight member',
  ],
  [ENUM_CreditReportAccountSupplementaryInformationType.UNRECOGNIZED, ''],
]);
