// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CreditReportAccountPaymentFrequency.proto

export enum ENUM_CreditReportAccountPaymentFrequency {
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_UNSPECIFIED = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_UNSPECIFIED',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_ANNUALLY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_ANNUALLY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_FORTNIGHTLY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_FORTNIGHTLY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_MONTHLY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_MONTHLY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_PERIODICALLY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_PERIODICALLY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_QUARTERLY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_QUARTERLY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_WEEKLY = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_WEEKLY',
  CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_UNKNOWN = 'CREDIT_REPORT_ACCOUNT_PAYMENT_FREQUENCY_UNKNOWN',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
