<div class="table-error">
  <img class="table-error__img" src="./assets/images/submarine.svg" />

  <span class="table-error__description">
    We are sorry, but an error occurred in process of getting the table data
  </span>

  <div class="table-error__buttons">
    <button
      pButton
      pRipple
      type="button"
      class="p-button-outlined retry-btn"
      icon="pi icon-retry"
      [label]="isDisabled ? counter.toString() + 'sec' : 'Retry'"
      [disabled]="isDisabled"
      (click)="fetchData()"></button>
  </div>
</div>
