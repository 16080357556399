// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CreditReportAccountSupplementaryInformationType.proto

export enum ENUM_CreditReportAccountSupplementaryInformationType {
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_UNSPECIFIED = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_UNSPECIFIED',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DECEASED = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DECEASED',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_GONE_AWAY = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_GONE_AWAY',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DEPT_MANAGEMENT = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DEPT_MANAGEMENT',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_ACCOUNT_PAID_BY_A_THIRD_PARTY = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_ACCOUNT_PAID_BY_A_THIRD_PARTY',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_VOLUNTARY_TERMINATION = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_VOLUNTARY_TERMINATION',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_PARTIAL = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_PARTIAL',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DEBT_ASSIGNED = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_DEBT_ASSIGNED',
  CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_SOLD_TO_INSIGHT_MEMBER = 'CREDIT_REPORT_ACCOUNT_SUPPLEMENTARY_INFORMATION_TYPE_SOLD_TO_INSIGHT_MEMBER',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
