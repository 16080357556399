import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { HTTP_AP_ListTinyUrls_Request_Filter, HTTP_AP_ListTinyUrls_Request_Sort } from '@api-new/userservice';
import { TinyUrlTableRow } from '@platform/tiny-url/models/tiny-url-table-row.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const tinyUrlTableFilterDefaults: HTTP_AP_ListTinyUrls_Request_Filter = {
  codeContains: '',
  nameOrDescription: '',
};

export const getTinyUrlTableDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<TinyUrlTableRow, HTTP_AP_ListTinyUrls_Request_Sort>[] => {
  return [
    {
      columnLabel: 'Name',
      columnKey: 'name',
      type: RowDefinitionType.TEXT,
      isFrozen: true,
      sortKey: 'byName',
      width: {
        min: '10rem',
        max: '10rem',
      },
    },
    {
      columnLabel: 'Code',
      columnKey: 'code',
      type: RowDefinitionType.TEXT,
      sortKey: 'byCode',
      width: {
        min: '6rem',
        max: '6rem',
      },
    },
    {
      columnLabel: 'Created by',
      columnKey: 'createdByUserName',
      type: RowDefinitionType.TEXT,
      isHidden: !hasCurrentUserRequiredPermissions([
        {
          permission: Permission.TINY_URL_READ,
          permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
        },
      ]),
      width: {
        min: '10rem',
        max: '10rem',
      },
    },
    {
      columnLabel: 'Link',
      columnKey: 'tinyUrl',
      type: RowDefinitionType.COPY,
      width: {
        min: '10rem',
        max: '10rem',
      },
    },
    {
      columnLabel: 'Redirect Url',
      columnKey: 'redirectUrl',
      type: RowDefinitionType.TEXT,
      width: {
        min: '10rem',
        max: '10rem',
      },
    },
  ];
};
