// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ZooplaPropertyListingStatus.proto

export enum ENUM_ZooplaPropertyListingStatus {
  ZOOPLA_PROPERTY_LISTING_STATUS_UNSPECIFIED = 'ZOOPLA_PROPERTY_LISTING_STATUS_UNSPECIFIED',
  ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE = 'ZOOPLA_PROPERTY_LISTING_STATUS_FOR_SALE',
  ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER = 'ZOOPLA_PROPERTY_LISTING_STATUS_SALE_UNDER_OFFER',
  ZOOPLA_PROPERTY_LISTING_STATUS_SOLD = 'ZOOPLA_PROPERTY_LISTING_STATUS_SOLD',
  ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT = 'ZOOPLA_PROPERTY_LISTING_STATUS_TO_RENT',
  ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER = 'ZOOPLA_PROPERTY_LISTING_STATUS_RENT_UNDER_OFFER',
  ZOOPLA_PROPERTY_LISTING_STATUS_RENTED = 'ZOOPLA_PROPERTY_LISTING_STATUS_RENTED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
