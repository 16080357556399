import { ENUM_EmailNotificationReportingMetricType } from '@api-new/common';

export const ENUM_EmailNotificationReportingMetricTypeMap = new Map<
  ENUM_EmailNotificationReportingMetricType,
  { text: string; colorShade: number }
>([
  [ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNSPECIFIED, { text: 'Unspecified', colorShade: 0 }],
  [ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_PROCESSED, { text: 'Processed', colorShade: 0 }],
  [ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_DELIVERED, { text: 'Delivered', colorShade: 10 }],
  [ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_BOUNCED, { text: 'Bounces', colorShade: 20 }],
  [ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNIQ_OPENS, { text: 'Unique Opens', colorShade: 30 }],
  [
    ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNIQ_CLICKS,
    { text: 'Unique Clicks', colorShade: 40 },
  ],
  [
    ENUM_EmailNotificationReportingMetricType.EMAIL_NOTIFICATION_REPORTING_METRIC_TYPE_UNSUBSCRIBED,
    { text: 'Unsubscribe', colorShade: 50 },
  ],
]);
