// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_VisualStatus.proto

export enum ENUM_VisualStatus {
  VISUAL_STATUS_UNSPECIFIED = 'VISUAL_STATUS_UNSPECIFIED',
  VISUAL_STATUS_OK = 'VISUAL_STATUS_OK',
  VISUAL_STATUS_WARNING = 'VISUAL_STATUS_WARNING',
  VISUAL_STATUS_DANGER = 'VISUAL_STATUS_DANGER',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
