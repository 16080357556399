import { HTTP_AP_ListCases_ForPipelineTable_Request_Filter, HTTP_AP_ListCases_ForPipelineTable_Request_Sort } from '@api-new/caseservice';
import { ENUM_AdvisorUserPermissionLevel } from '@api-new/common';
import { PipelineCaseTableRow } from '@platform/case/models/mortgage-open-case-table-row.model';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { Permission } from '@shared/enums';
import { HasCurrentUserRequiredPermissionsFunction } from '@shared/services/permission.service';

export const pipelineCaseTableHeaderDefinition = (
  hasCurrentUserRequiredPermissions: HasCurrentUserRequiredPermissionsFunction,
): TableRowDefinitionModel<PipelineCaseTableRow, HTTP_AP_ListCases_ForPipelineTable_Request_Sort>[] => [
  {
    columnLabel: 'Client name',
    columnKey: 'clientUserFullName',
    sortKey: 'byClientUserFullName',
    type: RowDefinitionType.FULLNAME_SHORT,
    width: {
      min: '150px',
      max: '150px',
    },
    isFrozen: true,
  },
  {
    columnLabel: 'Case type',
    columnKey: 'caseTypeMapped',
    sortKey: 'byCaseType',
    type: RowDefinitionType.TEXT,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'id',
    columnKey: 'id',
    type: RowDefinitionType.TEXT,
    width: {
      min: '150px',
      max: '150px',
    },
  },
  {
    columnLabel: 'Company',
    columnKey: 'companyTradingName',
    sortKey: 'byCompanyTradingName',
    type: RowDefinitionType.TEXT,
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CASE_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_COMPANY,
      },
    ]),
    width: {
      min: '200px',
      max: '200px',
    },
  },

  {
    columnLabel: 'Adviser',
    columnKey: 'advisorUserFullName',
    sortKey: 'byAdvisorUserFullName',
    type: RowDefinitionType.FULLNAME_INITIALS,
    width: {
      min: '85px',
      max: '85px',
    },
    isHidden: !hasCurrentUserRequiredPermissions([
      {
        permission: Permission.CASE_READ,
        permissionLevel: ENUM_AdvisorUserPermissionLevel.ADVISOR_USER_PERMISSION_LEVEL_DIRECT,
      },
    ]),
  },
  {
    columnLabel: 'Loan amount',
    columnKey: 'loanAmount',
    sortKey: 'byLoanAmount',
    type: RowDefinitionType.CURRENCY,
    width: {
      min: '125px',
      max: '125px',
    },
  },
  {
    columnLabel: 'Ltv',
    columnKey: 'ltv',
    type: RowDefinitionType.PERCENT,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Property value',
    columnKey: 'propertyValue',
    sortKey: 'byPropertyValue',
    type: RowDefinitionType.CURRENCY,
    width: {
      min: '120px',
      max: '120px',
    },
  },
  {
    columnLabel: 'Address',
    columnKey: 'address',
    type: RowDefinitionType.TEXT,
    width: {
      min: '300px',
      max: '300px',
    },
  },
  {
    columnLabel: 'Created',
    columnKey: 'createdAt',
    sortKey: 'byCreatedAt',
    type: RowDefinitionType.DATE_TIME_SINCE,
    width: {
      min: '100px',
      max: '100px',
    },
  },
  {
    columnLabel: 'Last updated',
    columnKey: 'updatedAt',
    sortKey: 'byUpdatedAt',
    type: RowDefinitionType.DATE_TIME_SINCE,
    width: {
      min: '100px',
      max: '100px',
    },
  },
];

export const pipelineCaseTableFilterDefaults: HTTP_AP_ListCases_ForPipelineTable_Request_Filter = {
  createdAt: null,
  clientUserFullNameOrEmail: '',
  companyIds: [],
  advisorUserFullNameOrEmail: '',
  propertyValue: { min: null, max: null },
  loanAmount: { min: null, max: null },
  updatedAt: null,
  caseType: null,
};
