import { TimeIntervalEnum } from '@shared/enums';

export const TimeIntervalEnumMap = new Map([
  [TimeIntervalEnum.TODAY, 'Today'],
  [TimeIntervalEnum.LAST_WEEK, 'Last 7 days'],
  [TimeIntervalEnum.LAST_TWO_WEEKS, 'Last 2 weeks'],
  [TimeIntervalEnum.LAST_FOUR_WEEKS, 'Last 4 weeks'],
  [TimeIntervalEnum.LAST_THREE_MONTHS, 'Last 3 months'],
  [TimeIntervalEnum.LAST_TWELVE_MONTHS, 'Last 12 months'],
  [TimeIntervalEnum.ALL_TIME, 'All time'],
]);
