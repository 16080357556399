import { ENUM_AdvisorUserPermissionSettingsTemplate } from '@api-new/common';

export const HTTP_AP_GetAdvisorUserPermissionSettingsTemplate_Request_TemplateMap = new Map([
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_ADMIN, 'Admin'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_ADVISOR, 'Adviser'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_MANAGER, 'Manager'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_OWNER, 'Owner'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_AGENT, 'Agent'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_NETWORK_MANAGER, 'Network Manager'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.ADVISOR_USER_PERMISSION_SETTINGS_TEMPLATE_UNSPECIFIED, '-'],
  [ENUM_AdvisorUserPermissionSettingsTemplate.UNRECOGNIZED, '-'],
]);
