import { ENUM_MortgageOfferParserBatchStatus } from '@api-new/common';
import { HTTP_AP_ListMortgageOfferParserBatches_Request_Filter } from '@api-new/documentservice';
import { RowDefinitionType, TableRowDefinitionModel } from '@shared/components/table';
import { StatusDetail, StatusStyle } from '@shared/models/status-detail.model';
import { MortgageBatchTableRowModel } from '../models/mortgage-batch-table-row.model';

export const mortgageDocAiTableFilterDefaults: Partial<HTTP_AP_ListMortgageOfferParserBatches_Request_Filter> = {
  name: '',
  mortgageOfferParserBatchId: null,
  mortgageOfferParserBatchType: null,
};

export const mortgagePdfTableRowDefinition: TableRowDefinitionModel<MortgageBatchTableRowModel, null>[] = [
  {
    columnLabel: 'Batch Status',
    columnKey: 'statusIcon',
    type: RowDefinitionType.LABELED_IMG,
    width: {
      min: '60px',
      max: '60px',
    },
  },
  {
    columnLabel: 'Id',
    columnKey: 'id',
    type: RowDefinitionType.NUMBER,
    width: {
      min: '25px',
      max: '25px',
    },
  },
  {
    columnLabel: 'Name',
    columnKey: 'name',
    type: RowDefinitionType.TEXT,
    width: {
      min: '150px',
      max: '500px',
    },
  },
  {
    columnLabel: 'Type',
    columnKey: 'type',
    type: RowDefinitionType.TEXT,
    width: {
      min: '50px',
      max: '80px',
    },
  },

  {
    columnLabel: 'Created at',
    columnKey: 'createdAt',
    type: RowDefinitionType.DATE_WITH_TIME,
    width: {
      min: '50px',
      max: '80px',
    },
  },

  {
    columnLabel: '# of Documents',
    columnKey: 'entryCount',
    type: RowDefinitionType.NUMBER,
    width: {
      min: '60px',
      max: '50px',
    },
  },
];

export function getStatusDetails(status: ENUM_MortgageOfferParserBatchStatus): StatusDetail<boolean> {
  switch (status) {
    case ENUM_MortgageOfferParserBatchStatus.MORTGAGE_OFFER_PARSER_BATCH_STATUS_CREATED:
      return {
        icon: 'assets/icons/edit-rounded.svg',
        label: 'Created',
        style: StatusStyle.NEUTRAL,
      };
    case ENUM_MortgageOfferParserBatchStatus.MORTGAGE_OFFER_PARSER_BATCH_STATUS_FINISHED:
      return {
        icon: 'assets/icons/activated.svg',
        label: 'Finished',
        style: StatusStyle.DONE,
      };
    case ENUM_MortgageOfferParserBatchStatus.MORTGAGE_OFFER_PARSER_BATCH_STATUS_PROCESSING:
      return {
        icon: 'assets/icons/in_progress.svg',
        label: 'Processing',
        style: StatusStyle.PROGRESS,
      };

    case ENUM_MortgageOfferParserBatchStatus.MORTGAGE_OFFER_PARSER_BATCH_STATUS_FAILED:
      return {
        icon: 'assets/icons/deactivated.svg',
        label: 'Failed',
        style: StatusStyle.ERROR,
      };

    default:
      return {
        icon: 'assets/icons/deactivated.svg',
        label: 'In progress',
        style: StatusStyle.ERROR,
      };
  }
}
