// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_HelpCaseStatus.proto

export enum ENUM_HelpCaseStatus {
  HELP_CASE_STATUS_UNSPECIFIED = 'HELP_CASE_STATUS_UNSPECIFIED',
  HELP_CASE_STATUS_LEAD = 'HELP_CASE_STATUS_LEAD',
  HELP_CASE_STATUS_PIPELINE = 'HELP_CASE_STATUS_PIPELINE',
  HELP_CASE_STATUS_CLOSED = 'HELP_CASE_STATUS_CLOSED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
