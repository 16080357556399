@if (
  sort?.sortOrder && sort?.sortOrder !== SortType.SORT_TYPE_UNSPECIFIED && field === sort?.sortKey
) {
  <i
    class="icon-full-arrow-up"
    [class.not-active]="sort?.sortOrder === SortType.SORT_TYPE_DESC || field !== sort?.sortKey"></i>
  <i
    class="icon-full-arrow-down"
    [class.not-active]="sort?.sortOrder === SortType.SORT_TYPE_ASC || field !== sort?.sortKey"></i>
}
