import { ENUM_ClientUserCreationMethod } from '@api-new/common';

export const ENUM_ClientUserCreationMethodMap = new Map([
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_UNSPECIFIED, 'Unspecified'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_CLIENT_USER_IMPORT, 'Client user import'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_BY_PROXY, 'By proxy'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_SIGN_UP_REGISTRATION, 'Sign up - Registration'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_SIGN_UP_MORTGAGE_MONITOR, 'Sign up - Mortgage monitor'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_SIGN_UP_REMORTGAGE_NOW, 'Sign up - Remortgage now'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_SIGN_UP_FIRST_TIME_BUYER, 'Sign up - First time buyer'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_SIGN_UP_MOVING_HOME, 'Sign up - Moving home'],
  [ENUM_ClientUserCreationMethod.CLIENT_USER_CREATION_METHOD_SIGN_UP_BUY_TO_LET, 'Sign up - Buy to let'],
  [ENUM_ClientUserCreationMethod.UNRECOGNIZED, 'Unrecognized'],
]);
