export enum TimeIntervalEnum {
  CUSTOM = 'CUSTOM',
  ALL_TIME = 'ALL_TIME',
  TODAY = 'TODAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_TWO_WEEKS = 'LAST_TWO_WEEKS',
  LAST_FOUR_WEEKS = 'LAST_FOUR_WEEKS',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_TWELVE_MONTHS = 'LAST_TWELVE_MONTHS',
}
