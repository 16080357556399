import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'ap-table-error',
  templateUrl: './table-error.component.html',
  styleUrls: ['./table-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ButtonModule, RippleModule],
})
export class TableErrorComponent {
  @Output() retryButtonClicked = new EventEmitter<void>();
  isDisabled = false;
  counter = 10;

  constructor(private cdr: ChangeDetectorRef) {}
  fetchData() {
    if (this.isDisabled) {
      return;
    }
    this.retryButtonClicked.emit();
    this.isDisabled = true;
    const intervalId = setInterval(() => {
      this.counter -= 1;
      if (this.counter <= 0) {
        clearInterval(intervalId);
        this.isDisabled = false;
        this.counter = 10;
      }
      this.cdr.detectChanges();
    }, 1000);
  }
}
