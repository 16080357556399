import { Injectable } from '@angular/core';
import { HTTP_AP_AddFilesToMortgageOfferParserBatch_Request } from '@api-new/documentservice/HTTP_AP_AddFilesToMortgageOfferParserBatch_Request';
import { HTTP_AP_AddSeedFileToMortgageOfferParserBatch_Request } from '@api-new/documentservice/HTTP_AP_AddSeedFileToMortgageOfferParserBatch_Request';
import { HTTP_AP_CreateMortgageOfferParserBatch_Request } from '@api-new/documentservice/HTTP_AP_CreateMortgageOfferParserBatch_Request';
import { HTTP_AP_DeleteMortgageOfferParserBatchDocument_Request } from '@api-new/documentservice/HTTP_AP_DeleteMortgageOfferParserBatchDocument_Request';
import { HTTP_AP_GenerateMortgagePDF_Request } from '@api-new/documentservice/HTTP_AP_GenerateMortgagePDF_Request';
import { HTTP_AP_GenerateMortgagePDF_Response } from '@api-new/documentservice/HTTP_AP_GenerateMortgagePDF_Response';
import { HTTP_AP_GetMortgageOfferParserBatchDocument_Request } from '@api-new/documentservice/HTTP_AP_GetMortgageOfferParserBatchDocument_Request';
import { HTTP_AP_GetMortgageOfferParserBatchDocument_Response } from '@api-new/documentservice/HTTP_AP_GetMortgageOfferParserBatchDocument_Response';
import { HTTP_AP_GetMortgageOfferParserBatch_Request } from '@api-new/documentservice/HTTP_AP_GetMortgageOfferParserBatch_Request';
import { HTTP_AP_ListMortgageOfferParserBatchDocuments_Request } from '@api-new/documentservice/HTTP_AP_ListMortgageOfferParserBatchDocuments_Request';
import { HTTP_AP_ListMortgageOfferParserBatchDocuments_Response } from '@api-new/documentservice/HTTP_AP_ListMortgageOfferParserBatchDocuments_Response';
import { HTTP_AP_ListMortgageOfferParserBatches_Request } from '@api-new/documentservice/HTTP_AP_ListMortgageOfferParserBatches_Request';
import { HTTP_AP_ListMortgageOfferParserBatches_Response } from '@api-new/documentservice/HTTP_AP_ListMortgageOfferParserBatches_Response';
import { HTTP_AP_MortgageOfferParserBatch } from '@api-new/documentservice/HTTP_AP_MortgageOfferParserBatch';
import { HTTP_AP_ProcessMortgageOfferParserBatch_Request } from '@api-new/documentservice/HTTP_AP_ProcessMortgageOfferParserBatch_Request';
import { HTTP_CP_CreateDocument_Request } from '@api-new/documentservice/HTTP_CP_CreateDocument_Request';
import { HTTP_CP_DeleteDocument_Request } from '@api-new/documentservice/HTTP_CP_DeleteDocument_Request';
import { HTTP_CP_Document } from '@api-new/documentservice/HTTP_CP_Document';
import { HTTP_CP_ListDocuments_Response } from '@api-new/documentservice/HTTP_CP_ListDocuments_Response';
import { HTTP_CP_UpdateDocument_Request } from '@api-new/documentservice/HTTP_CP_UpdateDocument_Request';
import { HTTP_X_GenerateUploadUrl_Request } from '@api-new/documentservice/HTTP_X_GenerateUploadUrl_Request';
import { HTTP_X_GenerateUploadUrl_Response } from '@api-new/documentservice/HTTP_X_GenerateUploadUrl_Response';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class DocumentApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_AddFilesToMortgageOfferParserBatch(request: HTTP_AP_AddFilesToMortgageOfferParserBatch_Request): Observable<undefined> {
    return this.httpClient.post(
      `/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}/AddFiles`,
      request,
    );
  }

  HTTP_AP_AddSeedFileToMortgageOfferParserBatch(request: HTTP_AP_AddSeedFileToMortgageOfferParserBatch_Request): Observable<undefined> {
    return this.httpClient.post(
      `/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}/AddSeedFile`,
      request,
    );
  }

  HTTP_AP_CreateMortgageOfferParserBatch(
    request: HTTP_AP_CreateMortgageOfferParserBatch_Request,
  ): Observable<HTTP_AP_MortgageOfferParserBatch> {
    return this.httpClient.post(`/document-service/ap/mortgage-offer-parser-batches`, request);
  }

  HTTP_AP_DeleteMortgageOfferParserBatchDocument(request: HTTP_AP_DeleteMortgageOfferParserBatchDocument_Request): Observable<undefined> {
    const { mortgageOfferParserBatchId, documentFileName, ...updatedRequest } = request;
    return this.httpClient.delete(
      `/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}/documents/${request.documentFileName}`,
      { params: updatedRequest },
    );
  }

  HTTP_AP_GenerateMortgagePDF(request: HTTP_AP_GenerateMortgagePDF_Request): Observable<HTTP_AP_GenerateMortgagePDF_Response> {
    return this.httpClient.get(`/document-service/ap/generate-mortgage-pdf`, { params: request });
  }

  HTTP_AP_GetMortgageOfferParserBatch(request: HTTP_AP_GetMortgageOfferParserBatch_Request): Observable<HTTP_AP_MortgageOfferParserBatch> {
    const { mortgageOfferParserBatchId, ...updatedRequest } = request;
    return this.httpClient.get(`/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetMortgageOfferParserBatchDocument(
    request: HTTP_AP_GetMortgageOfferParserBatchDocument_Request,
  ): Observable<HTTP_AP_GetMortgageOfferParserBatchDocument_Response> {
    const { mortgageOfferParserBatchId, documentFileName, ...updatedRequest } = request;
    return this.httpClient.get(
      `/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}/documents/${request.documentFileName}`,
      { params: updatedRequest },
    );
  }

  HTTP_AP_ListMortgageOfferParserBatchDocuments(
    request: HTTP_AP_ListMortgageOfferParserBatchDocuments_Request,
  ): Observable<HTTP_AP_ListMortgageOfferParserBatchDocuments_Response> {
    const { mortgageOfferParserBatchId, ...updatedRequest } = request;
    return this.httpClient.get(`/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}/documents`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_ListMortgageOfferParserBatches(
    request: HTTP_AP_ListMortgageOfferParserBatches_Request,
  ): Observable<HTTP_AP_ListMortgageOfferParserBatches_Response> {
    return this.httpClient.get(`/document-service/ap/mortgage-offer-parser-batches`, { params: request });
  }

  HTTP_AP_ProcessMortgageOfferParserBatch(request: HTTP_AP_ProcessMortgageOfferParserBatch_Request): Observable<undefined> {
    return this.httpClient.post(
      `/document-service/ap/mortgage-offer-parser-batches/${request.mortgageOfferParserBatchId}/Process`,
      request,
    );
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_CreateDocument(request: HTTP_CP_CreateDocument_Request): Observable<HTTP_CP_Document> {
    return this.httpClient.post(`/document-service/cp/documents`, request);
  }

  HTTP_CP_DeleteDocument(request: HTTP_CP_DeleteDocument_Request): Observable<undefined> {
    const { documentId, ...updatedRequest } = request;
    return this.httpClient.delete(`/document-service/cp/documents/${request.documentId}`, { params: updatedRequest });
  }

  HTTP_CP_ListDocuments(request?: undefined): Observable<HTTP_CP_ListDocuments_Response> {
    return this.httpClient.get(`/document-service/cp/documents`, { params: request });
  }

  HTTP_CP_UpdateDocument(request: HTTP_CP_UpdateDocument_Request): Observable<HTTP_CP_Document> {
    return this.httpClient.put(`/document-service/cp/documents/${request.documentId}`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_X_GenerateUploadUrl(request: HTTP_X_GenerateUploadUrl_Request): Observable<HTTP_X_GenerateUploadUrl_Response> {
    return this.httpClient.post(`/document-service/x/GenerateUploadUrl`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
