export const standardizeUrlForHref = (url: string): string | null => {
  if (url == null) {
    return null;
  }
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  }
  if (url.startsWith('www.')) {
    return 'https://' + url;
  }

  return `//${url}`;
};
