import { ENUM_CreditReportRetrieveFrequency } from '@api-new/common';

export const ENUM_CreditReportRetrieveFrequencyMap = new Map([
  [ENUM_CreditReportRetrieveFrequency.CREDIT_REPORT_RETRIEVE_FREQUENCY_UNSPECIFIED, 'Unspecified'],
  [ENUM_CreditReportRetrieveFrequency.CREDIT_REPORT_RETRIEVE_FREQUENCY_NEVER, 'Never'],
  [ENUM_CreditReportRetrieveFrequency.CREDIT_REPORT_RETRIEVE_FREQUENCY_MONTHLY, 'Monthly'],
  [ENUM_CreditReportRetrieveFrequency.CREDIT_REPORT_RETRIEVE_FREQUENCY_QUARTERLY, 'Quarterly'],
  [ENUM_CreditReportRetrieveFrequency.CREDIT_REPORT_RETRIEVE_FREQUENCY_SEMIANNUALLY, 'Semi-annually'],
  [ENUM_CreditReportRetrieveFrequency.CREDIT_REPORT_RETRIEVE_FREQUENCY_ANNUALLY, 'Annually'],
  [ENUM_CreditReportRetrieveFrequency.UNRECOGNIZED, 'Unrecognized'],
]);
