// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_CreditReportAccountType.proto

export enum ENUM_CreditReportAccountType {
  CREDIT_REPORT_ACCOUNT_TYPE_UNSPECIFIED = 'CREDIT_REPORT_ACCOUNT_TYPE_UNSPECIFIED',
  CREDIT_REPORT_ACCOUNT_TYPE_BRIDGING_FINANCE = 'CREDIT_REPORT_ACCOUNT_TYPE_BRIDGING_FINANCE',
  CREDIT_REPORT_ACCOUNT_TYPE_BUY_TO_LET_MORTGAGE = 'CREDIT_REPORT_ACCOUNT_TYPE_BUY_TO_LET_MORTGAGE',
  CREDIT_REPORT_ACCOUNT_TYPE_HOME_LENDING_AGREEMENT = 'CREDIT_REPORT_ACCOUNT_TYPE_HOME_LENDING_AGREEMENT',
  CREDIT_REPORT_ACCOUNT_TYPE_SECURED_LOAN = 'CREDIT_REPORT_ACCOUNT_TYPE_SECURED_LOAN',
  CREDIT_REPORT_ACCOUNT_TYPE_HIRE_PURCHASE = 'CREDIT_REPORT_ACCOUNT_TYPE_HIRE_PURCHASE',
  CREDIT_REPORT_ACCOUNT_TYPE_CONSOLIDATED_DEBT = 'CREDIT_REPORT_ACCOUNT_TYPE_CONSOLIDATED_DEBT',
  CREDIT_REPORT_ACCOUNT_TYPE_FIXED_TERM_AGREEMENT = 'CREDIT_REPORT_ACCOUNT_TYPE_FIXED_TERM_AGREEMENT',
  CREDIT_REPORT_ACCOUNT_TYPE_GREEN_DEALS = 'CREDIT_REPORT_ACCOUNT_TYPE_GREEN_DEALS',
  CREDIT_REPORT_ACCOUNT_TYPE_INSURANCE_AGREEMENT = 'CREDIT_REPORT_ACCOUNT_TYPE_INSURANCE_AGREEMENT',
  CREDIT_REPORT_ACCOUNT_TYPE_PAY_DAY_OR_SHORT_TERM_LOAN = 'CREDIT_REPORT_ACCOUNT_TYPE_PAY_DAY_OR_SHORT_TERM_LOAN',
  CREDIT_REPORT_ACCOUNT_TYPE_UNSECURED_LOAN = 'CREDIT_REPORT_ACCOUNT_TYPE_UNSECURED_LOAN',
  CREDIT_REPORT_ACCOUNT_TYPE_BUDGET_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_BUDGET_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_CHARGE_CARD = 'CREDIT_REPORT_ACCOUNT_TYPE_CHARGE_CARD',
  CREDIT_REPORT_ACCOUNT_TYPE_CREDIT_CARD = 'CREDIT_REPORT_ACCOUNT_TYPE_CREDIT_CARD',
  CREDIT_REPORT_ACCOUNT_TYPE_MAIL_ORDER_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_MAIL_ORDER_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_OPTION_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_OPTION_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_XMAS_CLUB = 'CREDIT_REPORT_ACCOUNT_TYPE_XMAS_CLUB',
  CREDIT_REPORT_ACCOUNT_TYPE_BANK_DEFAULT_AGREEMENT = 'CREDIT_REPORT_ACCOUNT_TYPE_BANK_DEFAULT_AGREEMENT',
  CREDIT_REPORT_ACCOUNT_TYPE_BASIC_BANK_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_BASIC_BANK_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_CURRENT_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_CURRENT_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_UNPRESENTABLE_CHEQUE = 'CREDIT_REPORT_ACCOUNT_TYPE_UNPRESENTABLE_CHEQUE',
  CREDIT_REPORT_ACCOUNT_TYPE_COMMS_SUPPLY_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_COMMS_SUPPLY_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_COUNCIL_ARREARS = 'CREDIT_REPORT_ACCOUNT_TYPE_COUNCIL_ARREARS',
  CREDIT_REPORT_ACCOUNT_TYPE_LOCAL_AUTHORITY_HOUSING = 'CREDIT_REPORT_ACCOUNT_TYPE_LOCAL_AUTHORITY_HOUSING',
  CREDIT_REPORT_ACCOUNT_TYPE_PROPERTY_RENTAL = 'CREDIT_REPORT_ACCOUNT_TYPE_PROPERTY_RENTAL',
  CREDIT_REPORT_ACCOUNT_TYPE_PUBLIC_UTILITY_ACCOUNT = 'CREDIT_REPORT_ACCOUNT_TYPE_PUBLIC_UTILITY_ACCOUNT',
  CREDIT_REPORT_ACCOUNT_TYPE_RENTAL_AGREEMENT = 'CREDIT_REPORT_ACCOUNT_TYPE_RENTAL_AGREEMENT',
  CREDIT_REPORT_ACCOUNT_TYPE_SOCIAL_HOUSING_RENTAL = 'CREDIT_REPORT_ACCOUNT_TYPE_SOCIAL_HOUSING_RENTAL',
  CREDIT_REPORT_ACCOUNT_TYPE_STUDENT_LOAN = 'CREDIT_REPORT_ACCOUNT_TYPE_STUDENT_LOAN',
  CREDIT_REPORT_ACCOUNT_TYPE_UNCATEGORISED_AGREEMENT = 'CREDIT_REPORT_ACCOUNT_TYPE_UNCATEGORISED_AGREEMENT',
  CREDIT_REPORT_ACCOUNT_TYPE_MORTGAGE = 'CREDIT_REPORT_ACCOUNT_TYPE_MORTGAGE',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
